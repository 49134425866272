/**
 * Componente cotizacion.
 * @module $document
 * @param      {Object}  $timeout  Obtiene elementos del DOM.
 * @param      {Object}  CatalogService  invoca servicio para obtener hora actual.
 * @return     {Object}  Ctrl
 */
function wrapperTimerController($timeout, CatalogService) {
var vm = this;

  vm.time = '';
  vm.s = '';
  vm.m = '';
  vm.eta = '';
  vm.getDate = null;
  var activa = true;
  vm.timerr = '00:00:00';


  vm.timeArrival = timeArrival;
	vm.trafficLight = trafficLight;

	// timer callback
	var timer = function() {
		if (activa) {
			vm.timeArrival();
		} else {
			vm.trafficLight();
			if (vm.getDif <= 0) {
				vm.timerr = 'FINALIZO!!';
				timer = '';
			}
		}
		$timeout(timer, 1000);
	};

	/** @static
	 * @function
	 * @name timeArrival
	 * @description Realiza calculo de tiempo transcurrido desde que se toma la asistecia en cada tarjeta
	 * @return	{void}
	 */
	function timeArrival() {
		CatalogService.getCurrentDate().then(function(data) {
			vm.getDate = data.data.timestamp;
			if (vm.assistance.typeAssignment.code === '5'	|| vm.assistance.typeAssignment.code === '6') {
				vm.eta = 3 * 60;
				vm.getDif = vm.eta - (Math.floor((vm.getDate - (vm.assistance.auction)) / 1000));
			}
			vm.s = Math.abs(vm.getDif % 60);
			vm.m = Math.floor(vm.getDif / 60);
			activa = false;
		});


	}
	/** @static
  	 * @function
  	 * @name trafficLight
  	 * @description calculo de min restantes
  	 * @return	{void}
  	 */
	function trafficLight() {
		vm.getDif--;
		vm.s = Math.abs(vm.getDif % 60);
    vm.m = Math.floor(vm.getDif / 60);
		vm.timerr = (vm.getDif < 0 ? '-' : '') + format(Math.floor((Math.abs(vm.m) / 60))) + ':' + format(Math.abs(vm.m % 60)) + ':' + format(vm.s);
	}
	/** @static
  	 * @function
  	 * @name format
  	 * @description formatea valores
  	 * @param {String}  valor  formatea valores.
  	 * @return	{void}
  	 */
	function format(valor) {
		while ((valor.toString()).length < 2) {
			valor = '0' + valor;
		}
		return valor;
	}
	$timeout(timer, 1000);
}

angular
  .module('app')
  .component('wrapperTimer', {
    templateUrl: 'app/components/wrapperTimer/wrapperTimer.html',
    controller: wrapperTimerController,
    controllerAs : 'ctrl',
		bindings: {
			assistance : '=',
			getDif : '='
		}
  });

