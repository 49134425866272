/**
  * @namespace CatalogService
  * @desc Obtencion de catalogos.
  * @memberOf Factories
  * @param {Object} $http Peticiones
  * @return {Object} service
  */
function CatalogService($http) {

	/**
	 * Funciones disponibles del servicio
	 * @memberof Factories.CatalogService
	 * @property {function} rejectReasons - Obtiene catalogo con motivos de rechazo.
	 * @property {function} cancellationReasons
	 * @property {function} reassingReasons
	 * @property {function} commentList
	 * @property {function} getBanner
	 * @property {function} getCurrentDate
	 */
	var service = {
		rejectReasons : rejectReasons,
		cancellationReasons : cancellationReasons,
		reassingReasons : reassingReasons,
		commentList : commentList,
		getBanner : getBanner,
		getCurrentDate : getCurrentDate,
		getFiles : getFiles,
		getCatalogCosts : getCatalogCosts
	};
	/* Regreso de definición se servicio */
	return service;

	/* IMPLEMENTACION */
	/**
	 * @static
	 * @function getCustomerComplete
	 * @desc Procesa respuesta de servicio.
	 * @memberof Factories.CraneService
	 * @param      {Object}  data  	Response de servicio.
	 * @return     {Object}  data  Objeto data contenido en el response
	 */
	function getCustomerComplete(data) {
		return data.data;
	}
	/**
	 * @function cancellationReasons
	 * @desc Obtiene catalogo con motivos de cancelación.
	 * @memberof Factories.CatalogService
	 * @param      {Object}  country  	Parametro de pais.
	 * @param      {Object}  subservice  	Parametro de subservicio.
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function cancellationReasons(country, subservice) {
	/**
		let urlCancellationReasons = 'api/v1/cancelList';
		if (angular.isDefined(subservice) && country === 'CO') {
			urlCancellationReasons += '?subservice=' + subservice + '&country=' + country;
		}
		*/
		return $http.get('api/v1/cancelList' + (angular.isDefined(subservice) && country === 'CO' ? '?subservice=' + subservice + '&country=' + country : '')).then(getCustomerComplete);
		// return $http.get(urlCancellationReasons).then(getCustomerComplete);
	}
	/**
	 * @function rejectReasons
	 * @desc Obtiene catalogo con motivos de rechazo.
	 * @memberof Factories.CatalogService
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function rejectReasons() {
		return $http.get('api/v1/rejectList').then(getCustomerComplete);
	}
	/**
	 * @function reassingReasons
	 * @desc Obtiene catalogo con motivos de reasignación.
	 * @memberof Factories.CatalogService
	 * @param      {Object}  country  	Parametro de pais.
	 * @param      {Object}  subservice  	Parametro de subservicio.
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function reassingReasons(country, subservice) {
	/**
		let urlCancellationReasons = 'api/v1/reassignList';
		if (angular.isDefined(subservice) && country === 'CO') {
			urlCancellationReasons += '?subservice=' + subservice + '&country=' + country;
		}
		*/
		return $http.get('api/v1/reassignList' + ((angular.isDefined(subservice) && country === 'CO') ? '?subservice=' + subservice + '&country=' + country : '')).then(getCustomerComplete);
		// return $http.get(urlCancellationReasons).then(getCustomerComplete);
	}
	/**
	 * @function commentList
	 * @desc Obtiene catalogo con motivos para envio de comentarios.
	 * @memberof Factories.CatalogService
	 * @param      {Object}  country  	Parametro de pais.
	 * @param      {Object}  subservice  	Parametro de subservicio.
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function commentList(country, subservice) {
	/**
		let urlComments = 'api/v1/commentList';
		if (angular.isDefined(subservice) && country === 'CO') {
			urlComments += '?subservice=' + subservice + '&country=' + country;
		}
		*/
		return $http.get('api/v1/commentList' + (angular.isDefined(subservice) && country === 'CO' ? '?subservice=' + subservice + '&country=' + country : '')).then(getCustomerComplete);
		// return $http.get(urlComments).then(getCustomerComplete);
	}
	/**
	 * @function getBanner
	 * @desc Obtiene catalogo con mensajes para el banner.
	 * @memberof Factories.CatalogService
	 * @param      {Object}  country  	Parametro de pais.
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function getBanner(country) {
		return $http.get('api/v1/banner?country=' + country)
		.then(bannerMessages);
		/**
		 * @static
		 * @function bannerMessages
		 * @desc Procesa respuesta de servicio.
		 * @memberof Factories.CraneService
		 * @param      {Object}  data  	Response de servicio.
		 * @return     {Object}  data  Objeto data contenido en el response
		 */
		function bannerMessages(data) {
			return data;
		}
	}
	/**
	 * @function getCurrentDate
	 * @desc Obtiene hora actual del servidor con time zone cero.
	 * @memberof Factories.CatalogService
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function getCurrentDate() {
		return $http.get('api/v1/current_date')
			.then(getCustomerComplete);
	}
	/**
   * @function getFiles
   * @desc Obtiene listado de archivos.
   * @memberof Factories.CatalogService
   * @param {Object} assistance		Id de asistencia
   * @param {Object} providerId		Id del proveedor
   * @return {Object}  response  Lista de archivos.
   */
  function getFiles(assistance, providerId) {
      return $http.get('api/v1/assistances/' + assistance + '/files?providerId='+providerId)
          .then(getCustomerComplete);
  }
	/**
	 * @function getCurrentDate
	 * @desc Obtiene hora actual del servidor con time zone cero.
	 * @memberof Factories.CatalogService
	 * @param {Object}  providerId  id de proveedor.
	 * @param {Object}  assistanceId  id de asistencia.
	 * @param {Object}  map  mapa de datos.
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function getCatalogCosts(providerId, assistanceId, map) {
		return $http.post('api/v1/providers/' + providerId + '/assistances/' + assistanceId + '/costList', map)
			.then(getCustomerComplete);
	}

}// End service

angular
.module('app')
.service('CatalogService', CatalogService);
