angular
	.module('app')
	.run(runConfig)
	.config(routesConfig);
/**
  * @namespace routesConfig
  * @desc Manejo de rutas y permisos para las rutas del portal.
  * @memberOf Factories
  * @param      {Object}  $stateProvider      The state provider
 	* @param      {Object}  $urlRouterProvider  The url router provider
 	* @param      {Object}  $locationProvider   The location provider
  * @return 		{Object}	Rutas de proyecto y componentes asigados.
  */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
	$locationProvider.html5Mode(true).hashPrefix('');
	$urlRouterProvider.otherwise('provider/login');

	$stateProvider
		.state('login', {
			url: '/provider/login',
			component: 'app',
			data: {
				requiredLoggin : false
			}
		})
    .state('changePass', {
			url: '/provider/changePass',
			component: 'changePass',
			data: {
				requiredLoggin : true
			}
		})
		.state('assistance', {
			url: '/provider/assistance',
			component: 'assistance',
			data: {
				requiredLoggin : true,
				requiredRoles  : ['ASSITANCE_PROVIDER']
			}
		})
		.state('crane', {
			url: '/provider/crane',
			component: 'crane',
			data: {
				requiredLoggin : true,
				requiredRoles  : ['CRANE_OWNER']
			}
		})
		.state('incoming', {
			url: '/provider/incoming',
			component: 'incomingAssistanceTray',
			data: {
				requiredLoggin : true,
				requiredRoles  : ['ASSITANCE_PROVIDER']
			}
		})
		.state('history-assistance', {
			url: '/provider/history/assistance',
			component: 'assistance',
			data: {
				requiredLoggin : true,
				requiredRoles  : ['ASSITANCE_PROVIDER']
			}
		})
		.state('bandeja', {
      url: '/provider/bandeja',
      component: 'tray',
      data: {
        requiredLoggin : true,
        requiredRoles  : ['ASSITANCE_PROVIDER']
      }
    })
    .state('videowall', {
			url: '/provider/videowall',
			component: 'videoWall',
			data: {
				requiredLoggin : true,
				requiredRoles  : ['ASSITANCE_PROVIDER']
			}
		});

}

/**
 * @namespace routesConfig
 * @desc Validación de token y redireccion a ruta correspondiente.
 * @memberOf Factories
 * @param      {Object}  $rootScope             The root scope
 * @param      {Object}  $location              The location
 * @param      {Object}  $localForage           Libreria para almacenamiento local.
 * @param      {Object}  AuthenticationService  Servicio de autenticacion.
 * @param      {Object}  $transitions           The transitions
 * @param      {Object}  jwtHelper              Libreria para uso de token.
 * @param      {Object}  $cookies               Libreria para manejo de cookies.
 * @return 		 {Object}  void 			Redirección a login
 */
function runConfig($rootScope, $location, $localForage, AuthenticationService, $transitions, jwtHelper, $cookies) {
	$transitions.onStart({}, function(transition) {
		if (transition.to().data.requiredLoggin) {
			var bool = jwtHelper.isTokenExpired($cookies.get('dataAccess'));
			$localForage.getItem('dataUser').then(function(data) {
				if (bool || data === null) {
					$location.path('/provider/login');
					return transition.router.stateService.target('login');
				}
				if (data.changePassword) {
					$location.path('/provider/changePass');
				} else {
						$location.path('/provider/bandeja');
				}
			});
		}
	});
}
