/**
 * Componente Muestra el detalle de arrastre de grúa.
 * @module carDetailSupplyFuelController
 * @param {Object} ValueToClassHelper Ayuda para claces de elemntos html.
 * @return {Object} ctrl
 */
function carDetailDragCraneController(ValueToClassHelper) {
	var vm = this;

	/* Define funciones */
	vm.booleanReasigne = booleanReasigne;
	vm.stringToClass = stringToClass;

	/* Implementacion */
	/**
	 * @static
	 * @function
	 * @name booleanReasigne
	 * @desc    Pasa un objeto para ser procesado y asignar las clases de los iconos correspondientes.
	 * @param {Object} object		Objeto de json de una asistencia.
	 * @return {Object}	Objeto con clases para iconos correspondientes.
	 */
	function booleanReasigne(object) {
		return ValueToClassHelper.booleanReasigne(object);
	}
	/**
	 * @static
	 * @function
	 * @name stringToClass
	 * @desc       Ayuda para mapear icono de falla mecanica o electrica.
	 * @param      {Object}  valueString	Objeto del json de una sistencia.
	 * @return     {Object}		Objeto con clase de icono correspondiente.
	 */
	function stringToClass(valueString) {
		return ValueToClassHelper.stringToClass(valueString);
	}

}

angular
	.module('app')
	.component('carDetailDragCrane', {
		templateUrl: 'app/components/dragCrane/carDetailDragCrane/carDetailDragCrane.html',
		controller: carDetailDragCraneController,
		controllerAs : 'ctrl',
		bindings : {
			status : '<',
			detail : '<',
			asigna : '<'
		}
	});

