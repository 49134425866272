/**
 * Componente de vista muestra dirección de destino o origen con un mismo formato.
 * @module wrapperAddressController
 * @return {Object} ctrl
 */
function wrapperAddressController() {
}

angular
.module('app')
.component('wrapperAddress', {
	templateUrl: 'app/components/wrapperAddress/wrapperAddress.html',
	controller: wrapperAddressController,
	controllerAs : 'ctrl',
	bindings : {
		address : '<',
		location : '<'
	}
});

