/**
 * Componente Catalogo de grúas.<br>
 * Muestra lista de todas la gruas disponibles para el usuario, asi como informacion de expediente y coordenadas de la gruas con geolocalización.
 * @module cranesCatalogController
 *
 * @param      {Object}  EnvironmentConfig  Modulo con variables de configuración
 * @param      {Object}  CraneService  obtengo el servicio para actualizar el estatus
 * @param      {Object}  $document  obtiene la información del DOM
 * @param {Object} toastr  Servicio para alertas.
 * @param {Object} toastrConfig Servicio para configuracion de alertas.
 * @return  	 {Object}  Ctrl
 */
function cranesCatalogController(EnvironmentConfig, CraneService, $document, toastr, toastrConfig) {
	var vm = this;
	toastrConfig.positionClass = 'toast-top-center';


	/* Define variables */
	vm.cranes = null;
	vm.origin = [0, 0];

	/* Define funciones */
	vm.setMapData = setMapData;
	vm.maps = EnvironmentConfig.googleMaps;
	vm.Available = Available;
	vm.logout = logout;
	vm.releaseCranes = releaseCranes;
	vm.modalView = null;

	/* Inicialización */
	/*
	 * Actualiza la lista de gruas cuando se pulsa el boton de catalogo.
	 */
	var cranePrevious = null;
	this.$doCheck = function() {
		var craneCurrent = vm.cranesList;
		if (cranePrevious !== craneCurrent) {
			vm.cranes = craneCurrent;
			cranePrevious = craneCurrent;
			var tmp = [];
			vm.cranes.forEach(function(temp) {
				if (tmp.length === 0) {
					tmp.push(temp);
				} else if (tmp[tmp.length - 1].id === temp.id) {
					tmp[tmp.length - 1].expediente += ', ' + temp.expediente;
				} else {
					tmp.push(temp);
				}
			});
			vm.cranes = tmp;

			/**
						var i;
						var j;
						for (i = 0; i < vm.cranes.length; i++) {
							for (j = (i + 1); j < vm.cranes.length; j++) {
								if (vm.cranes[i].id === vm.cranes[j].id) {
									vm.cranes[i].expediente += ', ' + vm.cranes[j].expediente;
									vm.cranes.splice([j], [j]);
								}
							}
						} */
		}
	};

	/* Implementación */
	// NgMap.getMap().then(function() {});
	/** @static
	 * @function
	 * @name setMapData
	 * @description Inserta el valor de la posición de la grúa en el mapa.
	 * @param      {String} latitud  Valor de latitud.
	 * @param      {String} longitud Valor de longitud.
	 * @param      {String} crane Id grua.
	 * @return     {void} void
	 */
	function setMapData(latitud, longitud, crane) {
		// vm.modalView = 'catalog';
		// vm.origin = [latitud, longitud];
		vm.origin = [];
		vm.origin.push(crane);
	}

	/** @static
	 * @function
	 * @name Available
	 * @description Actualiza el estatus del Switch
	 * @param      {String} craneId  Valor de identificador.
	 * @return     {void} void
	 */
	function Available(craneId) {
		var show = $document[0].getElementById(craneId);
		var status = 0;
		if (show.checked === false) {
			status = 2;
		}
		CraneService.availableCrane(craneId, status);
	}
	/** @static
	 * @function
	 * @name Available
	 * @description Actualiza el estatus del Switch
	 * @param      {String} craneId  Valor de identificador.
	 * @return     {void} void
	 */
	function logout(craneId) {
		CraneService.logout(craneId).then(function(data) {
			if (data.isSuccess === false) {
				toastr.error(data.data.message, 'Cierre de sesión');
			}
		});
	}
	/** @static
	 * @function
	 * @name Release Crane
	 * @description Libera la grúa asignada a un servicio
	 * @param      {String} clCrane  clGrua de la unidad
	 * @param      {String} country  pais al que pertenece la unidad
	 * @param      {String} propviderId  id del proveedor
	 * @return     {void} void
	 */
	function releaseCranes(clCrane, country, providerId) {
		CraneService.releaseCranes(clCrane, country).then(function(data) {
			console.log(data);
			if (data.isSuccess === false) {
				toastr.error('No se pudo liberar la grúa', 'Liberación de grúa');
			} else {
				toastr.info('Grúa liberada en asignación y ocupación', 'Liberación de grúas');
			}
		});

		CraneService.getCraneList(providerId).then(function(data) {
			vm.cranes = data.data.craneInformationList;
		});
	}
} // End controller

angular
	.module('app')
	.component('cranesCatalog', {
		templateUrl: 'app/components/cranesCatalog/cranesCatalog.html',
		controller: cranesCatalogController,
		controllerAs: 'ctrl',
		bindings: {
			cranesList: '<'
		}
	});
