/**
 * Componente de vista para mostar el header del portal.
 * @module ikeHeaderController
 * @param {Object} $location  Objeto angular para uso de rutas.
 * @param {Object} $localForage  Libreria para almacenamiento local.
 * @param {Object} ChannelManagerService  Servicio manejo de canal para notificaciones.
 * @return {Object} ctrl
 */
function ikeHeaderController($window,$location, $localForage, ChannelManagerService) {
	var vm = this;
	vm.test = 'hola';
	/* Define variables */
	vm.logout = logout;

	$localForage.getItem('dataUser')
	.then(function(data) {
		vm.dataUser = data;
	});
	/**
	 * @static
	 * @function
	 * @name logout
	 * @description  Cierra el canal de comunicación y dirige a login.
	 * @return     {void} void
	 */
	function logout() {
		$localForage.clear().then(function() {
			ChannelManagerService.closeChannel();
			$location.path('/');
		  $window.location.reload(); // Utiliza $window para acceder a la recarga de la página
		});
	}
}

angular
.module('app')
.component('ikeHeader', {
	templateUrl: 'app/components/ikeHeader/ikeHeader.html',
	controller: ikeHeaderController,
	controllerAs: 'ctrl',
	bindings : {
		readyState : '<'
	}
});
