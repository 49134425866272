/**
 * Componente Muestra el detalle de paso de corriente.
 * @module carDetailPassCurrentController
 * @param {Object} ValueToClassHelper Ayuda para claces de elemntos html.
 * @return {Object} ctrl
 */
function carDetailPassCurrentController(ValueToClassHelper) {
	var vm = this;

	/* Define funciones */
	vm.booleanReasigne = booleanReasigne;

	/* Implementacion */
	/**
	 * @static
	 * @function
	 * @name booleanReasigne
	 * @desc    Pasa un objeto para ser procesado y asignar las clases de los iconos correspondientes.
	 * @param {Object} object		Objeto de json de una asistencia.
	 * @return {Object}	Objeto con clases para iconos correspondientes.
	 */
	function booleanReasigne(object) {
		return ValueToClassHelper.booleanReasigne(object);
	}
}

angular
.module('app')
.component('carDetailPassCurrent', {
	templateUrl: 'app/components/passCurrent/carDetailPassCurrent/carDetailPassCurrent.html',
	controller: carDetailPassCurrentController,
	controllerAs : 'ctrl',
	bindings : {
		status : '<',
		detail : '<'
	}
});
