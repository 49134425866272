/**
 * Componente costos para paso de corriente.
 * @module changeTireCostController
 * @return {Object} ctrl
 */
function changeTireCostController() {
var vm = this;
	/* Define variables */

	/* Define funciones */
	vm.CostCaptureC = CostCaptureC;
	vm.CostCaptureNC = CostCaptureNC;


	/* Implementacion */
	/** @static
	 * @function
	 * @name CostCaptureC
	 * @description Contruye objeto de costos capturado Carretero
	 * @param      {String}  service - id de asistencia
	 * @param      {String}  beginningtrip - costo por inicio de viaje
	 * @param      {String}  kilometerstraveledCost - costo por kilometros recorridos
	 * @param      {String}  kilometerstraveled - kilometros recorridos
	 * @param      {String}  tollbooths - costo por cabinas de peaje
	 * @param      {String}  waittime - costo por tiempo de espera
	 * @param      {String}  country - pais por proveedor
	 * @return  {void}
	 */
	function CostCaptureC(service, beginningtrip, kilometerstraveledCost, kilometerstraveled, tollbooths, waittime, country) {
        var res;
        if (country === 'CO') {
            res = {
                service: service,
                beginning_trip: beginningtrip,
                kilometers_traveled: kilometerstraveled,
                kilometers_traveledCost: kilometerstraveledCost
            };
        } else {
            res = {
                service: service,
                beginning_trip: beginningtrip,
                kilometers_traveled: kilometerstraveled,
                kilometers_traveledCost: kilometerstraveledCost,
                toll_booths: tollbooths,
                wait_time: waittime
            };
        }
        vm.getTimecos({
            service: res
        });
    }

    /** @static
     * @function
     * @name CostCaptureNC
     * @description Contruye objeto de costos capturado No Carretero
     * @param      {String}  service - id de asistencia
     * @param      {String}  changetire - costo por cambio de neumatico
     * @param      {String}  waittime - costo por tiempo de espera
     * @param      {String}  country - pais por proveedor
     * @return  {void}
     */
    function CostCaptureNC(service, changetire, waittime, country) {
        var res;
        if (country === 'CO') {
            res = {
                service: service,
                change_tire: changetire
            };
        } else {
            res = {
                service: service,
                change_tire: changetire,
                wait_time: waittime
            };

        }

        vm.getTimecos({
            service: res
        });
    }
}

angular
.module('app')
.component('changeTireCost', {
	templateUrl: 'app/components/wrapperCost/changeTireCost/changeTireCost.html',
	controller: changeTireCostController,
	controllerAs : 'ctrl',
	bindings : {
		data : '<',
		valCosts : '=',
		getTimecos : '&',
		service : '<',
		monitor : '<',
		status : '='
	}
});
