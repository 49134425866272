/**
  * @namespace ValueToClassHelper
  * @desc Supongo que ayuda con asignacion de claces a etiquetas en la vista, principalmente en la pestaña de maniobras.
  * @memberOf Factories
  * @return {Object} service
  */
function ValueToClassHelper() {

	var booleanTrue = 'icon-check';
	var booleanFalse = 'icon-close';
	var booleanNull = 'value-null';

	var service = {
		booleanToClass: booleanToClass,
		booleanReasigne: booleanReasigne,
		stringToClass: stringToClass,
		stringEmpty: stringEmpty
	};

	return service;
	/**
	 * @static
	 * @function
	 * @name booleanToClass
	 * @desc       Supongo que decide que icono regresaar a la vista check o close
	 * @memberOf Factories.ValueToClassHelper
	 * @param {Object} valueBoolean  Supongo que valor boleano obtenido del json que regresan los servicio de backend.
	 * @return {Object} Supongo que clase para asignar a una etiqueta en la vista.
	 */
	function booleanToClass(valueBoolean) {
		if (valueBoolean === null) {
			valueBoolean = booleanNull;
		} else {
			valueBoolean = valueBoolean ? booleanTrue : booleanFalse;
		}
		return valueBoolean;
	}
	/**
	 * @static
	 * @function
	 * @name booleanReasigne
	 * @desc       Supongo que itera un objeto con varias propiedades y verifica si son boleanas para asignar algun icono.
	 * @memberOf Factories.ValueToClassHelper
	 * @param {Object} object1  Supongo que objeto de json de una asistencia
	 * @return {Object} Supongo que objeto de json con clases de iconos asignadas
	 */
	function booleanReasigne(object1) {
		if (angular.isObject(object1)) {
			for (var property1 in object1) {
				if (typeof (object1[property1]) === 'boolean') {
					object1[property1] = booleanToClass(object1[property1]);
				}
			}
		} else if (typeof (object1) === 'boolean' || object1 === null) {
			object1 = booleanToClass(object1);
		}
		return object1;
	}
	/**
	 * @static
	 * @function
	 * @name stringToClass
	 * @desc       Supongo que ayuda a mapear icono de falla mecanica o electrica.
	 * @memberOf Factories.ValueToClassHelper
	 * @param {Object} valueString	Supongo que valor obtenido del json de una asistencia.
	 * @return {Object}		Clase asignada para ver en vista de portal icono de falla mecanica o electrica.
	 */
	function stringToClass(valueString) {
		switch (valueString) {
			case 'MECANICA':
				valueString = 'icon-wrench';
				break;
			case 'ELECTRICA':
				valueString = 'icon-car-battery';
				break;
			default:
				valueString = 'icon-wrench';
		}
		return valueString;
	}
	/**
	 * @static
	 * @function
	 * @name stringEmpty
	 * @desc       Supongo que valida si el valor recivido es null, 0 o '' y lo convierte a - para verlo en vista de poortal.
	 * @memberOf Factories.ValueToClassHelper
	 * @param {Object} valueString	Supongo que objeto obtenido de json de una asistencia.
	 * @return {Object}		Nuevo valor - asignado si cumple la condición.
	 */
	function stringEmpty(valueString) {
		if (valueString === null || valueString.length === 0 || valueString === '') {
			valueString = '-';
		}
		return valueString;
	}

} // End service

angular
	.module('app')
	.service('ValueToClassHelper', ValueToClassHelper);







