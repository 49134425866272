/**
  * @namespace AssistanceService
  * @desc Manejo de asistencias obtención, reasignacion y modificaciones.
  * @memberOf Factories
  * @param {Object} $http Peticiones
  * @return {Object} service
  */
function AssistanceService($http) {

	/**
	 * Funciones disponibles del servicio
	 * @memberof Factories.AssistanceService
	 * @property {function} getCraneListReassign - Obtiene lista de gruas disponibles para una reasignación
	 * @property {function} getIncomingAssistances
	 * @property {function} getTakeAssistances
	 * @property {function} getCraneList
	 * @property {function} postCancellationService
	 * @property {function} postRejectReason
	 * @property {function} postReassignReason
	 * @property {function} takeAssistance
	 * @property {function} postCommentReason
	 */
	var service = {
		getIncomingAssistances : getIncomingAssistances,
		getTakeAssistances : getTakeAssistances,
		getAssistancesDetail : getAssistancesDetail,
		getCraneList : getCraneList,
		postCancellationService : postCancellationService,
		postRejectReason : postRejectReason,
		postReassignReason : postReassignReason,
		takeAssistance : takeAssistance,
		getCraneListReassign : getCraneListReassign,
		postCommentReason : postCommentReason,
		confirmNotification : confirmNotification,
		failure : failure,
		upload : upload,
		auction : auction,
		rejectAuction : rejectAuction,
		vehicleInfo : vehicleInfo,
		failedContact : failedContact,
		takeAssistanceOneClick : takeAssistanceOneClick,
		etaAdditional : etaAdditional,
		localAcutionCost : localAcutionCost,
		subscribeTopic : subscribeTopic,
		takeAssistanceSd:takeAssistanceSd,
		takeAssistanceSdCosto:takeAssistanceSdCosto
	};

	/* Regresa la definicion del servicio */
	return service;

	/* IMPLEMENTACION */

	/**
	 * @static
	 * @function getCustomerComplete
	 * @desc Procesa respuesta de servicio.
	 * @memberof Factories.CraneService
	 * @param      {Object}  data  	Response de servicio.
	 * @return     {Object}  data  Objeto data contenido en el response
	 */
	function getCustomerComplete(data) {
		return data.data;
	}
	/**
	 * @function getIncomingAssistances
	 * @desc Recupera todas las asistencias en proceso.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  status  	Estatus de las asistencias a recuperar.
	 * @return     {Object}  response  Lista de asistencias.
	 */
	function getIncomingAssistances(provider, status) {
		return $http.get('api/v1/providers/' + provider + '/assistancelist?status=' + status + '&number=1')
			.then(getCustomerComplete);
	}
	/**
	 * @function getTakeAssistances
	 * @desc Recupera todas las asistencias entrantes.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  status  	Estatus de las asistencias a recuperar.
	 * @return     {Object}  response  Lista de asistencias.
	 */
	function getTakeAssistances(provider, status) {
		return $http.get('api/v1/providers/' + provider + '/assistancelist?status=' + status + '&number=1')
		.then(getCustomerComplete);
	}

	/**
	 * @function getAssistancesDetail
	 * @desc Recupera todas las asistencias entrantes.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  assistanceId  	 asistencias id.
	 * @return     {Object}  response  Lista de asistencias.
	 */
	function getAssistancesDetail(provider, assistanceId) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + assistanceId + '/detail')
	.then(getCustomerComplete);
	}
	/**
	 * @function getCraneList
	 * @desc Consulta lista de gruas para una asistencia.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor
	 * @param      {Object}  folio 			Id de asistencia
	 * @return     {Object}  response   respuesta de la invocación de servicio
	 */
	function getCraneList(provider, folio) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + folio + '/craneList')
		.then(getCustomerComplete);
	}
	/**
	 * @function takeAssistance
	 * @desc Toma de asistencia
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor
	 * @param      {Object}  assistance Id de asistencia
	 * @param      {Object}  data  			Contiene datos de la grua, para su asignación
	 * @return     {Object}  response   respuesta de la invocación de servicio
	 */
	function takeAssistance(provider, assistance, data) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/take', {
			craneid : data.craneId,
			typeAssignment : data.typeAssignment,
			eta : data.eta,
			requestFrom : 'portal'
		})
		.then(getCustomerComplete);
	}
	 /**
   	 * @function takeAssistanceSd
   	 * @desc Toma de asistencia
   	 * @memberof Factories.AssistanceService
   	 * @param      {Object}  provider  	Id de proveedor
   	 * @param      {Object}  assistance Id de asistencia
   	 * @param      {Object}  data  			Contiene datos de la grua, para su asignación
   	 * @return     {Object}  response   respuesta de la invocación de servicio
  */
   	function takeAssistanceSd(provider, assistance, data) {
				return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/take', {
   			craneid : '0',
   			typeAssignment : "3",
   			eta : data.eta,
   			requestFrom : 'portal'
   		})
   		.then(getCustomerComplete);
   	}
 /**
     *function takeAssistanceSdCosto
   	 * @desc Toma de asistencia
   	 * @memberof Factories.AssistanceService
   	 * @param      {Object}  provider  	Id de proveedor
   	 * @param      {Object}  assistance Id de asistencia
   	 * @return     {Object}  response   respuesta de la invocación de servicio
*/
 	function takeAssistanceSdCosto(provider, assistance, res) {
	   return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/costsLocalAuction?requestFrom=portal',{
        "costs": res.costs,
        "comments": res.comments,
        "estimatedTime": res.estimatedTime
        })
		  .then(getCustomerComplete);
   	}
	/**
	 * @function takeAssistance
	 * @desc Toma de asistencia
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor
	 * @param      {Object}  assistance Id de asistencia
	 * @param      {Object}  data  			Contiene datos de la grua, para su asignación
	 * @return     {Object}  response   respuesta de la invocación de servicio
	 */
	function takeAssistanceOneClick(provider, assistance, data) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/takeAssistance', {
			typeAssignment : data.typeAssignment
		})
		.then(getCustomerComplete);
	}
	/**
	 * @function postCancellationService
	 * @desc Solicitud de cancelación de asistencia
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  data  			Contiene id de proveedor, id de servicio, razon de cancelacion y id de tipo de asignación
	 * @return     {Object}  response    Respuesta de la invocación de servicio
	 */
	function postCancellationService(data) {
		return $http.post('/api/v1/providers/' + data.idProvider + '/assistances/' + data.idService + '/cancel', {
			motifid : data.reason,
			assignmentid : data.assignmentid,
			requestFrom : 'portal'
		})
		.then(getCustomerComplete);
	}
	/**
	 * @function postRejectReason
	 * @desc Rechazo de asistencia.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  data  			Contiene id de proveedor, id de servicio, razon de reasignacion y id de grua
	 * @return     {Object}  response    respuesta de la invocación de servicio
	 */
	function postRejectReason(data) {
		data.requestFrom = 'portal';
		return $http.put('api/v1/providers/' + data.idProvider + '/assistances/' + data.idService + '/reject/' + data.reason)
		.then(getCustomerComplete);
	}
	/**
	 * @function postReassignReason
	 * @desc Reasignacion de grua
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  data  			Contiene id de proveedor, id de servicio, razon de reasignacion y id de grua
	 * @return     {Object}  response    respuesta de la invocación de servicio
	 */
	function postReassignReason(data) {
		return $http.post('api/v1/providers/' + data.idProvider + '/assistances/' + data.idService + '/reassignment/' + data.reason, {
			craneId : data.craneId,
			timeAditional : data.timeAditional,
			requestFrom : 'portal',
			name : data.name,
			lastName : data.lastName
		})
		.then(getCustomerComplete);
	}
	/**
	 * @function getCraneListReassign
	 * @desc Obtienen lista de gruas disponibles para una reasignacion, enviando la bandera reassign = true
	 * @memberof Factories.AssistanceService
	 * @param      {string}  providerId  id de proveedor
	 * @param      {string}  exp         id de expediente
	 * @return     {object}  response    respuesta de la invocación de servicio
	 */
	function getCraneListReassign(providerId, exp) {
		return $http.get('api/v1/providers/' + providerId + '/assistances/' + exp + '/craneList?reassign=true')
		.then(getCustomerComplete);
	}
	/**
	 * @function postCommentReason
	 * @desc  Envio de comentarios para una asistencia.
	 * @memberof Factories.AssistanceService
	 * @param {Object}  data  Objeto con datos de proveedor, asistencia y comentario.
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function postCommentReason(data) {
		return $http.put('api/v1/providers/' + data.idProvider + '/assistances/' + data.idService + '/provider_comments?comment=' + data.txtComment + '&code=' + data.idComment + '&requestFrom=portal')
		.then(getCustomerComplete);
	}
	/**
	 * @function ConfirmNotification
	 * @desc  Confirmacion de notificacion entrante.
	 * @memberof Factories.AssistanceService
	 * @param {Object}  data  Objeto con datos de proveedor, tipo de notificacion entrante.
	 * @return     {void}
	 */
	function confirmNotification(data) {
		return $http.put('api/v1/confirm_notification', data)
		.then(getCustomerComplete);
	}
	/**
  	 * @function failure
  	 * @desc  Informa asistencia con eta en retraso.
  	 * @memberof Factories.AssistanceService
  	 * @param {Object}  provider  id de proveedor.
  	 * @param {Object}  assistance  id de asistencia.
  	 * @return     {void}
  	 */
	function failure(provider, assistance) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance.id + '/failure_eta', {
			typeAssignment : assistance.typeAssignment.id
		})
		.then(getCustomerComplete);
	}
	/**
	 * @function upload
	 * @desc  Carga de archivos.
	 * @memberof Factories.AssistanceService
	 * @param {Object}  provider  id de proveedor.
	 * @param {Object}  folio  Asistencia.
	 * @param {Object}  file  archivo a argar.
	 * @param {Object}  serviceId  archivo a argar.
	 * @return     {void}
	 */
	function upload(provider, folio, file, serviceId) {
        var fd = new FormData();

                       fd.append('file', file);
                       return $http.post('api/v1/assistances/' + serviceId + '/fileUpload', fd, {
                           transformRequest: angular.identity,
                           headers: {'Content-Type': undefined}
                       })
                       .then(getCustomerComplete);

	}
		/**
      	 * @function failure
      	 * @desc  Informa asistencia con eta en retraso.
      	 * @memberof Factories.AssistanceService
      	 * @param {Object}  provider  id de proveedor.
      	 * @param {Object}  assistance  id de asistencia.
      	 * @param {Object}  res  mapa de datos.
      	 * @return     {void}
      	 */
  function localAcutionCost(provider, assistance, res) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/costsLocalAuction?requestFrom=portal', res)
		.then(getCustomerComplete);
  }
	/**
    	 * @function failure
    	 * @desc  Informa asistencia con eta en retraso.
    	 * @memberof Factories.AssistanceService
    	 * @param {Object}  provider  id de proveedor.
    	 * @param {Object}  assistance  id de asistencia.
    	 * @param {Object}  res  mapa de datos.
    	 * @return     {void}
    	 */
	function auction(provider, assistance, res) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/AssistanceAuction', res)
		.then(getCustomerComplete);
	}
	/**
		 * @function rejectAuction
		 * @desc  Informa rechazo de participacion en asistencias subastadas.
		 * @memberof Factories.AssistanceService
		 * @param {Object}  provider  id de proveedor.
		 * @param {Object}  assistance  id de asistencia.
		 * @return     {void}
		 */
	function rejectAuction(provider, assistance) {
		return $http.put('api/v1/providers/' + provider + '/assistances/' + assistance + '/rejectAuction')
		.then(getCustomerComplete);
	}

	/**
	 * @function vehicleInfo
	 * @desc  Informa asistencia con eta en retraso.
	 * @memberof Factories.AssistanceService
	 * @param {Object}  provider  id de proveedor.
	 * @param {Object}  assistance  id de asistencia.
	 * @param {Object}  info  contenido where, who.
	 * @return     {void}
	 */
	function vehicleInfo(provider, assistance, info) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/vehicleinfo', info)
		.then(getCustomerComplete);
	}
	/**
	 * @function failedContact
	 * @desc  Estatus de contacto fallido.
	 * @memberof Factories.AssistanceService
	 * @param {Object}  provider  id de proveedor.
	 * @param {Object}  assistance  id de asistencia.
	 * @return     {void}
	 */
	function failedContact(provider, assistance) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/failed_status')
		.then(getCustomerComplete);
	}
	/**
	 * @function failedContact
	 * @desc  Estatus de contacto fallido.
	 * @memberof Factories.AssistanceService
	 * @param {Object}  provider  id de proveedor.
	 * @param {Object}  assistance  id de asistencia.
	 * @param {Object} 	data  informacion de eta actualziado.
	 * @return     {void}
	 */
	function etaAdditional(provider, assistance, data) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/update_eta', {
			reason : data.reason,
			dsreason : data.dsreason,
			timeAditional : data.timeAditional,
			country : data.country
		}).then(getCustomerComplete);
	}
	/**
  	 * @function subscribeTopic
  	 * @desc Suscripción del token para topic
  	 * @memberof Factories.AssistanceService
  	 * @param      {Object}  token  			Contiene el token a suscribir en el topic
  	 * @param      {Object}  topic  			Contiene el topic
  	 * @return     {void}
  	 */
	function subscribeTopic(token, topic) {
			return $http.post('api/v1/providers/subscribe/topic/', {
				topic : topic,
				token : token
			})
			.then(getCustomerComplete);
	}
} // End service

angular
	.module('app')
	.service('AssistanceService', AssistanceService);
