/**
   * Componente Catalogo de grúas.<br>
   * Muestra lista de todas la gruas disponibles para el usuario, asi como informacion de expediente y coordenadas de la gruas con geolocalización.
   * @module videoWallController
   *
   * @param      {Object}  EnvironmentConfig  Modulo con variables de configuración
   * @param      {Object}  $document  Obtiene elementos de DOM
   * @param      {Object}  CraneService  Servicio de
   * @param      {Object}  $localForage  Obtiene datos de sesion de proveedor
   * @param      {Object}  toastr  Notificaciones
   * @param      {Object}  toastrConfig  Configuracion de notificaciones
   * @param      {Object}  $window  Configuracion para contructores
		* @param      {Object}  $timeout  Contador de tiempo para actualziar
   * @return  	 {Object}  Ctrl
   */
function videoWallController(EnvironmentConfig, $document, CraneService, $localForage, toastr, toastrConfig, $window, $timeout) {
	var vm = this;
	toastrConfig.positionClass = 'toast-top-center';
	vm.cranes1 = null;
	vm.cranes = null;
	vm.setTitle = setTitle;
	vm.marker = [];
	vm.polyline = '';
	vm.s = '';
	vm.show = '';
	vm.info = '';
	vm.popupinfo = true;
	var imagensvg = 'M15,10H20.39L17.06,6H15V10M17.5,18.5C17.89,18.5 18.24,18.37 18.54,18.07C18.84,17.77 19,17.42 19,17C19,16.61 18.84,16.26 18.54,15.96C18.24,15.66 17.89,15.5 17.5,15.5C17.08,15.5 16.73,15.66 16.43,15.96C16.13,16.26 16,16.61 16,17C16,17.42 16.13,17.77 16.43,18.07C16.73,18.37 17.08,18.5 17.5,18.5M6,18.5C6.44,18.5 6.8,18.37 7.08,18.07C7.36,17.77 7.5,17.42 7.5,17C7.5,16.61 7.36,16.26 7.08,15.96C6.8,15.66 6.44,15.5 6,15.5C5.56,15.5 5.2,15.66 4.92,15.96C4.64,16.26 4.5,16.61 4.5,17C4.5,17.42 4.64,17.77 4.92,18.07C5.2,18.37 5.56,18.5 6,18.5M18,4L23,10V17H20.5C20.5,17.83 20.19,18.53 19.59,19.13C19,19.72 18.3,20 17.5,20C16.67,20 15.97,19.72 15.38,19.13C14.78,18.53 14.5,17.83 14.5,17H9C9,17.83 8.7,18.53 8.11,19.13C7.5,19.72 6.81,20 6,20C5.19,20 4.5,19.72 3.89,19.13C3.3,18.53 3,17.83 3,17H1V13H9.19L3,8.11V11H1V5H2L13,11.06V4H18Z';

	var L = $window.L;
	// initial configuration map
	vm.mapOptions = {
		zoom: 10,
		preferCanvas: false
 };
	// Creating a map object
	/**
	var container = L.DomUtil.get('mapvideowall');
	if(container != null){
		console.log('entra');
		container._leaflet_id = null;
	} */

	var map = new L.map('mapvideowall', vm.mapOptions);

	// Creating a Layer object
	var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
	// Adding layer to the map
	map.addLayer(layer);

	// Icon options
	// var iconOptions = { iconUrl: '../icons/START.png', iconSize: [50, 50]};
	// Creating a custom icon
	// var customIcon = L.icon(iconOptions);

	// NgMap.getMap().then(function() {});

	/* Inicialización */
	/*
	 * Actualiza la lista de gruas cuando se pulsa el boton de catalogo.
	 */
	var cranePrevious = null;
	this.$doCheck = function() {
		var craneCurrent = vm.cranesList;
		if (cranePrevious !== craneCurrent) {
			vm.cranes = craneCurrent;
			vm.cranes1 = vm.cranes;
			if (vm.cranes1.length > 0) {
				try {
					remove();
					vm.cranes1.forEach(function(temp) {
						if ((temp.latitude !== '0' && temp.latitude !== null) && (temp.longitude !== '0' && temp.longitude !== null)) {
							// Creating a marker catalog and videowall
							if (temp.type === 'AUTO UTILITARIO') {
								markersmap(temp, 'icon-carro-taller', 'Auto');
							} else {
								markersmap(temp, 'icon-towing', 'Grua');
							}
						}
					});
					// Resize map
					map.invalidateSize();
					if (vm.cranes1.length === 1) { // entra a catalogo de gruas
						map.setView(new L.LatLng(vm.cranes1[0].latitude, vm.cranes1[0].longitude), 17);
					} else {
						// limited zomm
						var group = new L.featureGroup(vm.marker);
						var bounds = group.getBounds().extend(group.getBounds());
						map.fitBounds(bounds);
					}
					$timeout(function() {
					map.invalidateSize();
					}, 200);
				} catch (error) {}
			}
			cranePrevious = craneCurrent;
		}
	};

    /**
     * @static
     * @function
     * @name onClick
     * @description Mostrara el tracking de la grua seleccionada al momento en caso de tener servicio asignado
     * @param {String} e informacion de la grua.
     * @return  {void} void
     */
    function onClick(e) {
			if (e.type === 'click') {
				if (vm.popupinfo) {
					if (angular.isDefined(vm.assistanceInprogress)) {
						vm.assistanceInprogress.forEach(function (temp) {
							if (e.sourceTarget.options.customId.expediente === temp.folio) {
								vm.popupinfo = false;
							} else {
								var t = $document[0].getElementById(temp.folio);
								try {
									t.appendChild($document[0].getElementById('grua' + temp.folio));
								} catch (error) {
								}
							}
						});
					}
				} else {
					refresh();
				}
			}
			if (e.type === 'dblclick') {
				/** vm.assistanceInprogress.forEach(function (temp) {
						var t = $document[0].getElementById(temp.folio);
						try {
							t.appendChild($document[0].getElementById('grua' + temp.folio));
						} catch (error) { error }
				});
				*/
				refresh();
				if (vm.modalView !== 'CraneCatalog') {
					if (e.sourceTarget.options.customId.expediente) {
							remove();
							getCraneLocation(e.sourceTarget.options.customId);
					} else {
							toastr.warning('Grua no tiene serivcio en proceso', 'Video Wall');
					}
				}
			}
    }

    map.on('click', function(e) {
			if (e.type === 'click') {
				refresh();
			}
    });

		/**
		 * @static
		 * @function
		 * @name refresh
		 * @description refresaca bandeja en progreso
		 * @return  {void} void
		 */
    function refresh() {
			if (vm.assistanceInprogress) {
				vm.assistanceInprogress.forEach(function (temp) {
				var t = $document[0].getElementById(temp.folio);
					t.appendChild($document[0].getElementById('grua' + temp.folio));
				});
			}
			vm.popupinfo = true;
    }
    /**
     * @static
     * @function
     * @name remove
     * @description Elimina markers y lineas en el mapa
     * @return  {void} void
     */
    function remove() {
			// Remove a marker
			vm.marker.forEach(function(a) {
					map.removeLayer(a);
			});
			vm.marker = [];
			// Remove a marker
			if (vm.polyline !== '') {
					map.removeLayer(vm.polyline);
			}
    }

    /**
     * @static
     * @function
     * @name getCraneLocation
     * @description ???
     * @param {String} dataCrane  Informacion de la grua.
     * @return  {void} void
     */
    function getCraneLocation(dataCrane) {
        var latlngs = [];
				$localForage.getItem('dataUser').then(function(data) {
					CraneService.getCraneLocation(data.id, dataCrane.assistance_id).then(function (data) {
						if (data.isSuccess) {
						data.data.craneLocations.forEach(function(temp) {
							if (temp.craneId === dataCrane.id) {
								temp.locationCrane = _.filter(temp.locationCrane, function(t) {
									return ((t.latitude !== '0.0' && t.latitude !== '0' && t.latitude !== null) && (t.longitud !== '0.0' && t.longitud !== '0' && t.longitud !== null));
							});
								temp.locationCrane.forEach(function(temp1) {
									latlngs.push([temp1.latitude, temp1.longitud]);
								});
								dataCrane.latitude = temp.locationCrane[0].latitude;
								dataCrane.longitude = temp.locationCrane[0].longitud;
							}
						});
						if (latlngs.length > 0) {
							// Creating a poly line
							vm.polyline = L.polyline(latlngs, {color: 'blue'});
							// Adding to poly line to map
							vm.polyline.addTo(map);
							// centrar ruta en mapa
							map.fitBounds(vm.polyline.getBounds());
						}
					}
				});
			});
			if (dataCrane.type === 'AUTO UTILITARIO') {
				markersmap(dataCrane, 'icon-carro-taller', 'Auto');
			} else {
				markersmap(dataCrane, 'icon-towing', 'Grua');
			}
			markersmap(dataCrane, 'icon-person-pin', 'Origen');
			markersmap(dataCrane, 'icon-pin-drop', 'Destino');
			$timeout(function() {
				map.invalidateSize();
			}, 300);
    }

    /** @static
			* @function
			* @name markersmap
			* @description Genera marker en el mapa.
			* @param {String} temp	datos de la grua para mostrar.
			* @param {String} icon	datos de la grua para mostrar.
			* @param {String} type	datos de la grua para mostrar.
			* @return {void} sin retorno de informacion
			*/
    function markersmap(temp, icon, type) {
			var latlng = '';
			var imagen = '';
			var costum = '';
			var a = '';
			if (type === 'Grua' || type === 'Auto') {
				vm.s = _.findIndex(vm.assistanceInprogress, function (o) {
					return o.id === temp.assistance_id;
				});
				if (type === 'Auto') {
					imagensvg = 'M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z';
				}
				if (temp.assistance_status > 3) {
					a = temp.assistance_status;
				}
				a = vm.s > -1 ? vm.assistanceInprogress[vm.s].status.arrival : 10;
				var time = ((Math.floor((vm.getDate - temp.last_update) / 1000)) / 60);

				if (time > 4 && vm.s === -1 && temp.busy !== '2')	 {
					a = 7;
				}
				if (time < 4 && vm.s === -1 && temp.busy === '0') {
					a = 8;
				}
				if (temp.busy === 2) {
					a = 10;
				}
				switch (a) {
					case 0:
					case 1:
						imagen = 'red';
						break;
					case 2:
						imagen = 'yellow';
						break;
					case 3:
						imagen = 'green';
						break;
					case 4:
						// contactado
						imagen = 'blue';
						break;
					case 5:
						// contactado llegando a destino
						imagen = '#00D3D3';
						break;
          case 7:
						// Disponible con reporte de ubicacion vencido mayor a 3 min
						imagen = 'gray';
						break;
					case 8:
						// Disponible con reporte de ubicacion menor a 3 min
						imagen = 'white';
						break;
          default:
						// descanso, no disponible
						imagen = 'black';
        }
				if (vm.s > -1 && vm.modalView !== 'CraneCatalog') {
					costum = $document[0].getElementById('grua' + temp.expediente);
				} else {
					costum = '<div>' +
					' Grua : ' + temp.cl_economic +
					'<br> Version app : ' + temp.app_version +
					'<br> Ultima actualizacion : ' + (new Date(temp.last_update)).toLocaleString('en-US', {hour12 : true}) +
					'<br> Ubicaciones : ' + temp.latitude + ', ' + temp.longitude +
					'<br> Placa : ' + temp.plaque +
					'<br> Tipo de grua : ' + temp.type +
					'</div>';
				}
				latlng = [temp.latitude, temp.longitude];

			}

			if (type === 'Origen') {
				latlng = [temp.origin_lat, temp.origin_lng];
				costum = '<div> Origen </div>';
			}
			if (type === 'Destino') {
				latlng = [temp.destination_lat, temp.destination_lng];
				costum = '<div> Destino </div>';
			}
			var imagensvg1 = 'fill="' + imagen + '" stroke="' + (imagen === 'white' ? '#000' : '') + '"  d="' + imagensvg + '"';

			var iconSettings = {
						mapIconUrl: '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 24 24"><path ' + imagensvg1 + ' /></svg>'
				};

			// icon normal state
			var divIcon = L.divIcon({
					className: 'leaflet-data-marker',
					html: L.Util.template(iconSettings.mapIconUrl, iconSettings),
					iconAnchor: [12, 32],
					iconSize: [25, 30],
					popupAnchor: [0, -28]
			});

			var markerOptions = {
					title : type === 'Grua' ? ('Grua : ' + temp.cl_crane + ' ' + (temp.expediente ? 'Expediente : ' + temp.expediente : '')) : ('Auto : ' + temp.cl_crane + ' ' + (temp.expediente ? 'Expediente : ' + temp.expediente : '')),
					clickable : false,
					draggable : false,
					icon : type === 'Grua' ? divIcon : divIcon,
					customId : temp
			};
			// specify popup options
			var customOptions = {maxWidth : '2000', className : 'custom'};
			var gr = true;
			latlng.forEach(function (temp) {
				if (temp === '' || temp === '0') {
					gr = false;
				}
			});
			if (gr) {
				if (costum === '') {
					vm.marker.push(L.marker(latlng, markerOptions).addTo(map).on('dblclick', onClick).on('click', onClick));
				} else {
					vm.marker.push(L.marker(latlng, markerOptions).bindPopup(costum, customOptions).addTo(map).on('dblclick', onClick).on('click', onClick));
				}
			}
    }

	/** @static
  		* @function
  		* @name setTitle
  		* @description Respondera texto a mostrar en gruas con o sin expediente asignado.
  		* @param {String} crane	Numero economico de grua.
  		* @param  {String} exp	Obtiene el numero de expediete.
  	 	* @return {String} texto a mostrar
  		*/
	function setTitle(crane, exp) {
		var text = 'Grúa : ' + crane + '  Expediente : ' + exp;
		if (!exp) {
			text = 'Grúa : ' + crane;
		}

		return text;
	}


}

angular
  .module('app')
  .component('videoWall', {
    templateUrl: 'app/components/videoWall/videoWall.html',
    controller: videoWallController,
    controllerAs: 'ctrl',
    bindings: {
			cranesList: '<',
			modalView: '<',
			assistanceInprogress: '<',
			getDate : '<'
		}
  });
