/**
 * Componente de vista para mostar datos genericos en las asistencias entrantes.
 * @module wrapperGenericController
 * @return {Object} ctrl
 */
function wrapperGenericController() {
}

angular
  .module('app')
  .component('wrapperGeneric', {
    templateUrl: 'app/components/wrapperGeneric/wrapperGeneric.html',
    controller: wrapperGenericController,
    controllerAs : 'ctrl',
		bindings: {
			agreement : '<',
			user : '<',
			car : '<',
			origin : '<',
			destination : '<',
			service : '<',
			country : '<',
      pevent : '<'
		}
  });

