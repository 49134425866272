/**
  * @namespace ChangePassword
  * @desc Obtencion de catalogos.
  * @memberOf Factories
  * @param {Object} $http Peticiones
  * @return {Object} service
  */
	function ChangePassword($http) {
/* Define las fuciones accesibles */
	var service = {
		changepass : changepass
	};
	/* Regresa la definicion del servicio */
	return service;

	/**
  	 * @static
  	 * @function postChangeComplete
  	 * @desc Procesa respuesta de servicio.
  	 * @memberof Factories.ChangePassword
  	 * @param      {Object}  data  	Response de servicio.
  	 * @return     {Object}  data  Objeto data contenido en el response
  	 */
	function postChangeComplete(data) {
		return data;
	}
	/**
  	 * @function changepass
  	 * @desc envia usuario y contraseña nueva.
  	 * @memberof Factories.Authentication
  	 * @param      {Object}  providerid  	id de usuario logeado.
  	 * @param      {Object}  user1  	nombre de usuario.
  	 * @param      {Object}  password1 	password de usuario.
  	 * @param      {Object}  confirmacion1 	password de usuario confirmado.
  	 * @return     {Object}  response   Json con datos de actualizacion correta.
  	 */
	function changepass(providerid, user1, password1, confirmacion1) {
		return $http.post('api/v1/' + providerid + '/changepasswod', {
				userapp : 0,
				user : user1,
				password : password1,
				confirmacion : confirmacion1
		})
		.then(postChangeComplete);
	}
}

angular
  .module('app')
  .service('ChangePassword', ChangePassword);

