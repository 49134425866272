/**
 * Componente informativo en de estatus de asistencia y datos relevantes en bandeja de proceso.
 * @module wrapperStageController
 * @param      {Function}  $timeout   The timeout
 * @param      {Object}    $filter    The filter
 * @param      {Object}    $document  The document
 * @param      {Object}    AssistanceService  Servicio para acciones sobre asistencias.
 * @param      {Object}    $localForage  libreria para almacenamiento local.
 * @param      {Object}    HelpUtilityCtrl validacion de estatus de asistencia.
 * @param      {Object}    toastr notificacion.
 * @param      {Object}    toastrConfig configuracion de notificacion.
 * @param      {Object}    CatalogService Obtener hora actual.
 * @return     {Object}    ctrl
 */
function wrapperStageController($timeout, $filter, $document, AssistanceService, $localForage, HelpUtilityCtrl, toastr, toastrConfig, CatalogService) {
	var vm = this;

	toastrConfig.positionClass = 'toast-top-center';
	var audio = new Audio('../bower_components/audio/notification1.mp3');
	// vm.elapsed = null;
	vm.time = null;
	vm.eta = 0;
	vm.etaAdditional = 0;
	vm.s = 0;
	vm.m = 0;
	vm.dif = '';
	vm.class = 'stage-light stage-end';
	vm.estilo = {color: '#777'};
	vm.arrival = 3;
	vm.active = true;
	vm.cita = true;
	vm.appointment = false;
	vm.appointmentDate = '';
	vm.notification = false;
	vm.failure = true;

	vm.detail = detail;
	vm.timeArrival = timeArrival;
	vm.timeArrivalcita = timeArrivalcita;
	vm.trafficLight = trafficLight;
	vm.getAssistancesDetail = getAssistancesDetail;

	$localForage.getItem('dataUser').then(function(data) {
		vm.country = data.country;
  });
	/** @static
	 * @function
	 * @name detail
	 * @param {Object} assistanceId  id de asistencia
	 * @description Muesta u ocualta el detalle de una asistencia.
	 * @return	{void}
	 */
	function detail(assistanceId) {
		getAssistancesDetail(assistanceId);
		if (vm.showDetail) {
			vm.flagDetailChatBot = null;
			vm.showDetail = false;
		} else {
			vm.showDetail = true;
		}
	}
	/**
	 * @static
	 * @function
	 * @name getAssistancesDetail
	 * @description Muestra el detalle completo de la asistencia
	 * @param {String} assistanceId  Id de asistencia.
	 * @return  {void} Asigna el resultado a la variable a uno de los valores de vm.assistance
	 */
	function getAssistancesDetail(assistanceId) {
		$localForage.getItem('dataUser').then(function(data) {
			if (vm.showDetail) {
				AssistanceService.getAssistancesDetail(data.id, assistanceId).then(function (data) {
					var fullDetail = data.data;
					if (fullDetail !== null) {
						if (vm.assistance.folio === fullDetail.folio) {
							vm.assistance.agreement = fullDetail.agreement;
							vm.assistance.showDestiny = fullDetail.showDestiny;
							vm.assistance.appointment = fullDetail.appointment;
							vm.assistance.appointmentDate = fullDetail.appointmentDate;
							vm.assistance.assistanceMonitoringData = fullDetail.assistanceMonitoringData;
							vm.assistance.car = fullDetail.car;
							vm.assistance.craneInformation = fullDetail.craneInformation;
							vm.assistance.customerSurvey = fullDetail.customerSurvey;
							vm.assistance.destination = fullDetail.destination;
							vm.assistance.distance = fullDetail.distance;
							vm.assistance.eta = fullDetail.eta;
							vm.assistance.etaAdditional = fullDetail.etaAdditional;
							vm.assistance.folio = fullDetail.folio;
							vm.assistance.id = fullDetail.id;
							vm.assistance.inventory = fullDetail.inventory;
							vm.assistance.origin = fullDetail.origin;
							vm.assistance.relevant = fullDetail.relevant;
							vm.assistance.service = fullDetail.service;
							vm.assistance.serviceDetails = fullDetail.serviceDetails;
							vm.assistance.subservice = fullDetail.subservice;
							vm.assistance.takenDate = fullDetail.takenDate;
							vm.assistance.tracking = fullDetail.tracking;
							vm.assistance.typeAssignment = fullDetail.typeAssignment;
							vm.assistance.user = fullDetail.user;
							vm.assistance.vehicle = fullDetail.vehicle;
							fullDetail = HelpUtilityCtrl.statusDescriptionMappingOneAssistance(fullDetail);
							vm.assistance.status.code = fullDetail.status.code;
              vm.assistance.status.description = fullDetail.status.description;
              vm.assistance.status.id = fullDetail.status.id;
              vm.assistance.status.status = fullDetail.status.status;
              vm.assistance.status.status_type = fullDetail.status.status_type;
              vm.assistance.status.statusOrdinal = fullDetail.status.statusOrdinal;
              fullDetail = undefined;
						}
					}
				});
			}
		});
	}

	// timer callback
	var timer = function() {
		if (vm.assistance !== null && (vm.assistance.status.status_type === '0') /** && !(vm.assistance.status.description === 'Cancelación de Proveedor' || vm.assistance.status.description === 'Cancelación posterior' || vm.assistance.status.description === 'Asistencia Concluida' || vm.assistance.status.description === 'Proveedor Concluido' || vm.assistance.status.description === 'Proveedor no Contacto Nuestro Usuario' || vm.assistance.status.description === 'Cancelado al momento') */) {
			if (vm.active) {
				// vm.assistance.eta = null;
				if (vm.assistance.eta) {
					if (angular.isUndefined(vm.getArrival) || (vm.appointment !== vm.assistance.appointment || (vm.appointmentDate !== vm.assistance.appointmentDate && vm.assistance.appointment === true)) || ((vm.eta - (vm.assistance.etaAdditional * 60)) !== (vm.assistance.eta * 60) && vm.assistance.appointment === false)) {
						if (angular.isDefined(vm.getArrival)) {
							CatalogService.getCurrentDate().then(function(data) {
								vm.getDate = data.data.defMessage;
							});
						}
						vm.appointment = vm.assistance.appointment;
						vm.appointmentDate = vm.assistance.appointmentDate;
						// vm.cita = false;
						if (vm.assistance.appointment) {
							vm.timeArrivalcita();
						} else {
							vm.timeArrival();
						}
					}
					/**
					if (vm.appointment !== vm.assistance.appointment || vm.appointmentDate !== vm.assistance.appointmentDate) {
						vm.cita = true;
					} */
					/**
					if (vm.assistance.status.description === 'Usuario contactado' && vm.getArrival !== 4 && vm.getArrival !== 5) {
						vm.assistance.status.arrival = 4;
						vm.getArrival = 4;
					}
					if (vm.getArrival === 4 || vm.getArrival === 5) {
						vm.class = 'stage-light stage-contact';
						vm.estilo = null;
						vm.showDestiny = true;
						if (vm.getArrival === 4) {
							$document[0].getElementById(vm.assistance.folio).scrollIntoView(true);
						}
						vm.active = false;
						vm.estilo = {color: '#777'};
					} */
					if (vm.assistance.status.description === 'Usuario contactado' || (vm.getArrival === 4 || vm.getArrival === 5)) {
					// } if (vm.getArrival === 4 || vm.getArrival === 5) {
						vm.class = 'stage-light stage-contact';
						vm.estilo = null;
						vm.showDestiny = true;
						if (vm.getArrival === 4) {
							$document[0].getElementById(vm.assistance.folio).scrollIntoView(true);
						}
						vm.active = false;
						vm.estilo = {color: '#777'};
						vm.assistance.status.arrival = 4;
						vm.getArrival = 4;
					} else {
						vm.trafficLight();
					}
				} else {
					vm.estilo = {color: '#777'};
					vm.assistance.status.arrival = 7;
					vm.elapsed = '--:--:--';
				}
			}
			$timeout(timer, 1000);
		}	else {
			vm.class = 'stage-light stage-end';
			vm.assistance.status.arrival = 8;
			timer = null;
		}
	};

	/** @static
	 * @function
	 * @name timeArrival
	 * @description Realiza calculo de tiempo transcurrido desde que se toma la asistecia en cada tarjeta
	 * @return	{void}
	 */
	function timeArrival() {
		if (vm.assistance.eta) {
			vm.eta = (parseInt(vm.assistance.eta, 0) + ((angular.isDefined(vm.etaAdditional) ? parseInt(vm.assistance.etaAdditional, 0) : 0))) * 60;
		}
		vm.time = vm.eta - Math.floor((vm.getDate - (vm.assistance.takenDate * 1000)) / 1000);
	}
	/** @static
  	 * @function
  	 * @name timeArrivalcita
  	 * @description Realiza calculo de tiempo transcurrido desde que se toma la asistecia en cada tarjeta
  	 * @return	{void}
  	 */
	function timeArrivalcita() {
		if (vm.assistance.eta) {
			vm.eta = 90 * 60;
		}
		vm.time = (Math.floor(((vm.assistance.appointmentDate * 1000) - vm.getDate) / 1000));
	}

	/** @static
	 * @function
	 * @name trafficLight
	 * @description habilita el semaforo segun regla de tiempo para cada color verde contador negro, amarillo contador negro, rojo contador negro, rojo contador rojo.
	 * @return	{void}
	 */
	function trafficLight() {
		var hours = Math.floor(Math.abs(vm.time) / 3600);
		var minutes = Math.floor((Math.abs(vm.time) % 3600) / 60);
		var seconds = Math.floor(Math.abs(vm.time) % 60);
		vm.m = Math.floor(vm.time / 60);
		vm.s = Math.floor(Math.abs(vm.time) % 60);
		if (vm.time > 0) {
			vm.elapsed = ' ' + format(hours) + ':' + format(minutes) + ':' + format(seconds);
			vm.estilo = null;
		} else {
			vm.elapsed = '-' + format(hours) + ':' + format(minutes) + ':' + format(seconds);
			vm.estilo = {color: '#FF0000'};
			if (vm.failure) {
				$localForage.getItem('dataUser').then(function(data) {
					AssistanceService.failure(data.id, vm.assistance).then(function(data) {
						if (data.isSuccess) {
							vm.failure = false;
						} else {
							vm.failure = false;
						}
					});
				});
			}
		}

		var m = Math.floor(vm.m);
		if (!vm.assistance.appointment || m < 90 || vm.assistance.appointmentDate === -2208965004) {
			if (m === 89 && vm.s === 59 && vm.assistance.appointment && vm.assistance.appointmentDate !== -2208965004) {
				toastr.info('Recordatorio faltan 90 minutos para la cita programada :: Expediente: ' + vm.assistance.folio);
				audio.play();
			}
			// Cambio de color semaforo

			var a = ((vm.eta / 60) - ((vm.eta / 60) * (2 / 3)));
			var b = Math.floor((vm.eta / 60) - ((vm.eta / 60) * (8 / 9)));

			if (m > a && vm.getArrival !== 3) {
				vm.class = 'stage-light stage-ontime';
				vm.assistance.status.arrival = 3;
			}
			if (m < a && m >= b && vm.getArrival !== 2) {
				vm.class = 'stage-light stage-delay';
				vm.assistance.status.arrival = 2;
			}
			if (m < b && vm.getArrival !== 1 && vm.estilo === null) {
				vm.class = 'stage-light stage-vdelay';
				vm.assistance.status.arrival = 1;
			}
			if (m < b && vm.getArrival !== 0 && vm.estilo !== null) {
				vm.class = 'stage-light stage-vdelay';
				vm.assistance.status.arrival = 0;
			}
		} else if (vm.assistance.status.arrival !== 6 || vm.assistance.status.arrival < 4) {
			vm.class = 'stage-light stage-appointment';
			vm.assistance.status.arrival = 6;
		}
		vm.assistance.status.elapsed = vm.elapsed;
		vm.time--;
	}

	/** @static
	 * @function
	 * @name format
	 * @description Formato de numero para contador en vista web, devuelve numero en formato correcto para mostrar
	 * @param      {string}  valor - numero de segundos, minutos y hora
	 * @return	{string}	valor - devuelve formato correcto
	 */
	function format(valor) {
		while ((valor.toString()).length < 2) {
			valor = '0' + valor;
		}
		return valor;
	}
	// run!!
	$timeout(timer, 1000);
}

angular
.module('app')
.component('wrapperStage', {
	templateUrl: 'app/components/wrapperStage/wrapperStage.html',
	controller: wrapperStageController,
	controllerAs: 'ctrl',
	bindings: {
		assistance : '=',
		showDetail : '=',
		car : '<',
		origin : '<',
		destination : '<',
		serviceDetails : '<',
		getDate : '<',
		getArrival : '=',
		showDestiny : '=',
		flagDetailChatBot : '=',
		elapsed : '='
	}
});
