/**
  * @namespace ChatBot
  * @desc Servicios para chatbot.
  * @memberOf Factories
  * @param {Object} $http Peticiones
  * @return {Object} service
  */
function ChatBot($http) {

/**
	 * Funciones disponibles del servicio
	 * @memberof Factories.CatalogService
	 * @property {function} sendResponseCrane - Envia informacion de monitoreo de grua activa.
	 */
	var service = {
		sendResponseChatBot : sendResponseChatBot,
		getCatalogChatBot : getCatalogChatBot
	};
	/* Regreso de definición se servicio */
	return service;

	/**
	* @static
	* @function getCustomerComplete
	* @desc Procesa respuesta de servicio.
	* @memberof Factories.CraneService
	* @param      {Object}  data  	Response de servicio.
	* @return     {Object}  data  Objeto data contenido en el response
	*/
	function getCustomerComplete(data) {
		return data.data;
	}
	/**
	* @function sendResponseLocation
	* @desc Enva informacion de timepo restante
	* @memberof Factories.ChatBot
	* @param      {Object}  assistance  	informacion de asistencia
	* @param      {Object}  provider  	Informacion de proveedor
	* @param      {Object}  service  	Informacion de tiempo estimado de arribo
	* @return     {Object}  response   respuesta de la invocación de servicio
	*/
	function sendResponseChatBot(assistance, provider, service) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/sendMonitoring', service)
		.then(getCustomerComplete);
	}

	/**
	 * @function getCatalogChatBot
	 * @desc Obtiene catalogo con motivos de rechazo.
	 * @memberof Factories.CatalogService
	 * @param      {Object}  country  	parametro de pais
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function getCatalogChatBot(country) {
		return $http.get('api/v1/catalogchatBot?country=' + country).then(getCustomerComplete);
	}
}

angular
  .module('app')
  .service('ChatBot', ChatBot);

