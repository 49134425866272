importScripts('https://www.gstatic.com/firebasejs/7.3.0/firebase-app.js');
importScripts('https://www.gstatic.com/firebasejs/7.3.0/firebase-messaging.js');

var firebaseConfig = {
	apiKey: 'AIzaSyAS2tYdveigbNuUO0nzzUqSTkSc0K2Ct6U',
	authDomain: 'app-yaab-multipais-prod.firebaseapp.com',
	databaseURL: 'https://app-yaab-multipais-prod.firebaseio.com',
	projectId: 'app-yaab-multipais-prod',
	storageBucket: 'app-yaab-multipais-prod.appspot.com',
	messagingSenderId: '433488837009',
	appId: '1:433488837009:web:8eefa424b5fc1271e68230',
	measurementId: 'G-X5QH1CW8TK'
};
 firebase.initializeApp(firebaseConfig);
 var messaging = firebase.messaging();


 messaging.setBackgroundMessageHandler(function(payload) {
	console.log('[firebase-messaging-sw.js] Received background message =', payload);
	var dataBody = payload.data.body;
	var jsonVar = JSON.parse(dataBody);
	var listener = new BroadcastChannel('fsw');
	listener.postMessage(payload);

	var notificationOption = {
		body:jsonVar.body,
		icon:jsonVar.icon
	};

	self.registration.showNotification(jsonVar.title, notificationOption);

	self.addEventListener('notificationclick', function(e) {
	console.log('Background noti');
	console.log(e.notification);
		e.notification.close();
  });
 });
