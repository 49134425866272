/**
 * Componente Muestra el detalle de cambio de llanta.
 * @module carDetailChangeTireController
 * @param {Object} ValueToClassHelper  Ayuda para claces de elemntos html.
 * @return {Object} ctrl controlador.
 */
function carDetailChangeTireController(ValueToClassHelper) {
	var vm = this;

	/* Define funciones */
	vm.booleanReasigne = booleanReasigne;

	/* Implementacion */
	/**
	 * @static
	 * @function
	 * @name booleanReasigne
	 * @desc    Pasa un objeto para ser procesado y asignar las clases de los iconos correspondientes.
	 * @param {Object} object		Objeto de json de una asistencia.
	 * @return {Object}	Objeto con clases para iconos correspondientes.
	 */
	function booleanReasigne(object) {
		return ValueToClassHelper.booleanReasigne(object);
	}
}

angular
	.module('app')
	.component('carDetailChangeTire', {
		templateUrl: 'app/components/changeTire/carDetailChangeTire/carDetailChangeTire.html',
		controller: carDetailChangeTireController,
		controllerAs : 'ctrl',
		bindings : {
			detail : '<'
		}
	});

