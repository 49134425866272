/**
  * @namespace ChannelManagerService
  * @desc Comunicación de notificaciones.
  * @param {Object} EnvironmentConfig - Variables de configuración de ambiente.
  * @param {string} $q - Implementacion de promesa.
  * @param {string} $timeout - wrapper de funcion de angular.
  * @param {string} $cookies - Manipulacion de cookies.
  * @memberOf Factories
  * @return {Object} service
  */
function ChannelManagerService(EnvironmentConfig, $q, $timeout, $cookies) {

	/**
    * Es el objeto que contendra el canal de comunicación.
    * @memberof Factories.ChannelManagerService
    */
	var _ServerSentEventSource = null;
	/**
	 * Fuciones disponibles del servicio
	 * @memberof Factories.ChannelManagerService
	 * @property {function} getManager - Crea un nuevo objeto EventSourcePolyfill el cual contine el canal para notificasiones.
	 * @property {function} closeChannel - Cierra la conexion del canal para un usuario.
	 */
	var service = {
		getManager : getManager,
		closeChannel : closeChannel
	};
	return service;

	/* Implementacion */
	/**
	  * @function getManager
    * @desc Crea un nuevo objeto EventSourcePolyfill el cual contine el canal para notificasiones
    * @memberof Factories.ChannelManagerService
	  * @param {string} provider - El id de usuario
	  * @param {string} exp - Es un timestamp
		* @returns {Promise} promise - Nuevo Objeto EventSourcePolyfill
    */
	function getManager(provider, exp) {
		var deferred = $q.defer();
		$timeout(function() {
			deferred.notify('Conectando ' + provider + '.');

			if (_ServerSentEventSource === null) {
				if (Modernizr.eventsource) {
					_ServerSentEventSource = new EventSourcePolyfill(EnvironmentConfig.urlChannel + 'api/v1/providers/' + provider + '/event/suscribe/' + exp, {
						headers: {
							Authorization : $cookies.get('myAccess'),
							'x-api-key' : EnvironmentConfig.apiKey,
							Accept : 'text/event-stream'
						}
					});
					// _ServerSentEventSource = new EventSource(EnvironmentConfig.urlChannel + 'api/v1/providers/' + provider + '/event/suscribe/' + exp);
					deferred.resolve(_ServerSentEventSource);
				} else {
					_ServerSentEventSource = new EventSourcePolyfill(EnvironmentConfig.urlChannel + 'api/v1/providers/' + provider + '/event/suscribe/' + exp, {
						headers: {
							Authorization : $cookies.get('myAccess'),
							'x-api-key' : EnvironmentConfig.apiKey,
							Accept : 'text/event-stream'
						}
					});
					// _ServerSentEventSource = new EventSource(EnvironmentConfig.urlChannel + 'api/v1/providers/' + provider + '/event/suscribe/' + exp);
					deferred.resolve(_ServerSentEventSource);
				}
			} else {
				_ServerSentEventSource = new EventSourcePolyfill(EnvironmentConfig.urlChannel + 'api/v1/providers/' + provider + '/event/suscribe/' + exp, {
						headers: {
							Authorization : $cookies.get('myAccess'),
							'x-api-key' : EnvironmentConfig.apiKey,
							Accept : 'text/event-stream'
						}
					});
				deferred.resolve(_ServerSentEventSource);
			}
		}, 1000);

		return deferred.promise;
	}
	/**
	  * @function closeChannel
    * @desc Cierra la conexion del canal para un usuario.
    * @memberof Factories.ChannelManagerService
	  * @return {void}
    */
	function closeChannel() {
		_ServerSentEventSource.close();
	}

}// End service

angular
.module('app')
.service('ChannelManagerService', ChannelManagerService);

