/**
 * Componente costos de suministro de gasolina.
 * @module supplyFuelCostController
 * @return  {Object} ctrl
 */
function supplyFuelCostController() {
var vm = this;
	/* Define variables */


	/* Define funciones */
	vm.CostCaptureC = CostCaptureC;
	vm.CostCaptureNC = CostCaptureNC;


	/* Implementacion */
	/** @static
	 * @function
	 * @name CostCaptureC
	 * @description Contruye objeto de costos capturado Carretero
	 * @param      {String}  service - id de asistencia
	 * @param      {String}  beginningtrip - costo por inicio de viaje
	 * @param      {String}  kilometerstraveledCost - costo	por kilometros recorridos
	 * @param      {String}  kilometerstraveled - kilometros recorridos
	 * @param      {String}  waittime - costo	por tiempo de espera
	 * @param      {String}  liquids - costo por liquidos
	 * @param      {String}  country - pais por proveedor
	 * @return {void}
	 */
	function CostCaptureC(service, beginningtrip, kilometerstraveledCost, kilometerstraveled, waittime, liquids, country) {

        var res;

        if (country === 'CO') {
             res = {
                service: service,
                beginning_trip: beginningtrip,
                kilometers_traveled: kilometerstraveled,
                kilometers_traveledCost: kilometerstraveledCost,
                liquids: liquids
            };
        } else {
             res = {
                service: service,
                beginning_trip: beginningtrip,
                kilometers_traveled: kilometerstraveled,
                kilometers_traveledCost: kilometerstraveledCost,
                wait_time: waittime,
                liquids: liquids
            };
        }
        vm.getTimecos({
            service: res
        });
    }

	/** @static
	 * @function
	 * @name CostCaptureC
	 * @description Contruye objeto de costos capturado No Carretero
	 * @param      {String}  service - id de asistencia
	 * @param      {String}  beginningtrip - costo por inicio de viaje
	 * @param      {String}  waittime - costo por tiempo de espera
	 * @param      {String}  liquids - costo por liquidos
	 * @param      {String}  country - pais por proveedor
	 * @return  {void}
	 */
	function CostCaptureNC(service, beginningtrip, waittime, liquids, country) {

        var res;

        if (country === 'CO') {

            res = {
                service: service,
                beginning_trip: beginningtrip,
                liquids: liquids
            };
        } else {

            res = {
                service: service,
                beginning_trip: beginningtrip,
                wait_time: waittime,
                liquids: liquids
            };

        }


        vm.getTimecos({
            service: res
        });
    }
}

angular
.module('app')
.component('supplyFuelCost', {
	templateUrl: 'app/components/wrapperCost/supplyFuelCost/supplyFuelCost.html',
	controller: supplyFuelCostController,
	controllerAs : 'ctrl',
	bindings : {
		data : '<',
		valCosts : '<',
		getTimecos : '&',
		service : '<',
		monitor : '<',
		status : '='
	}
});

