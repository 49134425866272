/**
 * Componente de vista para mostar la lista de gruas asignadas a una asistencia.
 * @module wrapperCraneController
 * @return {Object} ctrl
 */
function wrapperCraneController() {
}

angular
.module('app')
.component('wrapperCrane', {
	templateUrl: 'app/components/wrapperCrane/wrapperCrane.html',
	controller: wrapperCraneController,
	controllerAs : 'ctrl',
	bindings : {
		craneList : '<'
	}
});
