/**
 * Componente bandeja.
 * @module tray
 * @param      {Object}    reporteService         http servivce reporte
 * @param      {Object}    CatalogService         Servicio encargado de obtención de catalogos
 * @param      {Object}    $localForage           The local forage
 * @param      {Object}    ChannelManagerService  Servicio encargado de creación de canal para notificaciones
 * @param      {Object}    $log                   The log
 * @param      {Object}    $rootScope             The root scope
 * @param      {Object}  	 $interval              The interval
 * @param      {Object}    $sce                   The sce
 * @param      {Object}    $location              The location
 * @param      {Object} 	 jwtHelper 							The jwtHelper
 * @param 		 {Object}		 $cookies 			      	Libreria para el uso de kookies.
 * @param      {Object} 	 CraneService 	      	Servicio para acciones de grúa.
 * @param      {Object} 	 AssistanceService 	  	Servicio asistencias.
 * @param      {Object} 	 OnHoldService 		      Servicio asistencias canceladas y proveedor concluido.
 * @param      {Object} 	 $document              Modulo para manejo de html
 * @param      {Object} 	 toastr                 Muestra la notificación
 * @param      {Object} 	 toastrConfig          	Configura el Toastr
 * @return  	 {Object} 	 Ctrl
 */
function trayController(reporteService, CatalogService, $localForage, ChannelManagerService, $log, $rootScope, $interval, $sce, $location, jwtHelper, $cookies, CraneService, AssistanceService, OnHoldService, $document, toastr, toastrConfig) {

	var vm = this;
	var providerId	=	'';
	var infoToken =	null;
	var listener = new BroadcastChannel('fsw');
	toastrConfig.positionClass = 'toast-top-center';
	var audio = new Audio('../bower_components/audio/notification1.mp3');
	/* Define variables funcionalidad de menu vista */
	vm.assistanceinprogress = [];
	vm.sizeTake = 'col-md-9';
	vm.sizeIncoming = 'col-md-3';
	vm.sizeCardIncoming = 'col-md-12';
	vm.bIncoming = true;
	vm.bTake = true;
	vm.readyState = false; // Monitoreo de conexion con el canal de notificaciones.
	vm.country = '';

	/* Define variables funcionalidad de banner */
	vm.bannerData = '';
	vm.timeMessage = 0;
	vm.dataonhold = false;
	vm.assistances = [];
	vm.CurrenDate = '';
	/**
	 * Tipo de mensaje : para ingreso de asistencias nuevas, se envian a bandeja de en proceso.<br>
	 * data:{id:"238", folio:"333806", relevant:false, takenDate:"345353", eta:null, appointment:false, appointmentDate:"4535345345", status:{}, service:{}, subservice:{}, typeAssignment:{}, agreement:{}, user:{}, car:{}, origin:{}, destination:{}, serviceDetails:{}, tracking:{}, craneInformation:{}, assistanceMonitoringData:{} }
	 * @var {(null|Object)} newAssistance
	 */
	vm.newAssistance = null;
	/**
	 * Tipo de mensaje : para notificar degradación de asistencias.<br>
	 * data : {assistance_id:'234', typeAssignment:{} }
	 * @var {(null|Object)} relegateAssistance
	 */
	vm.relegateAssistance = null;
	/**
	 * Tipo de mensaje : para ingreso de asistencias tomadas, se envian a bandeja de tomados.<br>
	 * data:{id:"238", folio:"333806", relevant:false, takenDate:"345353", eta:null, appointment:false, appointmentDate:"4535345345", status:{}, service:{}, subservice:{}, typeAssignment:{}, agreement:{}, user:{}, car:{}, origin:{}, destination:{}, serviceDetails:{}, tracking:{}, craneInformation:{}, assistanceMonitoringData:{} }
	 * @var {(null|Object)} assistanceAssignmented
	 */
	vm.assistanceAssignmented = null;
	/**
	 * Tipo de mensaje : para modificaciones en datos de seguimiento, hora llegada, contacto, etc...<br>
	 * data: {assistance_id: '123', assistanceMonitoringData:{}, tracking:{} }
	 * @var {(null|Object)} monitoringData
	 */
	vm.monitoringData = null;
	/**
	 * Tipo de mensaje : para eliminar asistencia entrante o en proceso<br>
	 * data: {assistance_id: '123'}
	 * @var {(null|Object)} deleteAssistance
	 */
	vm.deleteAssistance = null;
	vm.deleteChatBot = null;
	vm.etaUpdate = null;
	vm.isTake = null;
	/**
	 * Tipo de mensaje : para reasignación de grúa<br>
	 * data: {assistance_id: '123', craneInformation: {}, tracking:{} }
	 * @var {(null|Object)} craneReassignment
	 */
	vm.craneReassignment = null;
	/**
	 * Tipo de mensaje : para todos los cambios en datos de expedeinte que se envien de SISE<br>
	 * data: {assistance_id: '123', assistance: {id:"238", folio:"333806", relevant:false, takenDate:"345353", eta:null, appointment:false, appointmentDate:"4535345345", status:{}, service:{}, subservice:{}, typeAssignment:{}, agreement:{}, user:{}, car:{}, origin:{}, destination:{}, serviceDetails:{}, tracking:{}, craneInformation:{}, assistanceMonitoringData:{} }
	 * @var {(null|Object)} updateAssistance
	 */
	vm.updateAssistance = null;
	vm.updateAssistanceTake = null;
	vm.updateAssistanceIncoming = null;
	/**
	 * Tipo de mensaje : para actualización de bitacora, envío de comentarios<br>
	 * data: {assistance_id: '123', tracking:{} }
	 * @var {(null|Object)} updateTracking
	 */
	vm.updateTracking = null;
	/**
	 * Tipo de mensaje : para actualizar el estatus de una asistencia<br>
	 * data: {assistance_id: '123', status:{} }
	 * @var {(null|Object)} updateStatus
	 */
	vm.updateStatus = null;

	vm.connection = null;
	vm.cranes = null;
	vm.assistanceOnHold = [];
	vm.assistanceNoTaken = [];
	vm.loadingData = true;
	vm.vehicleInfo = null;
	vm.chatBotQuestion = null;
	vm.flagDetailChatBot = null;
	// Reporte
	vm.start = '';
	vm.end = '';
	vm.email = '';

	/* Define funciones de controlador */
	vm.allServices = allServices;
	vm.incomingServices = incomingServices;
	vm.takeServices = takeServices;
	vm.bannerMessages = bannerMessages;
	vm.getCraneList = getCraneList;
	vm.confirmNotification = confirmNotification;
	vm.getAssistanceOnHold = getAssistanceOnHold;
	vm.getAssistanceNoTaken = getAssistanceNoTaken;
	// vm.loading = loading;
	vm.restart = restart;
	vm.getCurrentDate = getCurrentDate;
	vm.firebaseIntegration = firebaseIntegration;
	// reporte
	vm.fnEnviar = fnEnviar;


	/** @static
		* @function
		* @name loading
		* @description Muestra loading en carga de asistencias concluidas y carga de gruas.
	 	* @return {void}
		*/
	/* function loading() {
		vm.loadingData = true;
		var idd = $interval(function() {
			try {
				if (($document[0].getElementById('myTabContent').clientHeight > 30) || vm.dataonhold) {
					vm.loadingData = false;
					vm.dataonhold = false;
					$interval.cancel(idd);
				}
			} catch (error) {
				vm.loadingData = false;
				vm.dataonhold = false;
				$interval.cancel(idd);
			}
		}, 1000);
	} */

	/** @static
  	 * @function
  	 * @name getCurrentDate
  	 * @description Obtiene la hora actual del servidor de api, con timezone cero y formato timestamp
  	 * @return      {timestamp}  CurrenDate   timestamp timezone cero
  	 */
	function getCurrentDate() {
		CatalogService.getCurrentDate().then(function(data) {
			// vm.CurrenDate = data.data.defMessage;
			vm.CurrenDate = data.data.defMessage;
			vm.Date = new Date(data.data.timestamp);
			vm.hours = vm.Date.getHours();
			$log.log("Hora Actual");
			$log.log("hours => "+vm.hours)
		});
  }

	/** @static
  		* @function
  		* @name restarr
  		* @description recarga div de asistencias en bandeja principal.
  		* @return {void}
  		*/
  function restart() {
		try {
			if (vm.assistances) {
				vm.assistances.forEach(function (temp) {
				var t = $document[0].getElementById(temp.folio);
					t.appendChild($document[0].getElementById('grua' + temp.folio));
				});
			}
		} catch (error) {
		}
	}

	/* Inicializacion */
	var previousValue;
	this.$doCheck = function() {
		var currentValue = vm.sse && vm.sse.readyState;
		if (previousValue !== currentValue) {
			if (vm.sse.readyState === 0) {
				vm.readyState = '';
			} else if (vm.sse.readyState === 1) {
				vm.readyState = 'Conectado..  ';
			} else {
				vm.readyState = 'Desconectado..  ';
			}
			previousValue = currentValue;
		}
	};

	bannerMessages();

	listener.onmessage = function(e) {
		$log.log('Got message from service worker', e);
		var jsondatasw = angular.fromJson(e.data.data);
		if (jsondatasw.type === 'newAssistance') {
			vm.newAssistance = angular.fromJson(jsondatasw.body);
			vm.newAssistance.chatbot = false;
			$log.log('Noti background = ' + vm.newAssistance);
			$rootScope.$apply();
			vm.confirmNotification(jsondatasw.body, infoToken.exp);
		}
		if (jsondatasw.type === 'updateStatus') {
			$log.log('updateStatus');
			vm.updateStatus = angular.fromJson(jsondatasw.body);
			$log.log(vm.updateStatus);
			$rootScope.$apply();
		}
  };
	/* Inicializacion de canal y creacion de tipos de mensajes entrantes */
	$localForage.getItem('dataUser').then(function(data) {
		infoToken = jwtHelper.decodeToken($cookies.get('dataAccess'));
		providerId = data.id;
		vm.country = data.country;
		vm.readyState = 'Conectado..  ';
		/** validamos que sea colombia y agregamos el chat  */
		if (vm.country === 'CO') {
			const link = $document[0].createElement('link');
			link.type = 'text/css';
			link.rel = 'stylesheet';
			link.href = 'https://ikeasistenciacol2.ifxcallcenter.com/clientweb/chat-client-v4/css/form.css';
			$document[0].head.appendChild(link);

			const script = $document[0].createElement('script');
			script.type = 'text/javascript';
			script.src = 'scripts/chat.js';
			$document[0].head.appendChild(script);

			const chatScript = $document[0].createElement('script');
			chatScript.src = 'https://ikeasistenciacol2.ifxcallcenter.com/clientweb/chat-client-v4/js/init.js';
			$document[0].head.appendChild(chatScript);

			getCurrentDate();

			$log.log("Hour = "+vm.hour);
		}
		firebaseIntegration();
		/**
		 * Este callback obtiene un canal de comunicación para el usuario.
		 * @callback getManager
		 * @param {string} data.id - El id de usuario
		 * @param {Object} infoToken.exp - El token del usuario
		 * @returns {Object} data - Nuevo Objeto EventSourcePolyfill
		 * @throws {InvalidSubscription} error - No se puede crear el canal o la promesa no se cumple
		 */
		ChannelManagerService.getManager(data.id, infoToken.exp).then(function(data) {
			vm.sse = data;

			vm.sse.addEventListener('appLogout', function (result) {
				$log.log('appLogout');
				var res = angular.fromJson(result);
				$log.log(res);
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
				toastr.info(res.data, 'Cierre de sesión :');
				audio.play();
			});

			vm.sse.addEventListener('availableCrane', function (result) {
				$log.log('availableCrane');
				var respuesta = angular.fromJson(result.data);
				$log.log(respuesta);
				_.find(vm.cranes, function(o) {
					if (o.id === respuesta.data.craneid) {
					/*
						if (respuesta.data.status === false) {
							o.busy = '2';
						} else if (respuesta.data.status === true) {
							o.busy = '0';
						}
						*/
						o.status = respuesta.data.status;
						o.busy = respuesta.data.busy;
					}
				});
				toastr.info(respuesta.Mensaje, 'Actualizacion de disponibilidad de gruas :');
				audio.play();
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('updateStatus', function (result) {
				$log.log('updateStatus');
				vm.updateStatus = angular.fromJson(result.data);
				$log.log(vm.updateStatus);
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('updateTracking', function (result) {
				$log.log('updateTracking');
				vm.updateTracking = angular.fromJson(result.data);
				$log.log(vm.updateTracking);
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('updateAssistance', function (result) {
				$log.log('updateAssistance');
				vm.updateAssistance = angular.fromJson(result.data);
				$log.log(vm.updateAssistance);
				if (vm.updateAssistance.isTaken) {
					$log.log('updateIsTake');
					vm.updateAssistanceTake = vm.updateAssistance;
					$rootScope.$apply();
				} else {
					$log.log('updateIsNotTake');
					vm.updateAssistanceIncoming = vm.updateAssistance;
					$rootScope.$apply();
				}
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('craneReassignment', function (result) {
				$log.log('craneReassignment');
				vm.craneReassignment = angular.fromJson(result.data);
				$log.log(vm.craneReassignment);
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('deleteAssistance', function (result) {
				$log.log('deleteAssistance');
				vm.deleteAssistance = angular.fromJson(result.data);
				$log.log(vm.deleteAssistance);
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('monitoringData', function (result) {
				$log.log('monitoringData');
				vm.monitoringData = angular.fromJson(result.data);
				$log.log(vm.monitoringData);
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('newAssistance', function (result) {
				$log.log('newAssistance');
				vm.newAssistance = angular.fromJson(result.data);
				vm.newAssistance.chatbot = false;
				$log.log(vm.newAssistance);
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('chatBot', function (result) {
					$log.log('chatBot');
					var chat = angular.fromJson(result.data);
					for (var o in vm.assistanceinprogress) {
						if (vm.assistanceinprogress[o].id === chat.id && vm.assistanceinprogress[o].bot === null) {
							vm.chatBotQuestion = chat;
							vm.chatBotQuestion.chatbot = true;
							vm.chatBotQuestion.subservice = angular.fromJson('{"code": "0",  "description":"Monitoreo"}');
							vm.chatBotQuestion.typeAssignment = angular.fromJson('{"code": "0", "description" : "Monitoreo"}');
						}
					}
					$log.log(vm.chatBotQuestion);
					$rootScope.$apply();
					vm.confirmNotification(result, infoToken.exp);
			});
			vm.sse.addEventListener('deletechatBot', function (result) {
					$log.log('deletechatBot');
					vm.deleteChatBot = angular.fromJson(result.data);
					vm.deleteChatBot.chatbot = true;
					$log.log(vm.deleteChatBot);
					$rootScope.$apply();
					vm.confirmNotification(result, infoToken.exp);
			});
			vm.sse.addEventListener('UpdateEta', function (result) {
					$log.log('UpdateEta');
					vm.etaUpdate = angular.fromJson(result.data);
					$log.log(vm.etaUpdate);
					$rootScope.$apply();
					vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('relegateAssistance', function (result) {
				$log.log('relegateAssistance');
				vm.relegateAssistance = angular.fromJson(result.data);
				$log.log(vm.relegateAssistance);
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});

            vm.sse.addEventListener('providerLoosers', function (result) {
				$log.log('providerLoosers');
                var loser = angular.fromJson(result);
                $log.log(loser);
				toastrConfig.timeOut = 0;
                toastrConfig.extendedTimeOut = 0;
                toastr.success(loser.data + '<br><button type="button" class="btn btn-secondary" style="background-color:#D35400;">Aceptar</button>', 'Asignacion subasta :', {
                    allowHtml: true,
                    iconClass: 'toast-orange'
                });
                toastrConfig.timeOut = 5000;
                toastrConfig.extendedTimeOut = 1000;
                $rootScope.$apply();
                vm.confirmNotification(result, infoToken.exp);
			});
            /*
            vm.sse.addEventListener('auctionAssignmented', function (result) {
				$log.log('auctionAssignmented');
				var dataAssistance = angular.fromJson(result.data);
				$log.log(dataAssistance);
				vm.assistanceAssignmented = dataAssistance;
				vm.isTake = {id : dataAssistance.id};
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});
*/
			vm.sse.addEventListener('assistanceAssignmented', function (result) {
				$log.log('assistanceAssignmented');
				var dataAssistance = angular.fromJson(result.data);
				$log.log(dataAssistance);
				vm.assistanceAssignmented = dataAssistance;
				vm.isTake = {id : dataAssistance.id};
				$rootScope.$apply();
				vm.confirmNotification(result, infoToken.exp);
			});

			vm.sse.addEventListener('vehicleInfo', function (result) {
					$log.log('vehicleInfo');
					vm.vehicleinfo = angular.fromJson(result.data);
					$log.log(vm.vehicleinfo);
					/*
					vm.assistanceAssignmented = dataAssistance;
					vm.isTake = {id : dataAssistance.id};
					*/
					$rootScope.$apply();
					vm.confirmNotification(result, infoToken.exp);
			});
		}, function(error) {
			$log.log('No soportado uno *********');
			$log.log(error);
			$location.path('/');
    }).catch(function(error) {
			$log.log('No soportado dos *********');
			$log.log(error);
			$location.path('/');
    });
	});

	/* Implementacion */
	/** @static
	 * @function
	 * @name getAssistanceOnHold
	 * @description Muestra lista de asistencias canceladas y proveedor concluido
	 * @return {void}
	 */
	function getAssistanceOnHold() {
		if (!vm.loadingData) {
			vm.loadingData = true;
			$localForage.getItem('dataUser').then(function(data) {
				OnHoldService.getAssistanceOnHold(data.id, 'onhold').then(function (data) {
					vm.loadingData = false;
					vm.assistanceOnHold = data.data.assistanceInformationList;
					if (vm.assistanceOnHold.length === 0) {
						vm.dataonhold = true;
					}
				});
			});
		}
	}
	/* Implementacion */
	/** @static
	 * @function
	 * @name getAssistanceOnHold
	 * @description Muestra lista de asistencias canceladas y proveedor concluido
	 * @return {void}
	 */
	function getAssistanceNoTaken() {
		if (!vm.loadingData) {
			vm.loadingData = true;
			$localForage.getItem('dataUser').then(function(data) {
				OnHoldService.getAssistanceNoTaken(data.id, 'notTaken').then(function (data) {
					vm.loadingData = false;
					vm.assistanceNoTaken = data.data.assistanceInformationList;
					$log.log(vm.assistanceNoTaken);
					if (vm.assistanceNoTaken.length === 0) {
						vm.dataonhold = true;
					}
				});
			});
		}
	}
	/** @static
		* @function
		* @name getCraneList
		* @description Muestra lista de gruas en el catalogo.
	 	* @return {void}
		*/
	function getCraneList() {
		vm.loadingData = true;
		$localForage.getItem('dataUser').then(function (data) {
			CraneService.getCraneList(data.id).then(function (data) {
				vm.loadingData = false;
				vm.cranes = data.data.craneInformationList;
				if (vm.cranes.length === 0) {
					vm.dataonhold = true;
				}
			});
		});
	}
	/** @static
		* @function
		* @name allServices
		* @description Cambia vista para ver bandeja de asistencias entrantes y en proceso.
	 	* @return {void}
		*/
	function allServices() {
		vm.bTake = true;
		vm.bIncoming = true;
		vm.sizeTake = 'col-md-9';
		vm.sizeIncoming = 'col-md-3';
		vm.sizeCardIncoming = 'col-md-12';
	}
	/** @static
		* @function
		* @name incomingServices
		* @description Cambia vista para ver bandeja de asistencias entrantes.
	  * @return {void}
		*/
	function incomingServices() {
		vm.bTake = false;
		vm.bIncoming = true;
		vm.sizeIncoming = 'col-md-12';
		vm.sizeCardIncoming = 'col-md-3';
	}
	/** @static
		* @function
		* @name takeServices
		* @description Cambia vista para ver bandeja de asistencias en proceso.
	 	* @return {void}
		*/
	function takeServices() {
		vm.bTake = true;
		vm.bIncoming = false;
		vm.sizeTake = 'col-md-12';
	}
	/** @static
		* @function
		* @name bannerMessages
		* @description Obtiene datos de banner y los muestra en la vista con intervalo
		* de tiempo 25 segundos.
	 	* @return {void}
		*/
	function bannerMessages() {
		$localForage.getItem('dataUser')
		.then(function(data) {
			CatalogService.getBanner(data.country).then(function(data) {
				vm.bannerData = data.data;
				if (vm.bannerData) {

					// Primer mensaje
					vm.trustedMessage = $sce.trustAsHtml(vm.bannerData[0].message);

					vm.showMessage = vm.trustedMessage;
					vm.timeMessage = 25000;

					vm.count = 1;

					var message = function() {
						vm.count += 1;
						vm.trustedMessage = $sce.trustAsHtml(vm.bannerData[vm.count].message);
						vm.showMessage = vm.trustedMessage;

						if (vm.count === (vm.bannerData.length - 1)) {
							vm.count = -1;
						}
					};
					$interval(message, vm.timeMessage);
				} else { // no data en banner service
					vm.showMessage = 'Sin mensajes por mostrar';
				}
			});
		});
	}
	/** @static
		* @function
		* @name ConfirmNotification
		* @description Respondera confirmacion de.
		* @param      {Object}  notificacion - datos de notificacion entrante.
		* @param      {String}  suscribe - id de canal de suscripcion.
		* @return {void}
		*/
	function confirmNotification(notificacion, suscribe) {
		$localForage.getItem('dataUser').then(function (data) {
			var res = {
				id: notificacion.lastEventId,
				entity: 'provider',
				entityId: data.id + suscribe,
				messageId: notificacion.lastEventId,
				typeMessage: notificacion.type,
				messageCorrect: true
			};
			AssistanceService.confirmNotification(res);
		});
	}

	/** @static
		* @function
		* @name firebaseIntegration
		* @description notificaciones entrantes por nfirebase
		* @return {void}
		*/
	function firebaseIntegration()	{
		$log.log('init firebase - - ->' + providerId);
		var firebaseConfig = {
			apiKey: 'AIzaSyAS2tYdveigbNuUO0nzzUqSTkSc0K2Ct6U',
			authDomain: 'app-yaab-multipais-prod.firebaseapp.com',
			databaseURL: 'https://app-yaab-multipais-prod.firebaseio.com',
			projectId: 'app-yaab-multipais-prod',
			storageBucket: 'app-yaab-multipais-prod.appspot.com',
			messagingSenderId: '433488837009',
			appId: '1:433488837009:web:8eefa424b5fc1271e68230',
			measurementId: 'G-X5QH1CW8TK'
		};


		firebase.initializeApp(firebaseConfig);
		var messaging	=	firebase.messaging();
		messaging.usePublicVapidKey('BASS7gNJjWyZo85hcrHRImXXos3F1S7vxnp9yIiGQT8PMXNWA4igFblrjU0cQt9Cc9jeTV5mP8ithLDRkdP3bZs');

		messaging.requestPermission().then(function () {
			$log.log('Notification Permission');
			return messaging.getToken();
		}).then(function (token) {
			$log.log('Token  - -- - >  :'	+	token);
			AssistanceService.subscribeTopic(token, providerId).then(function(data) {
				$log.log('Subscription response ====> ' + data);
			});
		}).catch(function (reason) {
			$log.log(reason);
		});

		messaging.onMessage(function(payload)	{
			$log.log(payload);
			$log.log('Firebase Notification ' + payload.data.type);
			var titleN;
			var bodyN;
			var iconN;
			if (payload.data.type === 'newAssistance') {
				vm.newAssistance = angular.fromJson(payload.data.body);
				vm.newAssistance.chatbot = false;
				$log.log(vm.newAssistance);
				$rootScope.$apply();
				vm.confirmNotification(payload.data.body, infoToken.exp);
				titleN = vm.newAssistance.title;
				bodyN = vm.newAssistance.body;
				iconN = vm.newAssistance.icon;
			}
			var notification = new Notification(titleN, {
				icon: iconN,
				body: bodyN
			});

			$log.log(notification);
		});
	}

	/* envia los datos de el formulario para el reporte  */
	vm.fnEnviar = fnEnviar;
		/** @static
  	 * @function
  	 * @name fnEnviar
  	 * @description envia el formulario.
  	 * @param      {string}  start fecha incio reporte.
  	 * @param      {string}  end    fecha fin  reporte.
  	 * @param      {string}  email   correo donde se envia el reporte.
  	 * @return	{Object | undefined}	 Json con datos de del reporte .
  	 */
	function fnEnviar(start, end, email) {

				reporteService.getReport(providerId, start, end, email).then(function (data) {
					if (data.data.isSuccess) {
						toastr.success('Se ha generado y enviado al correo el reporte', 'Mensaje:');
					} else {
						toastr.error('Ha ocurrido un error al generar el reporte y enviar el correo!', 'Error:');
					}
				});
	}
}// End controller

angular
  .module('app')
  .component('tray', {
    templateUrl: 'app/components/tray/tray.html',
    controller: trayController,
    controllerAs: 'ctrl'
  });

