/**
 * Componente de login para portal.
 * @module changePassController
 * @param      {Object}  ChangePassword     cambio de password proveedor
 * @param      {Object}  $location		obtiene ruta
 * @param 		 {Object}	$localForage   obtiene datos de proveedor
 * @param			 {Object} $window	encripta contraseña
 * @param			 {Object} $timeout	retardo de tiempo
 * @param			 {Object} $document	obtiene DOM
 * @return 		 {Object}  ctrl
 */
	function changePassController(ChangePassword, $location, $localForage, $window, $timeout, $document) {
	var vm = this;

	$localForage.getItem('dataUser')
	.then(function(data) {
		vm.dataUser = data;
		vm.user1 = data.userName;
	});

	/* Define variables */
	vm.alertData = {};
	vm.alertExecute = false;
	vm.loading = true;
	/* Define funciones */
	vm.changepass = changepass;
	vm.valida = valida;
	vm.validate = validate;
	vm.mostrar = mostrar;
	vm.logout = logout;

	vm.code = '';
	vm.user1 = '';
	vm.passwordnew = '';
	vm.passwordrep = '';
	vm.val = '';
	vm.correct = false;
	vm.messageErrors = '';
	vm.messageCP = '';
	vm.info = '';


	/** @static
  	 * @function
  	 * @name mostrar
  	 * @description muestra y oculta contraseña.
  	 * @return	{Object | undefined}	 No retorna nada.
  	 */
	function mostrar () {
		var show = $document[0].getElementById('passwordnew');
		var show1 = $document[0].getElementById('passwordrep');
		if (show.type === 'password') {
			show.type = 'text';
			show1.type = 'text';
		} else {
			show.type = 'password';
			show1.type = 'password';
		}

	}

	/**
	 * @static
	 * @function
	 * @name logout
	 * @description  Cierra el canal de comunicación y dirige a login.
	 * @return     {void} void
	 */
	function logout() {
		$localForage.clear().then(function() {
			$location.path('/');
		});
	}

	/** @static
	 * @function
	 * @name valida
	 * @description Envio de usuario y contraseña para cambio.
	 * @param      {string}  type 	tipo de inputs.
	 * @return	{Object | undefined}	 Json con datos de cambio correcto.
	 */
	function valida(type) {
	vm.info = '';
	vm.messageErrors = '';
	const vocales = ['a', 'e', 'i', 'o', 'u', 'A', 'E', 'I', 'O', 'U']; // no permitidas
	var temp = '';
		if (type === 'user1') {
			temp = vm.user1;
			vm.val = 1;
		}
		if (type === 'passnew') {
			temp = vm.passwordnew;
			vm.val = 2;
		}
		if (type === 'passrep') {
			temp = vm.passwordrep;
			vm.val = 3;
		}
		vm.alertExecute1 = false;
		for (var i = 0; i < temp.length; i++) {
			vm.code = temp.charAt(i).charCodeAt(0);
			if (vm.code > 32 && vm.code > 123) {
			vm.messageErrors = 'Caracter no permitido... ' + temp.charAt(i);
			vm.alertData = {alert : 'login-alert', message : vm.messageErrors};
			vm.alertExecute = true;
				if (vm.val === 1) {
					vm.user1 = vm.user1.substr(0, temp.length - 1);
				}
				if (vm.val === 2) {
					vm.passwordnew = vm.passwordnew.substr(0, temp.length - 1);
				}
				if (vm.val === 3) {
					vm.passwordrep = vm.passwordrep.substr(0, temp.length - 1);
				}
			} else {
				if (vm.val === 1) {
					vm.user1 = vm.user1.replace(/ /g, ''); // elimina espacios
				}
				if (vocales.indexOf(temp.charAt(i)) > -1 && (vm.val === 2 || vm.val === 3)) {
					if (vm.val === 2) {
						vm.passwordnew = vm.passwordnew.replace(/ /g, ''); // elimina espacios
						vm.passwordnew = vm.passwordnew.replace(/[aeiou]/ig, ''); // elimina vocales
					}
					if (vm.val === 3) {
						vm.passwordrep = vm.passwordrep.replace(/ /g, ''); // elimina espacios
						vm.passwordrep = vm.passwordrep.replace(/[aeiou]/ig, ''); // elimina vocales
					}
						vm.messageErrors = 'No se permiten vocales... ' + temp.charAt(i);
						vm.alertData = {alert : 'login-alert', message : vm.messageErrors};
						vm.alertExecute = true;
				}
			}
		}
	}

	/** @static
  	 * @function
  	 * @name Changepass
  	 * @description Envio de usuario y contraseña para cambio.
  	 * @param      {string}  user1   Nombre de usuario.
  	 * @param      {string}  passwordnew   Contraseña de usuario nueva.
  	 * @param      {string}  passwordrep   Contraseña de usuario nueva repetida.
  	 * @return	{Object | undefined}	 Json con datos de cambio correcto.
  	 */
  function changepass(user1, passwordnew, passwordrep) {
		vm.messageCP = '';
		vm.correct = false;
		if (passwordnew === passwordrep && user1) {
			validate(passwordnew);
			if (vm.correct) {
				$localForage.getItem('dataUser').then(function(data) {
					vm.loading = false;
					var passn = $window.btoa(passwordnew);
					var passr = $window.btoa(passwordrep);
                    if (data.id) {
                        ChangePassword.changepass(data.id, user1, passn, passr).then(function (data) {
                            vm.loading = true;
                            if (data.data.data.defMessage==="Cambio de Contraseña Exitoso") {
                                vm.messageCP = data.data.data.defMessage;
                                $timeout(function() {
                                    $localForage.clear().then(function() {
                                        $location.path('/');
                                    });
                                }, 2000);
                            } else {
                                vm.loading = true;
                                vm.messageErrors = data.data.data.defMessage;
                                vm.alertData = {alert : 'login-alert', message : vm.messageErrors};
                                vm.alertExecute = true;
                            }
                        });
					} else {
                        vm.loading = true;
                        vm.messageErrors = 'No se puede realizar cambio de contraseña';
                        vm.alertData = {alert : 'login-alert', message : vm.messageErrors};
                        vm.alertExecute = true;
					}
				});
			}
		} else {
			vm.loading = true;
			vm.messageErrors = 'Verificar los datos capturados...';
			vm.alertData = {alert : 'login-alert', message : vm.messageErrors};
			vm.alertExecute = true;
		}
  }

	/** @static
  	 * @function
  	 * @name validate
  	 * @description Envio de usuario y contraseña para cambio.
  	 * @param      {string}  password   validacion de password.
  	 * @return	{Object | undefined}	 Json con datos de cambio correcto.
  	 */
  function validate(password) {
		if (password.length > 7) {
			var mayusculas = false;
			var minusculas = false;
			var numero = false;
			var caracteres = false;
			var consecutivoI = false;
			var consecutivoII = '';
			var consecutivo = false;
			var consecutivoo = '';
			for (var i = 0; i < password.length; i++) {
				vm.code = password.charAt(i).charCodeAt(0);
				if (vm.code > 64 && vm.code < 91) { // MAYUSCULAS
					mayusculas = true;
				}
				if (vm.code > 96 && vm.code < 123) { // minusculas
					minusculas = true;
				}
				if (vm.code > 47 && vm.code < 58) { // numeros
					numero = true;
				}
				if ((vm.code > 32 && vm.code < 48) || (vm.code > 57 && vm.code < 65)) { // caracteres
					caracteres = true;
				}
				if ((password.charAt(i) === password.charAt(i - 1)) && (password.charAt(i) === password.charAt(i - 2))) {
					consecutivoI = true;
					consecutivoII += (' (' + password.charAt(i - 2) + ' ' + password.charAt(i - 1) + ' ' + password.charAt(i) + ') ');
				}

				if (((password.charAt(i).charCodeAt(0) - 2) === (password.charAt(i - 1).charCodeAt(0) - 1)) && ((password.charAt(i).charCodeAt(0) - 2) === password.charAt(i - 2).charCodeAt(0))) {
					consecutivo = true;
					consecutivoo += (' (' + password.charAt(i - 2) + ' ' + password.charAt(i - 1) + ' ' + password.charAt(i) + ') ');
				}
			}
			if (mayusculas && minusculas && numero && caracteres && !consecutivoI && !consecutivo) {
				vm.correct = true;
			} else {
				vm.messageErrors = 'Requiere almenos';
				if (!mayusculas) {
					vm.messageErrors += ', una mayuscula';
				}
				if (!minusculas) {
					vm.messageErrors += ', una minuscula';
				}
				if (!numero) {
					vm.messageErrors += ', un numero';
				}
				if (!caracteres) {
					vm.messageErrors += ', un caracter especial';
				}
				if (consecutivoI) {
					vm.messageErrors += ', maximo 2 caracteres consecutivos iguales' + consecutivoII;
				}
				if (consecutivo) {
					vm.messageErrors += ', maximo 2 caracteres consecutivos ' + consecutivoo;
				}
				vm.info = vm.messageErrors;
			}
		} else {
			vm.messageErrors = 'Longitud minima 8 caracteres... ';
			vm.alertData = {alert : 'login-alert', message : vm.messageErrors};
			vm.alertExecute = true;

		}
  }
}

angular
  .module('app')
  .component('changePass', {
    templateUrl: 'app/components/ChangePass/ChangePass.html',
    controller: changePassController,
    controllerAs : 'ctrl'
  });

