/**
  * @namespace reporteService
  * @desc envia l apeticon del reporte.
  * @memberOf Factories
  * @param {Object} $http Peticiones
  * @return {Object} service
  */
	function reporteService($http) {
/* Define las fuciones accesibles */
	var service = {
		getReport : getReport
	};
	/* Regresa la definicion del servicio */
	return service;

	/**
  	 * @static
  	 * @function postChangeComplete
  	 * @desc Procesa respuesta de servicio.
  	 * @memberof Factories.reporteService
  	 * @param      {Object}  data  	Response de servicio.
  	 * @return     {Object}  data  Objeto data contenido en el response
  	 */
	function postreportComplete(data) {
		return data;
	}
	/**
  	 * @function getReport
  	 * @desc reporte.
  	 * @memberof Factories.Authentication
		 *  @param     {Object}  providerid 	 user.
  	 * @param      {Object}  start  	fecha inicio.
  	 * @param      {Object}  end  	fecha fin .
  	 * @param      {Object}  email 	pcorreo user.
  	 * @return     {Object}  response   Json con datos de actualizacion correta.
  	 */
	function getReport(providerid, start, end, email) {
		return $http.post('api/v1/providers/' + providerid + '/report', {
			startDate  : start,
			endDate : end,
			email : email
		})
		.then(postreportComplete);
	}
}

angular
  .module('app')
  .service('reporteService', reporteService);

