/**
 * Componente de vista muestra datos de cita.
 * @module wrapperAppointmentController
 * @return {Object} ctrl
 */
function wrapperAppointmentController() {
  this.text = 'My brand new component!';
  // FALTA IMPLEMENTACION
}

angular
  .module('app')
  .component('wrapperAppointment', {
    templateUrl: 'app/components/wrapperAppointment/wrapperAppointment.html',
    controller: wrapperAppointmentController
  });

