/**
 * @namespace CraneService
 * @desc Manejo de catalogo y actualización de datos de las gruas.
 * @memberOf Factories
 * @param {Object} $http Peticiones
 * @return {Object} service
 */
function CraneService($http) {
	/**
	 * Funciones disponibles del servicio
	 * @memberof Factories.CraneService
	 * @property {function} getCraneList - Libera la grua.
	 * @property {function} getCraneLocation
	 * @property {function} enableCrane
	 */
	var service = {
		getCraneList: getCraneList,
		getCraneLocation: getCraneLocation,
		enableCrane: enableCrane,
		availableCrane: availableCrane,
		logout: logout,
		releaseCranes: releaseCranes

	};
	/* Regresa la definicion del servicio */
	return service;

	/* Implementacion */
	/**
	 * @static
	 * @function getCustomerComplete
	 * @desc Procesa respuesta de servicio.
	 * @memberof Factories.CraneService
	 * @param      {Object}  data  	Response de servicio.
	 * @return     {Object}  data  Objeto data contenido en el response
	 */
	function getCustomerComplete(data) {
		return data.data;
	}
	/**
	 * @function enableCrane
	 * @desc Libera la grúa.
	 * @memberof Factories.CraneService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  idservice  id de asistencia.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function enableCrane(provider, idservice) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + idservice + '/enable_crane?requestFrom=portal')
			.then(getCustomerComplete);
	}
	/**
	 * @function getCraneList
	 * @desc Obtiene lista de gruas para mostrar en el catalogo con datos actualizados.
	 * @memberof Factories.CraneService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @return     {Object}  response  Lista de gruas y atributos.
	 */
	function getCraneList(provider) {
		return $http.get('api/v1/providers/' + provider + '/craneList')
			.then(getCustomerComplete);
	}
	/**
	 * @function getCraneLocation
	 * @desc Posicion de la grúa.
	 * @memberof Factories.CraneService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  assistance  id de asistencia.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function getCraneLocation(provider, assistance) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + assistance + '/crane_tracking')
			.then(getCustomerComplete);
	}
	/**
	 * @function availableCrane
	 * @desc Cambia de estatus la grua
	 * @memberof Factories.CraneService
	 * @param      {Object} craneid	Id de grua
	 * @param      {Object} estatus 	llamada del servicio
	 * @return     {Object}  response  llamada del servicio.
	 */
	function availableCrane(craneid, estatus) {
		return $http.put('api/v1/cranes/' + craneid + '/Available', {
				busy: estatus
			})
			.then(getCustomerComplete);
	}

	/**
	 * @function logout
	 * @desc Cambia de estatus la grua
	 * @memberof Factories.CraneService
	 * @param      {Object} craneid	Id de grua
	 * @return     {Object}  response  llamada del servicio.
	 */
	function logout(craneid) {
		return $http.put('api/v1/cranes/' + craneid + '/logout')
			.then(getCustomerComplete);
	}

	/**
	 * @function releaseCranes
	 * @desc Liberación de la grúa
	 * @memberof Factories.CraneService
	 * @param      {Object} clCrane	Id de grua en SISE
	 * @param      {Object} country	codigo del pais
	 * @return     {Object}  response  llamada del servicio.
	 */
	function releaseCranes(clCrane, country) {
		return $http.post('api/v1/admin/releaseCranes', {
				gruas: clCrane,
				country: country
			})
			.then(getCustomerComplete);
	}
}

angular
	.module('app')
	.service('CraneService', CraneService);
