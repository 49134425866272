/**
 * Componente bitacora de asistencia.
 * @module wrapperTrackingController
 * @param {Object} AssistanceService  Servicio para el manejo de asistencias.
 * @param {Object} $localForage  Servicio para almacenamiento local.
 * @param {Object} toastr  Servicio para alertas.
 * @param {Object} toastrConfig Servicio para configuracion de alertas.
 * @param {Object} $document obtiene elementos del DOM
 * @param {Object} CatalogService Servicio de Catalogos.
 * @return {Object} ctrl
 */
function wrapperTrackingController(AssistanceService, $localForage, toastr, toastrConfig, $document, CatalogService) {
	var vm = this;

	toastrConfig.positionClass = 'toast-top-center';
	vm.dataComment = {};
	vm.upload = false;
	vm.name = '';
	vm.fd = '';
	vm.idCommentt = '';
	vm.event = '';
	vm.changefile = false;
	vm.filetosend = {};

	vm.postCommentReason = postCommentReason;
	vm.fileChanged = fileChanged;
	vm.validate = validate;
	vm.uploadfile = uploadfile;
	vm.commentList = commentList;
	/** @static
	 * @function
	 * @name postCommentReason
	 * @description Envia datos de comentarios, para guardar en  bitacora
	 * @param   {String}  service  Id de asistencia.
	 * @return {void}
	 */
	function postCommentReason(service) {
		vm.dataComment.idComment = vm.idCommentt;
		$localForage.getItem('dataUser').then(function(data) {
			if (service && data.id && vm.dataComment.idComment && vm.dataComment.txtComment) {
				vm.dataComment.idProvider = data.id;
				vm.dataComment.idService = service;
				AssistanceService.postCommentReason(vm.dataComment).then(function(data) {
					if (data.isSuccess === true) {
						toastr.success(data.data.defMessage, 'Bitácora');
						vm.name = '';
						$document[0].getElementById('up').value = '';
					}
				});
				$('#optComment').html('');
				$('#txtComment' + service).val('');
				vm.dataComment.idComment = '';
				vm.dataComment.txtComment = '';
			} else {
				toastr.error('No se guardó el comentario, intente de nuevo', 'Bitácora');
			}
		});
	}
	/** @static
	 * @function
	 * @name fileChanged
	 * @description Envia datos de comentarios, para guardar en  bitacora
	 * @param   {String}  $event  Id de asistencia.
	 * @param   {String}  service  Id de asistencia.
	 * @return {void}
	 */
	function fileChanged($event, service) {
		vm.event = $event;
		// vm.fd = new FormData();
		vm.service = service;
		for (var i = 0; i < ($event.target.files.length); i++) {
			if (i === 0) {
				vm.name = $event.target.files[i].name;
			} else {
				vm.name += ' ,' + $event.target.files[i].name;
			}
		}
		// vm.name = $event.target.files[0].name;
		// vm.fd.append('file', $event.target.files[0], $event.target.files[0].name);
		vm.filetosend = $event.target.files;
  }
  /** @static
	 * @function
	 * @name validate
	 * @description carga archvio
	 * @param   {String}  comment  datos de comentarios.
	 * @return {void}
	 */
  function validate(comment) {
		vm.name = '';
		var json = angular.fromJson(comment);
		vm.idCommentt = json.id;
		if (json.description === 'Carga de archivos') {
			vm.upload = true;
		} else {
			vm.upload = false;
		}
	}
	/** @static
	 * @function
	 * @name uploadfile
	 * @description carga archvio
	 * @param   {String}  service  Id de asistencia.
	 * @return {void}
	 */
	function uploadfile(service) {
		if (vm.filetosend.length > 0) {
			vm.changefile = true;
			for (var fchange = 0; fchange < vm.filetosend.length; fchange++) {
				send(service, vm.filetosend[fchange], vm.filetosend[fchange].name);
			}
		} else {
			toastr.error('Cargar archivo', 'Archivo enviado : ');
		}
	}
	/** @static
	 * @function
	 * @name send
	 * @description carga archvio
	 * @param   {String}  service  Id de asistencia.
	 * @param		{String}	fileTosendFinal archivo a enviar
	 * @param		{String}	name nombre del archivo
	 * @return {void}
	 */
	function send(service, fileTosendFinal, name) {
		$localForage.getItem('dataUser').then(function(data) {
			// vm.filetosend = $event.target.files[i];
			AssistanceService.upload(data.id, vm.data.folio, fileTosendFinal, service).then(function(data) {
			toastr.success(data.data.defMessage, 'Archivo enviado : ' + name);
				if (vm.dataComment.txtComment) {
					postCommentReason(service);
				} else {
					vm.name = '';
					$document[0].getElementById('up').value = '';
				}
				vm.changefile = false;
			});
			vm.filetosend = {};
		});
	}
		/**
  		* @function commentList
  		* @description Envia datos de comentarios, para guardar en  bitacora
  		* @param {object} country  Id de asistencia.
    	* @param {object} subservice  Id de subservicio.
    	* @param {object} elemento  Id de elemento combo.
    	* @return {void}
    	*/
  function commentList(country, subservice, elemento) {
		if (angular.isUndefined(vm.setComment)) {
			CatalogService.commentList(country, subservice).then(function(data) {
			vm.setComment = data.data.catalogueMotifsList;
			try {
				var select = elemento.target.getElementsByClassName('enabled_select')[0];
				select.disabled = false;
			} catch (e) {
			}
			});
		}
	}
}

angular
	.module('app')
	.directive('ngUploadChange', function() {
		return {
			scope:{
				ngUploadChange : '&'
			},
			link:function($scope, $element) {
			$element.on('change', function(event) {
					$scope.$apply(function() {
						$scope.ngUploadChange({$event : event});
					});
				});
					$scope.$on('$destroy', function() {
					$element.off();
				});
			}
		};
  })
	.component('wrapperTracking', {
		templateUrl: 'app/components/wrapperTracking/wrapperTracking.html',
		controller: wrapperTrackingController,
		controllerAs: 'ctrl',
		bindings : {
			data : '=',
			service : '=',
			setComment : '=',
			trackingEventList: '=',
			subService: '=',
			setCountry: '='
		}
	});
