/**
 * Componente modal cotizacion.
 * @module $document
 * @param      {Object}  $document  Obtiene elementos del DOM.
 * @return     {Object}  Ctrl
 */
function wrapperAuctionController($document) {
var vm = this;

vm.iva = iva;
vm.valida = valida;
vm.costAuction = costAuction;
vm.mostrar = false;
vm.valClass = null;
vm.valError = false;

/** @static
  	 * @function
  	 * @name iva
  	 * @description activa calculo de iva.
  	 * @return	{Object | undefined}	 No retorna nada.
  	 */
	function iva () {
		var show = $document[0].getElementById('check');
		if (show.checked === true) {
			vm.mostrar = true;
		} else {
			vm.mostrar = false;
		}
	}
/** @static
		* @function
		* @name valida
		* @description Obtiene lista de asistencias activas al hacer login.
		* @param {String}  key  valida parametros ingresados en costos.
		* @param {String}  val  valida campo.
	  * @return {void}
		*/
	function valida(key, val) {
		if (angular.isUndefined(key)) {
			vm.valClass = 'has-error';
			vm.valError = val;
    } else {
			vm.valClass = '';
			vm.valError = false;
    }
	}
	/** @static
	 * @function
	 * @name costAuction
	 * @description Valida costos capturados no null, no vacios y mayores a cero.
	 * @param      {String}  service	id de asistenccia
	 * @param      {String}  assistanceId	id de asistenccia
	 * @return {void}
	 */
	function costAuction(service, assistanceId) {
		vm.getCost({service : service, assistanceId : assistanceId});
	}
}

angular
  .module('app')
  .component('wrapperAuction', {
    templateUrl: 'app/components/wrapperAuction/wrapperAuction.html',
    controller: wrapperAuctionController,
    controllerAs : 'ctrl',
		bindings : {
			getAuction : '=',
			getRes : '=',
			getIva : '=',
			getIvac : '=',
			getCommet : '=',
			getCode : '=',
			getData : '=',
			getDif : '=',
			getCost : '&'
		}
  });

