/**
 * Componente de vista para mostar el formulario de costos dependiendo del subservicio y tipo de servicio (CARRETERO / NO CARRETERO).
 * @module genericCraneCostController
 * @param {Object} toastr  Libreria para alertas.
 * @param {Object} toastrConfig  Libreria para configuración de alertas.
 * @param {Object} CatalogService  Servicio de catalogos.
 * @param {Object} AssistanceService   Servicio para acciones sobre asistencias.
 * @param {Object} $localForage  Obtiene informacion de proveeedor guardada en cache.
 * @return {Object} ctrl
 */
function genericCraneCostCarreteroController(toastr, toastrConfig, CatalogService, AssistanceService, $localForage) {

	toastrConfig.positionClass = 'toast-top-center';

	var vm = this;
	/* Define variables */
	vm.data1 = [];
	vm.getDif = 0;
	vm.getCode = 0;
	vm.catalog1 = '';
	vm.total = 0;
	vm.validate = false;
	vm.validate1 = false;
	vm.data2 = [];
	vm.comment = '';
	vm.event = true;
	vm.previosEvent = '';
	vm.agreement = 'true';
	vm.res = {costs: '', comments: '', estimatedTime: 0};
	vm.contactTime = '';
	vm.placeE = '';
	vm.placeEventLocalAuction = '';


	/* Define funciones */
	vm.checkInput = checkInput;
	vm.sum = sum;
	vm.format = format;
	vm.deleteConcept = deleteConcept;
	vm.tranform = tranform;
	vm.init = init;
	vm.head = head;
	vm.saveCost = saveCost;
	vm.placeEvent1 = placeEvent1;
	vm.block = block;
	vm.localauction = localauction;
	vm.placeE1 = placeE1;

	/** @static
  	 * @function
  	 * @name block
  	 * @description Bloquea input cuando se selecciona costo cero.
  	 * @param  {String}  input	nombre de elemento a ingresar
  	 * @return {void}
  	 */
	function block(input) {
		if (input.description === 'COSTO CERO') {
			for (var x in vm.catalog1) {
				if (x !== 'OPTIONAL') {
					var index = vm.catalog1[x].findIndex(function(item) {
						return item.description === 'COSTO CERO';
					});
					vm.data2[x] = vm.catalog1[x][index];
				}
			}
		} else {
			for (var a in vm.catalog1) {
				if (a !== 'OPTIONAL' && vm.data2[a].description === 'COSTO CERO') {
					vm.data2[a] = vm.catalog1[a][0];
				}
			}
		}
	}
	/** @static
	 * @function
	 * @name head
	 * @description Inicializa valores.
	 * @param  {String}  val	nombre de elemento a ingresar
	 * @return {void}
	 */
	function head(val) {
		return val.split(' ')[0].toString();
	}
	/** @static
  	 * @function
  	 * @name placeEvent1
  	 * @description Valida evento.
  	 * @return {void}
  	 */
	function placeEvent1() {
		if (vm.previosEvent === 'NO CARRETERO') {
			toastr.info('No se permite el cambio de lugar de evento a carretero', 'Costos!');
			vm.data.serviceDetails.placeEvent = 'NO CARRETERO';
		} else {
			init();
		}
	}
	/** @static
		 * @function
		 * @name placeE1
		 * @description Valida evento.
		 * @param  {String}  val	nombre de elemento a ingresar
		 * @return {void}
		 */
	function placeE1(val) {
		vm.placeEventLocalAuction = val;
		init();
	}
	/** @static
	 * @function
	 * @name init
	 * @description Inicializa valores.
	 * @return {void}
	 */
	function init() {
		if (vm.data.typeAssignment.code !== '0') {
			if (parseInt(vm.data.status.code, 10) === 1 && vm.data.typeAssignment.code === '5') {
				if (vm.placeE === '') {
					vm.placeE = vm.data.serviceDetails.placeEvent;
					vm.placeEventLocalAuction = (vm.placeE === 'NO CARRETERO' ? '2' : '1');
				}
			}
			if (vm.event && vm.data.typeAssignment.code !== '0') {
				vm.previosEvent = vm.data.serviceDetails.placeEvent;
				vm.event = false;
			}
			if (vm.data.typeAssignment.code !== '0') {
				if (vm.data.status.status_type !== '0') {
					if (angular.isUndefined(vm.agreement)) {
						vm.agreement = 'true';
					}
					$localForage.getItem('dataUser').then(function(data1) {
						if (vm.data.serviceDetails.placeEvent === 'NO CARRETERO') {
							vm.agreement = 'true';
						}

							var map = {
							country: data1.country,
							placeEvent: (parseInt(vm.data.status.code, 10) === 1 && vm.data.typeAssignment.code === '5') ? vm.placeEventLocalAuction : (vm.data.serviceDetails.placeEvent === 'CARRETERO' ? '1' : '2'),
							status: (parseInt(vm.data.status.code, 10) === 1 && vm.data.typeAssignment.code === '5') ? '0' : vm.data.status.status_type,
							agreement_section: '1'
						};
						if (parseInt(vm.data.status.code, 10) > 1 || vm.data.typeAssignment.code === '5') {
							CatalogService.getCatalogCosts(data1.id, vm.data.id, map).then(function (data) {
								vm.catalog1 = [];
								if (data.isSuccess) {
									data1 = data.data.catalogueCostsList;
									vm.data2 = [];
									var costocero = '';
									var data1 = _.filter(data1, function(o) {
										if (o.description === 'COSTO CERO' && costocero === '') {
											costocero = o;
											costocero.quantity = 1;
											costocero.block = true;
											costocero.blockCost = true;
										}
										return o.description !== 'COSTO CERO';
									});
									vm.catalog1 = _.groupBy(data1, function (n) {
										n.quantity = 1;
										n.block = (n.description.indexOf('BANDERAZO') > -1 && vm.data.serviceDetails.placeEvent === 'CARRETERO');
										n.blockCost = false;
										if (n.description === 'COSTO CERO') {
											costocero = n;
										}
										return n.typeConcept === (vm.agreement === 'true' ? '1' : '3') ? n.description.split(' ')[0].toString() : 'OPTIONAL';
									});
									for (var k in vm.catalog1) {
										if (k !== 'OPTIONAL') {
											if (costocero !== '') {
												vm.catalog1[k].push(costocero);
											}
											vm.data2[k] = vm.catalog1[k][0];
										}
									}
								} else {
									toastr.error('No se encontraron conceptos de costos disponibles para esta asistencia', 'Costos!');
								}
							});
						}
					});
				}
			}
		}
	}

	/** @static
	 * @function
	 * @name checkInput
	 * @description Inserta costo en tabla.
	 * @param      {String}  input	nombre de elemento a ingresar
	 * @return {void}
	 */
  function checkInput(input) {
		var val = _.find(vm.data1, {description : input.description});
		if (angular.isUndefined(val)) {
			vm.data1.push(input);
		} else {
			toastr.info('No se pueden duplicar conceptos adicionales', 'Captura de costos adicionales!');
		}
  }
  /** @static
  	 * @function
  	 * @name tranform
  	 * @description transforma select en json
  	 * @param      {String}  input	nombre de elemento a ingresar
  	 * @param      {String}  type	nombre de elemento a ingresar
  	 * @return {void}
  	 */
  function tranform(input, type) {
		var res = angular.fromJson(_.filter(vm.catalog[type], function(o) {
			return o.name === input;
		}));
		vm.data2[type].unitary = res[0].unitary;
		vm.data2[type].quantity = res[0].quantity;
		vm.data2[type].value = res[0].value;
		vm.sum();
  }
	/** @static
  	 * @function
  	 * @name deleteConcept
  	 * @description Elimina concepto.
  	 * @param      {String}  input	nombre de elemento a eliminar
  	 * @return {void}
  	 */
  function deleteConcept(input) {
		var res = _.filter(vm.data1, function(o) {
			return o.description !== input;
		});
		vm.data1 = res;
	}
  /** @static
	 * @function
	 * @name sum
	 * @description Suma total de costos.
	 * @return {void}
	 */
  function sum() {
		vm.total = 0;
		vm.data1.forEach(function (e) {
			vm.total += (e.costo * e.quantity);
		});
		for (var k in vm.data2) {
			if (k) {
				vm.total += (vm.data2[k].costo * vm.data2[k].quantity);
			}
		}
    return format(vm.total);
  }


	/** @static
  	 * @function
  	 * @name localauction
  	 * @description Funcion que guarda los costos.
  	 * @param  {String}  id	id  de la asistencia.
  	 * @return {void}
  	 */
  function localauction(id) {
    var cost = '';
		if (vm.getDif > 0) {
			vm.data1.forEach(function (e) {
					cost += (cost === '' ? '' : '|') + e.concept + '-' + (e.costo * e.quantity);
			});
			if (vm.data2[Object.keys(vm.data2)[0]].description === 'COSTO CERO') {
				cost += (cost === '' ? '' : '|') + vm.data2[Object.keys(vm.data2)[0]].concept + '-' + (vm.data2[Object.keys(vm.data2)[0]].costo * vm.data2[Object.keys(vm.data2)[0]].quantity);
			} else {
				for (var k in vm.data2) {
					if (k) {
						cost += (cost === '' ? '' : '|') + vm.data2[k].concept + '-' + (vm.data2[k].costo * vm.data2[k].quantity);
					}
				}
			}
			vm.res.costs = cost;
			vm.res.comments = vm.comment;
			vm.res.estimatedTime = vm.contactTime;
			if (vm.contactTime) {
				vm.getCost({
					service : vm.res,
					assistanceId : vm.data.id
				});
			} else {
				toastr.info('Todos los campos deben tener costos capturados, igual o mayor a cero', 'Captura de costos!');
				vm.error = true;
			}
    } else {
     toastr.info('Termino el tiempo de subasta', 'Cotización!');
     vm.error = true;
     $('#' + id).modal('hide');
    }

  }
  /** @static
	 * @function
	 * @name saveCost
	 * @description inhabilita elementos confirmados.
	 * @return {void}
	 */
	function saveCost() {
		var cost = '';
		vm.data1.forEach(function (e) {
			cost += (cost === '' ? '' : '|') + e.concept + '-' + (e.costo * e.quantity);
		});
		if (vm.data2[Object.keys(vm.data2)[0]].description === 'COSTO CERO') {
			cost += (cost === '' ? '' : '|') + vm.data2[Object.keys(vm.data2)[0]].concept + '-' + (vm.data2[Object.keys(vm.data2)[0]].costo * vm.data2[Object.keys(vm.data2)[0]].quantity);
		} else {
			for (var k in vm.data2) {
				if (k) {
					cost += (cost === '' ? '' : '|') + vm.data2[k].concept + '-' + (vm.data2[k].costo * vm.data2[k].quantity);
				}
			}
		}
		$localForage.getItem('dataUser').then(function(data1) {
			var map = {
				cost : cost,
				country: data1.country,
				placeEvent: (vm.data.serviceDetails.placeEvent === 'CARRETERO' ? '1' : '2'),
				status: vm.data.status.status_type,
				agreement_section: (vm.agreement === 'true' ? '1' : '3'),
				comment : vm.comment
			};
			vm.getTimesise({
				service : map,
				assistanceId : vm.data.id
			});

			/**
			CheckContactService.saveCost(data1.id, vm.data.id, map).then(function (data) {
				if (data.isSuccess) {
					toastr.success(data.data.defMessage, 'Costos!');
				} else {
					toastr.error(data.data.defMessage, 'Costos!');
				}
			});
			*/
		});
	}
  /** @static
	 * @function
	 * @name format
	 * @description Inserta costo en tabla.
	 * @param      {String}  valor	Formatea decimales
	 * @return {void}
	 */
  function format(valor) {
		return Math.round(valor * 100) / 100;
  }
}

angular
  .module('app')
  .component('genericCraneCostCarretero', {
    templateUrl: 'app/components/wrapperCost/genericCraneCostCarretero/genericCraneCostCarretero.html',
    controller: genericCraneCostCarreteroController,
    controllerAs : 'ctrl',
		bindings : {
			data : '=',
			valCosts : '<',
			getTimecos : '&',
			getTimesise : '&',
			service : '<',
			monitor : '<',
			status : '=',
			loadingData : '=',
			getCode : '=',
			getDif: '=',
			getCost : '&'
	}
  });

