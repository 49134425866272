/**
 * Componente de vista muestra alertas en Login.
 * @module wrapperAlertController
 * @param {Object} $scope  Objeto global de angular.
 * @param {Object} $timeout  Objeto de angular.
 * @return {Object} ctrl
 */
function wrapperAlertController($scope, $timeout) {
	var vm = this;

	/* Define variables */
	vm.show = false;

	/* Define funciones */
	vm.showMessage = showMessage;
	/**
	 * @static
	 * @function
	 * @name showMessage
	 * @description Muestra alerta por 5 segundos.
	 * @return {void} void
	 */
	function showMessage() {
		vm.show = true;
		$timeout(function() {
			vm.show = false;
		}, 5000);
	}

	$scope.$watch(function() {
		return vm.execute;
	}, function() {
		if (vm.execute) {
			vm.showMessage();
			vm.execute = false;
		}
	});
}

angular
	.module('app')
	.component('wrapperAlert', {
		templateUrl: 'app/components/wrapperAlert/wrapperAlert.html',
		controller: wrapperAlertController,
		controllerAs: 'ctrl',
		bindings: {
			data : '<',
			execute : '='
		}
	});

