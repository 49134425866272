/**
 * Componente de vista para mostrar listado de costos.
 * @module listCostsController
 * @return {Object} ctrl
 */
function listCostsController() {
  this.text = 'My brand new component!';
}

angular
  .module('app')
  .component('listCosts', {
    templateUrl: 'app/components/wrapperCost/listCosts/listCosts.html',
    controller: listCostsController,
    controllerAs : 'ctrl',
		bindings : {
			setCosts: '=',
			setEvent: '=',
			setTotal: '<'
		}
  });

