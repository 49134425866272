/**
  * @namespace ApiInterceptor
  * @desc Interceptor de peticiones http.<br>Todas las peticiones pasan por el interceptor, excepto la conexión del canal.
  * @memberOf Factories
  * @param {Module} EnvironmentConfig  Modulo con variables de configuración.
  * @param {Object} $rootScope  Propiedad de angular.
  * @param {Object} $log  Imprime en consola logs.
  * @param {Object} $cookies  Manejo de cookies.
  * @return {void}
  */
function ApiInterceptor(EnvironmentConfig, $rootScope, $log, $cookies) {
	/**
	 * Fuciones disponibles del servicio
	 * @memberof Factories.ApiInterceptor
	 * @property {function} request - Procesa las peticiones enviadas.
	 * @property {function} response - Procesa la respuesta de las peticiones.
	 * @property {function} requestError - ...
	 * @property {function} responseError - ...
	 */
	var timestampMarker = {
		request: function(config) {
			if (!config.headers.Authorization) {
				if ((config.url).indexOf('fileUpload') > 0) {
					config.headers = {Authorization : $cookies.get('dataAccess'), 'x-api-key' : EnvironmentConfig.apiKey};
				} else {
					config.headers = {Authorization : $cookies.get('dataAccess'), 'x-api-key' : EnvironmentConfig.apiKey, 'Content-Type': 'application/json'};
				}
			}
			var url = config.url;
			var dominio;
			if ((EnvironmentConfig.enviroment === 'local' || EnvironmentConfig.enviroment === 'dev') && url.indexOf('oauth/token') === 0) {// Configuracion solo para desarrollo y ruta de oauth
				dominio = EnvironmentConfig.oauth_url;
				config.headers = {'Content-Type' : 'application/json', Authorization : EnvironmentConfig.authorization};
			} else if (url.indexOf('.html') < 0) {
				dominio = EnvironmentConfig.api;
				config.url = dominio + '/' + url;
			}
			if (url.indexOf('googleapis') !== -1) {
				$log.log('URL contains googleapis ' + url);
				config.url = url;
			}
			return config;
		},
		response: function(response) {
			var url = response.config.url;
			$rootScope.messageForModal = '';
			/* if (response.data.dataSet == '' && response.data.isSuccess == true){
				$rootScope.messageForModal = 'No se encontraron datos para su petición.';
				$(function () {
					$('#MessageModal').modal('toggle');
				});
			}else if(response.data.errors != '' && response.data.isSuccess == false){
				angular.forEach(response.data.errors, function(value, key) {
					console.log(value.message);
					$rootScope.messageForModal = value.message + ' '+$rootScope.messageForModal
				});
				$(function () {
					$('#MessageModal').modal('toggle');
				});
			}else if(response.data.errors == '' && response.data.isSuccess == true){
				$rootScope.messageForModal = 'Operación exitosa.';
				$(function () {
					$('#MessageModal').modal('toggle');
				});
			} */
			if (url.indexOf('.html') < 0) {
				if (response.data.isSuccess) {
					/** $log.log(url + ' petición exitosa.'); */
				} else if (url.indexOf('oauth') < 0 && url.indexOf('banner') < 0) {
					$log.error('Response of service, METHOD : ' + response.config.method + ' STATUS : ' + response.status + '  ' + url);
					$log.error(response.data);
				}
			}
			return response;
		},
		requestError: function(config) {
			/* var url = config.url;
			 $rootScope.messageForModal = 'Ocurrio un error, consulte con el Administrador.';
			$(function () {
				$('#MessageModal').modal('toggle');
			});
			usSpinnerService.stop('spinner-1'); */
			return config;
		},
		responseError: function(response) {
			var url = response.config.url;
			$log.log('error al recibir petición ' + url);
			/* $rootScope.messageForModal = 'Ocurrio un error, consulte con el Administrador.';
			$(function () {
				$('#MessageModal').modal('toggle');
			});

			usSpinnerService.stop('spinner-1'); */
			return response;
		}
	};
	return timestampMarker;
}// End service

angular
	.module('app')
	.service('ApiInterceptor', ApiInterceptor)
	.config(function($httpProvider) {
		$httpProvider.interceptors.push('ApiInterceptor');
	});

