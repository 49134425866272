/**
 * Tarjetas para bandeja de asistencias entrantes.
 * @module wrapperNotificationController
 * @param      {Object}  toastr        Libreria para alertas.
 * @param      {Object}  toastrConfig  Objeto para configuración de alertas.
 * @param      {Object}  AssistanceService  Servicio relacionado con asistencias.
 * @param      {Object}  $localForage  Obtiene datos de sesion de proveedor.
 * @param      {Object}  CatalogService Servicio para obtencion de catalogos.
 * @return     {Object}  ctrl
 */
function wrapperNotificationController(toastr, toastrConfig, AssistanceService, $localForage) {
	var vm = this;
	toastrConfig.positionClass = 'toast-top-center';

  /* Definició de variables */
  vm.showDetail = true;
  vm.classShow = 'show';
  vm.classBtn = 'icon-minus-circle';

  /* Definición de funciones */
  vm.detail = detail;
  vm.sendFolio = sendFolio;
  vm.sendR = sendR;
  vm.rejectAuction = rejectAuction;
  vm.take = take;
  vm.country = '';

  $localForage.getItem('dataUser').then(function(data) {
		vm.country = data.country;
  });
  /** @static
    * @function
    * @name detail
    * @description Oculta o muestra el detalle de una asistencia entrante.
    * @return {void} void
    */
  function detail() {
    if (vm.showDetail) {
      vm.showDetail = false;
      vm.classShow = 'hide';
      vm.classBtn = 'icon-plus-circle';

    } else {
      vm.showDetail = true;
      vm.classShow = 'show';
      vm.classBtn = 'icon-minus-circle';
    }
  }
  /** @static
    * @function
    * @name sendFolio
    * @param {string} expedient  Datos de la asistencia
    * @description Envia los datos de una asistencia para ser tomada.
    * @return {void} void
    */
  function sendFolio(expedient) {
		vm.getFolio({expedient: expedient});

  }
   /** @static
		* @function
		* @name take
		* @param {string} expedient  Datos de la asistencia
		* @description Envia los datos de una asistencia para ser tomada.
		* @return {void} void
		*/
  function take(expedient) {
		vm.getDirect({expedient: expedient});
	}
  /** @static
    * @function
    * @name sendR
    * @param {string} id  De asistencia.
    * @description Envia el id de una asistencia para rechazar.
    * @return {void} void
    */
	function sendR(id) {
		vm.getService({id: id});
	}
	/** @static
		* @function
		* @name rejectAuction
		* @param {string} assistanceId  asistencia.
		* @description Envia el id de una asistencia para rechazar.
		* @return {void} void
		*/
	function rejectAuction(assistanceId) {
		$localForage.getItem('dataUser').then(function(data) {
			AssistanceService.rejectAuction(data.id, assistanceId).then(function(data) {
				if (data.isSuccess === true) {
					toastr.success('Se informo rechazo de cotizacion para la s!', 'Rechazo de subasta!');
				} else {
					var msg = data.data.dsError;
					toastr.error(msg, 'Asistencia!');
				}
			});
		});
	}
}// End controller

angular
  .module('app')
  .component('wrapperNotification', {
    templateUrl: 'app/components/wrapperNotification/wrapperNotification.html',
    controller: wrapperNotificationController,
    controllerAs: 'notification',
    bindings: {
      expedient : '<',
      getFolio : '&',
      getService : '&',
      getDif : '=',
      getDirect : '&',
      getData : '='
    }
	});
