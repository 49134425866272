/**
 * Tarjetas para bandeja de asistencias entrantes.
 * @module wrapperNotificationChatBotController
 * @param      {Object}  toastr        Libreria para alertas.
 * @param      {Object}  toastrConfig  Objeto para configuración de alertas.
 * @param      {Object}  AssistanceService  Servicio relacionado con asistencias.
 * @param      {Object}  $localForage  Obtiene datos de sesion de proveedor.
 * @return     {Object}  ctrl
 */
function wrapperNotificationChatBotController() {
	var vm = this;

	/* Definició de variables */
	vm.showDetail = true;
	vm.classShow = 'show';
	vm.classBtn = 'icon-minus-circle';

	/* Definición de funciones */
	vm.detail = detail;
	vm.click = click;

	/** @static
		* @function
		* @name detail
		* @description Oculta o muestra el detalle de una asistencia entrante.
		* @return {void} void
		*/
	function detail() {
		if (vm.showDetail) {
			vm.showDetail = false;
			vm.classShow = 'hide';
			vm.classBtn = 'icon-plus-circle';

		} else {
			vm.showDetail = true;
			vm.classShow = 'show';
			vm.classBtn = 'icon-minus-circle';
		}
	}
	/** @static
		* @function
		* @name click
		* @description Muestra detallla de asistencia seguimiento.
		* @param {object} id  Id de asistencia.
		* @return {void} void
		*/
	function click(id) {
		vm.flagDetailChatBot = id;
	}

}

angular
  .module('app')
  .component('wrapperNotificationChatBot', {
    templateUrl: 'app/components/wrapperNotificationChatBot/wrapperNotificationChatBot.html',
    controller: wrapperNotificationChatBotController,
    controllerAs: 'ctrl',
		bindings: {
			chatBotQuestion : '<',
			flagDetailChatBot : '='
		}
  });

