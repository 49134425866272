/**
  * @namespace Authentication
  * @desc Manejo de tokens para sesion de usuario.
  * @memberOf Factories
  * @param {Nodule} EnvironmentConfig Modulo con variables de configuración.
  * @param {Object} $http Peticiones.
  * @param {Object} $window Encode base64.
  * @return {Object} service
  */
function Authentication(EnvironmentConfig, $http, $window) {
	/* Define las fuciones accesibles */
	var service = {
		validateLogin : validateLogin
	};
	/* Regresa la definicion del servicio */
	return service;

	/* IMPLEMENTACION */
	/**
	 * @function validateLogin
	 * @desc Consulta el usuario en base de SISE y crea un token para la sesion.
	 * @memberof Factories.Authentication
	 * @param      {Object}  user  	nombre de usuario.
	 * @param      {Object}  password 	password de usuario.
	 * @return     {Object}  response   Json con token e info de usuario.
	 */
	function validateLogin(user, password) {
		var result = $window.btoa(password);
		var headers = {'Content-Type' : 'application/json', Authorization : EnvironmentConfig.authorization, 'x-api-key' : EnvironmentConfig.apiKey};
		return $http.post('oauth/token?grant_type=password&username=' + user + '&password=' + result, {}, {headers: headers})
			.then(postLoginComplete);
		/**
	 * @static
	 * @function postLoginComplete
	 * @desc Procesa respuesta de servicio.
	 * @memberof Factories.CraneService
	 * @param      {Object}  data  	Response de servicio.
	 * @return     {Object}  data  Objeto data contenido en el response
	 */
		function postLoginComplete(data) {
			return data;
		}
	}
}// End services

angular
  .module('app')
  .service('AuthenticationService', Authentication);
