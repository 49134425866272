/**
 * Componente bandeja de Servicios concluidos.
 * @module onHoldController
 *
 * @param      {Object}               AssistanceService   Servicio para acciones sobre asistencias.
 * @param      {Object}               ValueToClassHelper  The value to class helper
 * @param      {Object}               CatalogService      Servicio para obtencion de catalogos.
 * @param      {Object}               $localForage        Libreria para almacenamiento local.
 * @param      {Object}               $location           Objeto angular para manejo de rutas.
 * @param      {Object}               $anchorScroll       The anchor scroll
 * @param      {Object}               toastr              Libreria para alertas.
 * @param      {Object}               toastrConfig        Libreria para configuración de alertas.
 * @param      {Object}               CraneService        Servicio para acciones sobre grúas.
 * @param      {Object}               HelpUtilityCtrl     Servicio con funciones de ayuda para controladores.
 * @param      {Object}               EnvironmentConfig   Modulo con variables de configuración.
 * @param      {Object}               $window   Modulo con variables de configuración.
 * @param      {Object}               $timeout   Modulo con variables de configuración.
 * @return     {Object}  Ctrl
 */
function onHoldController(AssistanceService, ValueToClassHelper, CatalogService, $localForage, $location, $anchorScroll, toastr, toastrConfig, CraneService, HelpUtilityCtrl, EnvironmentConfig, $window, $timeout) {

	var vm = this;

	toastrConfig.positionClass = 'toast-top-center';

	/* Define variables */
	vm.assistances = [];
	vm.dataCancellation = {};
	vm.commentList = {};
	vm.cranesRoadmap = [];
	vm.maps = EnvironmentConfig.googleMaps;
	vm.origin = [0, 0];
	vm.destination = [0, 0];
	vm.marker = 'icon-transparent';

	/* Define funciones */
	vm.stringToClass = stringToClass;
	vm.stringEmpty = stringEmpty;
	vm.setMapData = setMapData;
	vm.getCraneLocation = getCraneLocation;
	vm.formatPoint = formatPoint;
	vm.getLastLocation = getLastLocation;
	vm.getTrakingColor = getTrakingColor;
	vm.getCraneReassign = getCraneReassign;
	vm.postReassignmentReasonManual = postReassignmentReasonManual;


	// Prueba para notificaciones
/**
	vm.prueba = prueba;
	function prueba() {
		vm.deleteAssistance = angular.fromJson('{"id": "6c26237d-3a38-4f61-a7e2-a7d1ea2d258f"}');
	}
*/
	/** @static
	 * @function
	 * @name commentList
	 * @description Obtiene la lista de motivos para enviar un comentario.
	 * @return      {Object}    Lista de motivos para enviar un comentario.
	 */
	CatalogService.commentList().then(function(data) {
		vm.commentList = data.data.catalogueMotifsList;
	});
	/**
	 * { Se activa cuando las variables en el componente Tray sufren alguna modificacion
	 * por el canal y los diferentes tipos de mensajes }
	 */
	var updateStatusPrevios = null;
	var monitoringDataPrevious = null;
	var updateTrackingPrevious = null;
	var deletePrevious = null;
	var vehicleinfoPrevious = null;
	console.log('entramos aqui onhold');
	// var audio = new Audio('../bower_components/audio/notification1.mp3');
	this.$doCheck = function() {

		vm.assistances = vm.assistanceOnHold;
		var updateStatusCurrent = vm.updateStatus;
		if (updateStatusPrevios !== updateStatusCurrent) {
			var getNodoCancel = getOneAssistanceOfList(updateStatusCurrent.id);
			if (getNodoCancel) {
				getNodoCancel.status = updateStatusCurrent.status;
				getNodoCancel = HelpUtilityCtrl.statusDescriptionMappingOneAssistance(getNodoCancel);
				toastr.info(updateStatusCurrent.message + ' Expediente: ' + getNodoCancel.folio);
				// audio.play();
			}
			updateStatusPrevios = updateStatusCurrent;
		}

		var monitoringDataCurrent = vm.monitoringData;
		if (monitoringDataPrevious !== monitoringDataCurrent) {
			var getNodoMon = getOneAssistanceOfList(monitoringDataCurrent.id);
			if (getNodoMon) {
				getNodoMon.assistanceMonitoringData = monitoringDataCurrent.Monitoring;
				getNodoMon.tracking = monitoringDataCurrent.Tracking.trackingList;
			}
			monitoringDataPrevious = monitoringDataCurrent;
		}

		var updateTrackingCurrent = vm.updateTracking;
		if (updateTrackingPrevious !== updateTrackingCurrent) {
			var getNodo = getOneAssistanceOfList(updateTrackingCurrent.id);
			if (getNodo) {
				getNodo.tracking = updateTrackingCurrent.trackingList;
			}
			updateTrackingPrevious = updateTrackingCurrent;
		}

		var deleteCurrent = vm.deleteAssistance;
		if (deletePrevious !== deleteCurrent) {
			deletePrevious = deleteCurrent;
			if (getOneAssistanceOfList(deleteCurrent.id)) {
				deleteOneAssistanceList(deleteCurrent.id);
			}
		}

		var vehicleinfo = vm.vehicleInfo;
		if (vehicleinfoPrevious !== vehicleinfo) {
			vehicleinfoPrevious = vehicleinfo;
			vm.assistances.forEach(function(temp) {
				if (temp.id === vm.vehicleInfo.id) {
						temp.vehicle = 1;
				}
			});
		}
	};


	/* Implementacion */
	// NgMap.getMap().then(function() {});
	vm.marker = [];
	vm.polyline = '';
	vm.mapOptions = {
		center: [51.505, -0.09],
		zoom: 10,
		preferCanvas: false
	};

	var L = $window.L;
	var map = new L.map('mapvideowallonhold', vm.mapOptions);
	var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
	// Adding layer to the map
	map.addLayer(layer);
	/** @static
	 * @function
	 * @name getOneAssistanceOfList
	 * @description Ayuda para obtener una asistencia de la lista existente
	 * @param      {string}  idAssistance    id de asistencia
	 * @return	{Object}	nodoAssistance	Datos de una asistencia
	 */
	function getOneAssistanceOfList(idAssistance) {
		var nodoAssistance = _.find(vm.assistances, function(o) {
			return o.id === idAssistance;
		});
		return nodoAssistance;
	}
	/** @static
	 * @function
	 * @name deleteOneAssistanceList
	 * @description Ayuda para eliminar una asistencia de la lista existente
	 * @param      {string}  idAssistance    id de asistencia
	 * @return  {void}  void
	 */
	function deleteOneAssistanceList(idAssistance) {
		var res = _.filter(vm.assistanceOnHold, function(o) {
			return o.id !== idAssistance;
		});
		vm.assistanceOnHold = res;
	}
	/**
	 * @static
	 * @function
	 * @name setMapData
	 * @description  Asigna valores para mostar en mapa y logica para marker de destino.
	 * @param      {Array}   origin       The origin
	 * @param      {Array}   destination  The destination
	 * @param      {string}  showdestiny  The showdestiny
	 * @return  {void} void
	 */
	function setMapData(origin, destination, showdestiny) {
		remove();
		markersmap(origin, 'icon-person-pin', 'Origen');
		if (showdestiny) {
			markersmap(destination, 'icon-pin-drop', 'Destino');
		}
		map.setView(new L.LatLng(origin.latitude, origin.longitude), 17);
		$timeout(function() {
			map.invalidateSize();
		}, 200);

		// vm.origin = [origin.latitude, origin.longitude];
		// vm.destination = [destination.latitude, destination.longitude];
		// vm.marker = showdestiny ? 'icon-pin-drop' : 'icon-transparent';
	}
	/**
	 * @static
	 * @function
	 * @name setMapData
	 * @description  Asigna valores para mostar en mapa y logica para marker de destino.
	 * @param      {Object}   temp       The origin
	 * @param      {String}   icon  The destination
	 * @param      {string}   message  The showdestiny
	 * @return  {void} void
	 */
	function markersmap(temp, icon, message) {
		// icon-towing
		var myIcon = L.divIcon({
				className: '',
				iconSize: null,
				html:'<i class="' + icon + '" style="font-size: 400%; color:black; ">'
				});
		var latlng = [temp.latitude, temp.longitude];
			if (icon === 'icon-towing') {
				latlng = [temp.latitude, temp.longitud];
				message = 'Grua';
			}
		var markerOptions = {
				title : message,
				clickable : false,
				draggable : false,
				icon : myIcon,
				customId : temp
		};
		vm.marker.push(L.marker(latlng, markerOptions).addTo(map));
	}
	/**
	 * @static
	 * @function
	 * @name remove
	 * @description  Remueve markers y lineas.
	 * @return  {void} void
	 */
	function remove() {
		// Remove a marker
		vm.marker.forEach(function(a) {
				map.removeLayer(a);
		});
		vm.marker = [];
		// Remove a marker
		if (vm.polyline !== '') {
				map.removeLayer(vm.polyline);
		}
	}
	/**
  	 * @static
  	 * @function
  	 * @name locationService
  	 * @description  traking de asistencias.
  	 * @param      {Object}   dataCrane       Datos de grua
  	 * @return  {void} void
  	 */
	function locationService(dataCrane) {
			var latlngs = [];
			dataCrane.forEach(function(temp) {
				var i = 0;
				temp.locationCrane = _.filter(temp.locationCrane, function(t) {
					return ((t.latitude !== '0.0' && t.latitude !== '0' && t.latitude !== null) && (t.longitude !== '0.0' && t.longitude !== '0' && t.longitude !== null));
				});
				temp.locationCrane.forEach(function(temp1) {
					if ((temp.locationCrane.length - 1) === i) {
						markersmap(temp1, 'icon-towing', 'Grua');
					} else {
						latlngs.push([temp1.latitude, temp1.longitud]);
					}
					i++;
				});
			});
		if (latlngs.length > 0) {
			// Creating a poly line
			vm.polyline = L.polyline(latlngs, {color: 'blue'});
			// Adding to poly line to map
			vm.polyline.addTo(map);
			// centrar ruta en mapa
			map.fitBounds(vm.polyline.getBounds());
			// markersmap(dataCrane);
		}
		$timeout(function() {
			map.invalidateSize();
		}, 200);
	}

	/**
	 * @static
	 * @function
	 * @name getCraneLocation
	 * @description ???
	 * @param {String} assistanceId  Id de asistencia.
	 * @return  {void} Asigna el resultado a la variable de vm.cranesRoadmap
	 */
	function getCraneLocation(assistanceId) {
		$localForage.getItem('dataUser').then(function(data) {
			CraneService.getCraneLocation(data.id, assistanceId).then(function (data) {
				vm.cranesRoadmap = data.data.craneLocations;
				locationService(vm.cranesRoadmap);
			});
		});
	}


	/** @static
	 * @function
	 * @name postReassignmentReasonManual
	 * @description Envia datos para reasignacion de  grúa cuando una asistencia es asignacion MANUAL,  id de proveedor, id de servicio, razon de reasignacion y id de grua
	 * @param      {string}  craneId    id de grúa
	 * @return {void}
	 */
	function postReassignmentReasonManual(craneId) {
		$localForage.getItem('dataUser').then(function(data) {
			if (craneId && data.id) {
				vm.dataReassignment.idProvider = data.id;
				vm.dataReassignment.craneId = craneId;
				vm.dataReassignment.plaque = vm.selected.plaque;
				vm.dataReassignment.reason = vm.reassingManualReasons.id;
				AssistanceService.postReassignReason(vm.dataReassignment).then(function(data) {
					if (data.isSuccess === true) {
						toastr.success('Asignación a grúa: ' + vm.dataReassignment.plaque + ' correcta', 'Asignación!');
					} else {
						toastr.error('Asignación de grúa fallida', 'Error!');
					}
				});
				vm.selected = null;
				vm.dataReassignment.reason = null;
				$('#manualAssignmentModal').modal('hide');
			}
		});
	}

	/**
	 * @static
	 * @function
	 * @name stringToClass
	 * @desc       Ayuda para mapear icono de falla mecanica o electrica.
	 * @param      {Object}  valueString	Objeto del json de una sistencia.
	 * @return     {Object}		Objeto con clase de icono correspondiente.
	 */
	function stringToClass(valueString) {
		return ValueToClassHelper.stringToClass(valueString);
	}
	/**
	 * @static
	 * @function
	 * @name stringEmpty
	 * @desc     Si el valor recivido es null, 0 o '' y lo convierte a - para verlo en vista de poortal.
	 * @param {Object} valueString	Objeto obtenido de json de una asistencia.
	 * @return {Object}		Nuevo valor - asignado si cumple la condición.
	 */
	function stringEmpty(valueString) {
		return ValueToClassHelper.stringEmpty(valueString);
	}
	/**
	 * @static
	 * @function
	 * @name formatPoint
	 * @description Formatea los puntos de latitud y longitud emitidos por una grúa para imprimirlos en el mapa.
	 * @param {Object} points  Puntos reportados por una grúa.
	 * @return  {Object}   Nuevo arreglo con puntos formateados para imprimirlos en el mapa.
	 */
	function formatPoint(points) {
		var points2 = [];
		points.forEach(function (element) {
			points2.push('[' + element.latitude + ',' + element.longitud + ']');
		});
		return '[ ' + points2.join() + ' ]';
	}
	/**
	 * @static
	 * @function
	 * @name getLastLocation
	 * @description Supongo que busca la primera grua dentro de craneData con el id de grua obtenido del json de uan asistencia.
	 * @param {Object} craneId		Id de una grúa.
	 * @return  {Object}  	cordenada de la primera grúa obtenida con el id, si existe.
	 */
	function getLastLocation(craneId) {
		var found = vm.cranesRoadmap.find(function(craneData) {
			var result = '';
			if (craneData.craneId === craneId) {
				result = craneData;
			} else {
				result = null;
			}
			return result;
		});

		var y = '[]';

		if (found !== null) {
			y = '[ \'' + found.locationCrane[0].latitude + '\' , \'' + found.locationCrane[0].longitud + '\']';
		}
		return y;
	}

	/** @static
	 * @function
	 * @name getCraneReassign
	 * @description Obtiene lista de grúas para la reasignación
	 * @param      {string}  exp     id de expediente
	 * @return {void}
	 */
	function getCraneReassign(exp) {
		$localForage.getItem('dataUser').then(function(data) {
			if (data && exp) {
				AssistanceService.getCraneListReassign(data.id, exp).then(function(data) {
					if (data.data.cranesList.length > 0) {
						vm.craneListReassign = data.data.cranesList;
					} else {
						$('#reassingServiceModal').modal('hide');
						toastr.error('No existen grúas disponibles', 'Asignación!');
					}
				});
			} else {
				toastr.error('Servicio no disponible', 'Error!');
			}
		});
	}
	/**
	 * @static
	 * @function
	 * @name getTrakingColor
	 * @description 		Supongo que asigna el color a la grúa que se pintara en el portal.
	 * @param {Object} trackColor		Supongo que Id de una grua
	 * @return  {Object}		Color asignado que se vera en la vissta del portal.
	 */
	function getTrakingColor(trackColor) {
		var color = '';
		if (vm.cranesRoadmap[0].craneId === trackColor) {
			color = '#1E88E5';
		} else {
			color = '#BBB';
		}
		return color;
	}
}// End controller

/**
 * @namespace detailHelperController
 * @desc Ayuda para acciones en la vista de detalle.
 * @memberOf Factories
 *
 * @param      {Object}  $localForage         Libreria para almacenamiento local.
 * @param      {Object}  CheckContactService  Servicio para acciones sobre estatus de asistencia.
 * @param      {Object}  toastr               Libreria para alertas.
 * @param      {Object}  toastrConfig         Libreria para configuración de alertas.
 * @param      {Object}  AssistanceService    Servicio de asistencias.
 * @param      {Object}  $document    				Documento HTML.
 * @param      {Object}  CatalogService     Servicio para obtencion de catalogos.
 * @return 		 {Object}	 Ctrl
 */
function detailHelperController($localForage, CheckContactService, toastr, toastrConfig, AssistanceService, $document, CatalogService) {
	var vm = this;

	toastrConfig.positionClass = 'toast-top-center';
	/**
	 * Bandera para detalle de asistencias
	 * @memberof Factories.detailHelperController
	 */
	vm.flagDetail = false;
	/**
	 * Bandera para direccion de destino
	 * @memberof Factories.detailHelperController
	 */
	vm.modal = '';
	vm.showdestiny = true;
	vm.map = {where : '', who : '', requestFrom : 'portal'};
	vm.listFiles = [];
	vm.loadingData1 = false;
	vm.totalCosts = 0;
	/* Define funciones de controlador */
	vm.showDetail = showDetail;
	vm.hideDetail = hideDetail;
	vm.TimeEnd = TimeEnd;
	vm.capturar = capturar;
	vm.validar = validar;
	vm.mostrar = false;
	vm.vehicleinfo = vehicleinfo;
	vm.getFiles = getFiles;
	vm.CostSise = CostSise;
	vm.saveCost = saveCost;
	vm.getListCosts = getListCosts;
	/**
	 * @function showDetail
	 * @desc Muestar el detalle de una asistencia
	 * @memberof Factories.detailHelperController
	 * @return {void}
	 */
	function showDetail() {
		vm.flagDetail = true;
	}
	/**
	 * @function hideDetail
	 * @desc Oculta el detalle de una asistencia
	 * @memberof Factories.detailHelperController
	 * @return {void}
	 */
	function hideDetail() {
		vm.flagDetail = false;
	}
	/**
	 * @function CostSise
	 * @desc Recibe datos de costos e inicia modal
	 * @memberof Factories.detailHelperController
	 * @param {object} service  Id de asistencia.
	 * @param {object} assistanceId Id de asistencia.
	 * @return {void}
	 */
	function CostSise(service, assistanceId) {
		vm.modal = service;
		$('#sise' + assistanceId + ' .modal').modal('show');
	}
  /**
	 * @function saveCost
	 * @desc Recibe datos de costos e inicia modal
	 * @memberof Factories.detailHelperController
	 * @param {object} assistanceId  Id de asistencia.
	 * @return {void}
	 */
	function saveCost(assistanceId) {
		vm.loadingData1 = true;
		$localForage.getItem('dataUser').then(function(data) {
			CheckContactService.saveCost(data.id, assistanceId, vm.modal).then(function (data) {
				if (data.isSuccess) {
					toastr.success(data.data.defMessage, 'Costos!');
				} else {
					toastr.error(data.data.defMessage, 'Costos!');
				}
				vm.loadingData1 = false;
			});
		});
	}
	/**
	 * @function TimeEnd
	 * @desc Recibe datos de costos e inicia modal
	 * @memberof Factories.detailHelperController
	 * @param {object} service  Id de asistencia.
	 * @return {void}
	 */
	function TimeEnd(service) {
		vm.modal = service;
		$('#' + vm.modal.service + ' .modal').modal('show');
	}
	/**
	 * @function capturar
	 * @desc Captura datos y cierra modal
	 * @param      {int}  estatusCosto - tipo de costo
	 * @memberof Factories.detailHelperController
	 * @return {void}
	 */
	function capturar(estatusCosto) {
		$localForage.getItem('dataUser').then(function(data) {
			var provider = data.id;
			var tipoCosto = estatusCosto;
			CheckContactService.captureCost(provider, vm.modal, tipoCosto).then(function(data) {
				if (data.isSuccess) {
					toastr.success(data.data.defMessage, 'Costos!');
				} else {
					toastr.success(data.data.message, 'Costos!');
				}
			});
		});
		$('#' + vm.modal.service + '.modal').modal('hide');
	}
	/**
	* @function validar
	@description valida si se eligio alguna opcion del radiobutton
	* @param {Object} valida		Id de asistencia
	* @return  {void}		muestra respuesta de endpoint.
	*/
	function validar(valida) {
	var show = $document[0].getElementById('check' + valida);
	var showtwo = $document[0].getElementById('check1' + valida);
	if (show.checked === true) {
	vm.mostrar = true;
	} else if (showtwo.checked === true) {
	vm.mostrar = true;
	} else {
	vm.mostrar = false;
	}
	}
	/**
     * @static
     * @function
     * @name vehicleinfo
     * @description 		envia donde y quien recibe el vehiculo.
     * @param {Object} assistanceId		Id de asistencia
     * @return  {void}		muestra respuesta de endpoint.
     */
    function vehicleinfo(assistanceId) {
        if (vm.map.where !== '' && vm.map.who !== '') {
            $localForage.getItem('dataUser').then(function(data) {
                AssistanceService.vehicleInfo(data.id, assistanceId, vm.map).then(function(data) {
                    if (data.data.success) {
                        toastr.success(data.data.message, 'Registro!');
                    } else {
                        toastr.error(data.data.message, 'Error');
                    }
                });
            });
        } else {
            toastr.error('Debes ingresar la información requerida!', 'Error');
        }
    }
    /**
     * @static
     * @function
     * @name getFiles
     * @description 		envia donde y quien recibe el vehiculo.
     * @param {Object} assistance		Id de asistencia
     * @return  {Object} Muestra lista archivos.
     */
    function getFiles(assistance) {
			$localForage.getItem('dataUser').then(function(data) {
				CatalogService.getFiles(assistance, data.id).then(function(data) {
					vm.listFiles = data.data.data;
				});
			});
    }
		/**
		 * @static
		 * @function
		 * @name getListCosts
		 * @description 		lista de costos obtenida de sise.
		 * @param {Object} assistanceId		Id de asistencia
		 * @return  {Object} Muestra lista de costos.
		 */
			function getListCosts(assistanceId) {
				$localForage.getItem('dataUser').then(function(data1) {
					var map = {
					country: data1.country,
					placeEvent: '0',
					status: '0',
					agreement_section: '0'
					};
					CatalogService.getCatalogCosts(data1.id, assistanceId, map).then(function (data) {
							vm.listCosts = data.data.catalogueCostsList;
							vm.totalCosts = 0;
							vm.listCosts.forEach(function (element) {
								vm.totalCosts += element.subtotal;
							});
					});
				});
			}
}// End detailHelper

angular
  .module('app')
  .component('onHold', {
    templateUrl: 'app/components/onHold/onHold.html',
    controller: onHoldController,
		controllerAs: 'ctrl',
		bindings: {
			monitoringData : '<',
			updateTracking : '<',
			assistanceOnHold: '<',
			deleteAssistance : '<',
			updateStatus : '<',
			vehicleInfo : '<'
		}
  })
	.controller('detailHelperController', detailHelperController);

