/**
 * @namespace AssistanceService
 * @desc Manejo de asistencias obtención, reasignacion y modificaciones.
 * @memberOf Factories
 * @param {Object} $http Peticiones
 * @return {Object} service
 */
function OnHoldService($http) {

	/**
	 * Funciones disponibles del servicio
	 * @memberof Factories.AssistanceService
	 * @property {function} getCraneListReassign - Obtiene lista de gruas disponibles para una reasignación
	 * @property {function} getIncomingAssistances
	 * @property {function} getTakeAssistances
	 * @property {function} getCraneList
	 * @property {function} postCancellationService
	 * @property {function} postRejectReason
	 * @property {function} postReassignReason
	 * @property {function} takeAssistance
	 * @property {function} postCommentReason
	 */
	var service = {
		getIncomingAssistances : getIncomingAssistances,
		getTakeAssistances : getTakeAssistances,
		getCraneList : getCraneList,
		postCancellationService : postCancellationService,
		postRejectReason : postRejectReason,
		postReassignReason : postReassignReason,
		takeAssistance : takeAssistance,
		getCraneListReassign : getCraneListReassign,
		postCommentReason : postCommentReason,
		confirmNotification : confirmNotification,
		getAssistanceOnHold : getAssistanceOnHold,
		getAssistanceNoTaken : getAssistanceNoTaken
	};

	/* Regresa la definicion del servicio */
	return service;

	/* IMPLEMENTACION */

	/**
	 * @function getAssistanceOnHold
	 * @desc Recupera todas las asistencias con estatus "Proveedor Concluido" y "Cancelación de Proveedor"
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  status  	Estatus de las asistencias a recuperar.
	 * @return     {Object}  response  Lista de asistencias.
	 */
	function getAssistanceOnHold(provider, status) {
		return $http.get('api/v1/providers/' + provider + '/assistancelist?status=' + status + '&number=1')
			.then(getCustomerComplete);
	}

	/**
	 * @function getAssistanceNoTaken
	 * @desc Recupera todas las asistencias disponibles para toma
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  status  	Estatus de las asistencias a recuperar.
	 * @return     {Object}  response  Lista de asistencias.
	 */
	function getAssistanceNoTaken(provider, status) {
		return $http.get('api/v1/providers/' + provider + '/assistancelist?status=' + status + '&number=1')
			.then(getCustomerComplete);
	}

	/**
	 * @static
	 * @function getCustomerComplete
	 * @desc Procesa respuesta de servicio.
	 * @memberof Factories.CraneService
	 * @param      {Object}  data  	Response de servicio.
	 * @return     {Object}  data  Objeto data contenido en el response
	 */
	function getCustomerComplete(data) {
		return data.data;
	}
	/**
	 * @function getIncomingAssistances
	 * @desc Recupera todas las asistencias en proceso.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  status  	Estatus de las asistencias a recuperar.
	 * @return     {Object}  response  Lista de asistencias.
	 */
	function getIncomingAssistances(provider, status) {
		return $http.get('api/v1/providers/' + provider + '/assistancelist?status=' + status + '&number=1')
			.then(getCustomerComplete);
	}
	/**
	 * @function getTakeAssistances
	 * @desc Recupera todas las asistencias entrantes.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  status  	Estatus de las asistencias a recuperar.
	 * @return     {Object}  response  Lista de asistencias.
	 */
	function getTakeAssistances(provider, status) {
		return $http.get('api/v1/providers/' + provider + '/assistancelist?status=' + status + '&number=1')
			.then(getCustomerComplete);
	}
	/**
	 * @function getCraneList
	 * @desc Consulta lista de gruas para una asistencia.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor
	 * @param      {Object}  folio 			Id de asistencia
	 * @return     {Object}  response   respuesta de la invocación de servicio
	 */
	function getCraneList(provider, folio) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + folio + '/craneList')
			.then(getCustomerComplete);
	}
	/**
	 * @function takeAssistance
	 * @desc Toma de asistencia
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  provider  	Id de proveedor
	 * @param      {Object}  assistance Id de asistencia
	 * @param      {Object}  data  			Contiene datos de la grua, para su asignación
	 * @return     {Object}  response   respuesta de la invocación de servicio
	 */
	function takeAssistance(provider, assistance, data) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + assistance + '/take', {
			craneid : data.craneId,
			typeAssignment : data.typeAssignment,
			eta : data.eta
		})
			.then(getCustomerComplete);
	}
	/**
	 * @function postCancellationService
	 * @desc Solicitud de cancelación de asistencia
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  data  			Contiene id de proveedor, id de servicio, razon de cancelacion y id de tipo de asignación
	 * @return     {Object}  response    Respuesta de la invocación de servicio
	 */
	function postCancellationService(data) {
		return $http.post('/api/v1/providers/' + data.idProvider + '/assistances/' + data.idService + '/cancel', {
			motifid : data.reason,
			assignmentid : data.assignmentid
		})
			.then(getCustomerComplete);
	}
	/**
	 * @function postRejectReason
	 * @desc Rechazo de asistencia.
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  data  			Contiene id de proveedor, id de servicio, razon de reasignacion y id de grua
	 * @return     {Object}  response    respuesta de la invocación de servicio
	 */
	function postRejectReason(data) {
		return $http.put('api/v1/providers/' + data.idProvider + '/assistances/' + data.idService + '/reject/' + data.reason)
			.then(getCustomerComplete);
	}
	/**
	 * @function postReassignReason
	 * @desc Reasignacion de grua
	 * @memberof Factories.AssistanceService
	 * @param      {Object}  data  			Contiene id de proveedor, id de servicio, razon de reasignacion y id de grua
	 * @return     {Object}  response    respuesta de la invocación de servicio
	 */
	function postReassignReason(data) {
		return $http.post('api/v1/providers/' + data.idProvider + '/assistances/' + data.idService + '/reassignment/' + data.reason, {
			craneId : data.craneId
		})
			.then(getCustomerComplete);
	}
	/**
	 * @function getCraneListReassign
	 * @desc Obtienen lista de gruas disponibles para una reasignacion, enviando la bandera reassign = true
	 * @memberof Factories.AssistanceService
	 * @param      {string}  providerId  id de proveedor
	 * @param      {string}  exp         id de expediente
	 * @return     {object}  response    respuesta de la invocación de servicio
	 */
	function getCraneListReassign(providerId, exp) {
		return $http.get('api/v1/providers/' + providerId + '/assistances/' + exp + '/craneList?reassign=true')
			.then(getCustomerComplete);
	}
	/**
	 * @function postCommentReason
	 * @desc  Envio de comentarios para una asistencia.
	 * @memberof Factories.AssistanceService
	 * @param {Object}  data  Objeto con datos de proveedor, asistencia y comentario.
	 * @return     {Object}  response  Lista con catalogo.
	 */
	function postCommentReason(data) {
		return $http.put('api/v1/providers/' + data.idProvider + '/assistances/' + data.idService + '/provider_comments?comment=' + data.txtComment + '&code=' + data.idComment)
			.then(getCustomerComplete);
	}
	/**
	 * @function ConfirmNotification
	 * @desc  Confirmacion de notificacion entrante.
	 * @memberof Factories.AssistanceService
	 * @param {Object}  data  Objeto con datos de proveedor, tipo de notificacion entrante.
	 * @return     {void}
	 */
	function confirmNotification(data) {
		return $http.put('api/v1/confirm_notification', data)
			.then(getCustomerComplete);
	}

} // End service

angular
	.module('app')
	.service('OnHoldService', OnHoldService);
