/**
 * Componente de login para portal.
 * @module wrapperLoginController
 * @param      {Object}  $location              The location
 * @param      {Object}  $localForage           Libreria para almacenamiento local.
 * @param      {Object}  $rootScope             The root scope
 * @param      {Object}  $cookies               Libreria para uso de cookies
 * @param      {Object}  AuthenticationService  Servicio con metodos para autenticación
 * @param      {Object}  jwtHelper              Libreria para manejo de token
 * @return 		 {Object}  ctrl
 */
function wrapperLoginController($location, $localForage, $rootScope, $cookies, AuthenticationService, jwtHelper) {

	var vm = this;

	/* Define variables */
	vm.alertData = {};
	vm.alertExecute = false;
	vm.loading = true;

	vm.user = '';
	vm.password = '';

	/* Define funciones */
	vm.validateLogin = validateLogin;

	/* Implementacion */
	/** @static
	 * @function
	 * @name validateLogin
	 * @description Envio de datos de usuario para validacion y recuperar token.
	 * @param      {string}  user   Nombre de usuario.
	 * @param      {string}  password   Contraseña de usuario.
	 * @return	{Object | undefined}	 Json con datos de token.
	 */
	function validateLogin(user, password) {
		vm.loading = false;
		AuthenticationService.validateLogin(user, password)
		.then(function(data) {
			if (data && (data.status === 200)) {
				vm.loading = true;
				if (data.data.UserInfo.id) {
					$cookies.put('dataAccess', data.data.access_token);
					var info = jwtHelper.decodeToken($cookies.get('dataAccess'));
					var dataUser = {id : info.UserInfo.id, userName : info.user_name, message : info.UserInfo.message, changePassword : info.UserInfo.changePassword, name : info.UserInfo.name, country : info.UserInfo.country};
					$localForage.setItem('dataUser', dataUser).then(function() {
						if (info.UserInfo.changePassword) {
							$location.path('/provider/changePass');
						} else {
							$location.path('/provider/bandeja');
						}
						$rootScope.$apply();
					});
				} else {
					vm.loading = true;
					vm.alertData = {alert : 'login-alert', message : 'Verificar usuario'};
					vm.alertExecute = true;
				}
			} else {
				vm.loading = true;
				var messageErrors = '';
				if (data) {
					messageErrors = decodeHTMLEntities(data.data.error_description);
				} else {
					messageErrors = 'Error de conexion';
				}
				vm.alertData = {alert : 'login-alert', message : messageErrors};
				vm.alertExecute = true;
			}
		});
	}
	/** @static
	 * @function
	 * @name decodeHTMLEntities
	 * @description Recibe parametro para decodificar.
	 * @param      {string}  text   texto a decodificar.
	 * @return	{string}	 retorna mensaje codificado.
	 */
	function decodeHTMLEntities(text) {
		var entities = [
				['amp', '&'],
				['ntilde', 'ñ'],
				['bsol', '\''],
				['commat', '@']
		];
		for (var i = 0, max = entities.length; i < max; ++i) {
				text = text.replace('&' + entities[i][0] + ';', entities[i][1]);
		}
		return text;
  }

}// End controller

angular
.module('app')
.component('wrapperLogin', {
	templateUrl: 'app/components/wrapperLogin/wrapperLogin.html',
	controller: wrapperLoginController,
	controllerAs : 'ctrl'
});
