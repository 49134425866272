SERVICE_PATTERN_CHAT_CONFIG = {
  appId: '7995ae7203a14ae08f4d38098630d07d',
  apiUrl: 'https://ikeasistenciacol2.ifxcallcenter.com/clientweb/api/v1',
  tenantUrl: 'ikeasistenciacol2.ifxcallcenter.com',
  chatPath: 'https://ikeasistenciacol2.ifxcallcenter.com/clientweb/chat-client-v4/'

  /*
   // Default customer data, it can be collected on current site's context
   first_name: '',
   last_name: '',
   email: '',
   phone_number: ''
*/
}
