/**
 * Componente vista de preguntas chat bot.
 * @module wrapperMonitoringController
 * @param      {Object}  ChatBot  Servicio para chatbot.
 * @param {Object} toastr  Libreria para alertas.
 * @param {Object} toastrConfig  Libreria para configuración de alertas.
 * @param      {Object}  $localForage  Obtiene datos de sesion de proveedor.
 * @return {Object} ctrl
 */
function wrapperMonitoringController(ChatBot, toastr, toastrConfig, $localForage) {
	toastrConfig.positionClass = 'toast-top-center';
	var vm = this;
	/* Define variables */
	vm.timeContact = true;
	vm.timeExt = '0';
	vm.motiv = 'motivo';
	vm.comment = '';
	vm.comment1 = '';
	vm.name_operator = '';
	// vm.con = [];
	// vm.setComment = '';
	vm.validate = 0;
	// var n = 0;
	// while (n <= 60) {
  //   vm.con.push(angular.fromJson('{ "value" : ' + n + ', "name" : "' + n + ' min"}'));
  //   n += 5;
  // }
  vm.setD = setD;
  vm.saveResponse = saveResponse;
  vm.catalog = catalog;
	/** @static
  	 * @function
  	 * @name setD
  	 * @description Envia parametros para asignacion y reasginacion.
  	 * @return	{void}
  	 */
  function setD() {
		var data = {
			id : vm.questionChatBot.id,
			country : vm.questionChatBot.serviceDetails.country,
			subservice : vm.questionChatBot.subservice.id,
			active : (vm.craneList.length > 0 ? vm.craneList[0].active : false)
		};
		vm.setData({service : data});
  }
	/** @static
	 * @function
	 * @name saveResponse
	 * @description Envia parametros de monitoreo.
	 * @param {Object} question  id de asistencia
	 * @return	{void}
 	*/
  function saveResponse(question) {
		var val = false;
		var data = {};
		data.questionId = question.id;
		data.country = vm.questionChatBot.serviceDetails.country;
		switch (question.id) {
			case '1':
				if (vm.name_operator === '') {
					toastr.info('Ingresar Nombre de Operador ', 'Monitoreo servicio!');
        } else {
					data.nameOperator = vm.name_operator;
					val = true;
        }
				break;
			case '2':
				if (vm.timeContact) {
					data.timeContact = 1;
				} else {
					var motivo = angular.fromJson(vm.motiv);
					data.timeContact = 0;
					data.reason = motivo.id;
					data.dsreason = motivo.description;
					data.timeAditional = vm.timeExt;
					data.comment = vm.comment;
				}
				val = true;
				break;
			case '3':
				data.comment = vm.comment;
				val = true;
				break;
			default:
				break;
		}
		if (val) {
			$localForage.getItem('dataUser').then(function(dd) {
				ChatBot.sendResponseChatBot(vm.questionChatBot.id, dd.id, data).then(function(data1) {
					if (data1.isSuccess) {
						toastr.success(data1.data.defMessage, 'Monitoreo');
						vm.showDetail = false;
					} else {
						toastr.error(data1.data.defMessage, 'Monitoreo');
					}
				});
			});
		} else {
			toastr.error('Validar informacion capturada', 'Monitoreo');
		}
  }
	/** @static
    	 * @function
    	 * @name catalog
    	 * @description Obtiene catalogo de motivos de retraso en servicio.
    	 * @return	{void}
    	 */
	function catalog () {
		$localForage.getItem('dataUser').then(function(dd) {
			ChatBot.getCatalogChatBot(dd.country).then(function(data) {
				vm.setComment = data.data;
				vm.validate = 1;
			});
		});
	}
}// End controller

angular
.module('app')
.component('wrapperMonitoring', {
	templateUrl: 'app/components/wrapperMonitoring/wrapperMonitoring.html',
	controller: wrapperMonitoringController,
	controllerAs : 'ctrl',
	bindings : {
		questionChatBot : '=',
		craneList : '<',
		elapsed : '=',
		setData : '&',
		showDetail : '=',
		cont : '=',
		setComment : '='
	}
});

