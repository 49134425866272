/**
 * Componente de vista muestar hora de contacto, llegada y finalizacion, formulario para validacion de codigos para hora de contacto.
 * @module wrapperCheckContactController
 * @param      {Object}  CheckContactService  Servicio para registro de seguimiento de asistencia.
 * @param      {Object}  $localForage         libreria para almacenamiento local.
 * @param      {Object}  toastr               Libreria para alertas.
 * @param      {Object}  toastrConfig         Libreria para configuración de alertas.
 * @param      {Object}  CraneService         Servicio para acciones de gruas.
 * @param			 {Object}  AssistanceService		Servicio para assistenias
 * @return 		 {Object}	 Ctrl
 */
function wrapperCheckContactController(CheckContactService, $localForage, toastr, toastrConfig, CraneService, AssistanceService) {
	var vm = this;

	toastrConfig.positionClass = 'toast-top-center';

	$localForage.getItem('dataUser')
	.then(function(data) {
		vm.dataInfo = data.country;
	});

	/* Define variables */
	vm.valContact = false;
	vm.valCode = false;
	vm.valClass = null;
	vm.valError = false;
	vm.flagResultStatus = false;
	vm.vcode = false;
	vm.vlastname = false;
	vm.vplaque = false;

	/* Define funciones */
	vm.dateArrival = dateArrival;
	vm.dateContact = dateContact;
	vm.validateCode = validateCode;
	vm.dateEnd = dateEnd;
	vm.init = init;
	vm.flagStatus = flagStatus;
	vm.failedContact = failedContact;

	/* Implementacion */
		var assistancePrevios = null;
		this.$doCheck = function() {
			var assistanceCurrent = vm.assistance.status;
			if (assistancePrevios !== assistanceCurrent) {
				flagStatus();
				assistancePrevios = assistanceCurrent;
			}
		};
	/**
	 * @static
	 * @function
	 * @name flagStatus
	 * @description Valida el estatus de la asistencia, y la bandera se ocupa para mostar elementos en la vista.
	 * @return {Boolean}  true o flase.
	 */
	function flagStatus() {
		vm.flagResultStatus = vm.assistance.status.description === 'Cancelación de Proveedor' || vm.assistance.status.description === 'Cancelación posterior' || vm.assistance.status.description === 'Asistencia Concluida' || vm.assistance.status.description === 'Proveedor Concluido' || vm.assistance.status.description === 'Proveedor no Contacto Nuestro Usuario';
		return vm.flagResultStatus;
	}
	/** @static
	 * @function
	 * @name init
	 * @description Carga estatus iniciales segun datos existente en JSON assistances contactDate y endDate
	 * @return {void}
	 */
	function init() {
		try {
			if (vm.assistance.assistanceMonitoringData.providerDates.contactDate && vm.assistance.assistanceMonitoringData.validationFlags.success) {
				vm.assistance.status.arrival = 5;
				vm.showDestiny = true;
			}
		} catch (err) {
		}
	}

	/** @static
	 * @function
	 * @name dateArrival
	 * @description Ejecuta endpoint dateArrival para obtener date arribo con NU
	 * @return {void}
	 */
	function dateArrival() {
		if (flagStatus()) {
			toastr.error('No se puede realizar hora de arrivo, por el motivo ' + vm.assistance.status.description, 'Arribo!');
		} else {
			$localForage.getItem('dataUser').then(function(data) {
				CheckContactService.dateArrival(data.id, vm.service).then(function(data) {
					if (data.data.isSuccess) {
						toastr.success(data.data.data.defMessage, 'Arribo!');
					} else {
						toastr.error(data.data.data.defMessage, 'Arribo!');
					}
				});
			});
		}
	}

	/** @static
	 * @function
	 * @name dateContact
	 * @description Asigna texto a JSON parametros contactDate y arriveDate para habilitar el formulario de contacto
	 * @return {void}
	 */
	function dateContact() {
		vm.assistance.assistanceMonitoringData.providerDates.contactDate = 'Ingresar codigo';
		if (angular.equals(vm.assistance.assistanceMonitoringData.providerDates.arriveDate, null)) {
			vm.assistance.assistanceMonitoringData.providerDates.arriveDate = 'Ingresar codigo';
		}
	}

	/** @static
	 * @function
	 * @name validateCode
	 * @description Realiza la validacion de codigo con el cual se realzia contacto con NU, type tipo que se valida, code deto a validar
	 * @param      {string}  type   tipo de validacion
	 * @param      {string}  code   code a validar
	 * @return {void}
	 */
	function validateCode(type, code) {
		if (!angular.equals(code, null)) {
			if (flagStatus()) {
				toastr.error('No se puede realizar la validación, por el motivo ' + vm.assistance.status.description, 'Contacto!');
			} else {
				$localForage.getItem('dataUser').then(function(data) {
				var prov = data.id;
					CheckContactService.validateCode(vm.service, prov, type, code).then(function(data) {
						if (data.data.isSuccess || vm.assistance.assistanceMonitoringData.validationFlags.success) {
							vm.showDestiny = true;
							if (!vm.assistance.assistanceMonitoringData.providerDates.arriveDate || vm.assistance.assistanceMonitoringData.providerDates.arriveDate === 'Ingresar codigo') {
								CheckContactService.dateArrival(prov, vm.service).then(function(data) {
									if (data.data.isSuccess) {
										toastr.success(data.data.data.defMessage, 'Arribo!');
										CheckContactService.dateContact(prov, vm.service).then(function(data) { // obtiene la hora de contacto despues de la validacion de codigo
											if (data.data.isSuccess) {
												toastr.success(data.data.data.defMessage, 'Contacto!');
												vm.assistance.status.arrival = 4;
											} else {
												toastr.error(data.data.data.defMessage, 'Contacto!');
											}
										});
									} else {
										toastr.error(data.data.data.defMessage, 'Arribo!');
									}
								});
							} else {
								CheckContactService.dateContact(prov, vm.service).then(function(data) { // obtiene la hora de contacto despues de la validacion de codigo
									if (data.data.isSuccess) {
										toastr.success(data.data.data.defMessage, 'Contacto!');
										vm.assistance.status.arrival = 4;
									} else {
										toastr.error(data.data.data.defMessage, 'Contacto!');
									}
								});
							}
							vm.valError = false;
						} else {
							vm.valClass = 'has-error';
							vm.valError = true;
						}
					});
				});
			}
		}
	}

	/** @static
	 * @function
	 * @name dateEnd
	 * @description Ejecuta endpoint para liberacion de grua y termino de asistencia
	 * @return {void}
	 */
	function dateEnd() {
		if (flagStatus()) {
			toastr.error('No se puede realizar fecha de termino, por el motivo ' + vm.assistance.status.description, 'Finalizar!');
		} else if (vm.assistance.assistanceMonitoringData.validationFlags.success && vm.assistance.assistanceMonitoringData.providerDates.contactDate) {
			$localForage.getItem('dataUser').then(function(data) {
				var prov = data.id;
				CheckContactService.dateEnd(prov, vm.service).then(function(data) {
					if (data.data.isSuccess && data.data.data.defMessage !== 'Error date' && data.data.data.defMessage !== 'Error') {
						toastr.success(data.data.data.defMessage, 'Finaliza!');
						CraneService.enableCrane(prov, vm.service).then(function(data) { // libera grua al finalizar asistencia
							if (data.isSuccess) {
								toastr.success('Se libera grua con exito!!', 'Grua!');
							}
						});
					} else {
						toastr.error(data.data.data.defMessage, 'Finaliza!');
					}
				});
			});
		}
	}

	/** @static
	 * @function
	 * @name dateEnd
	 * @description Ejecuta endpoint para marca de contacto fallido
	 * @param      {string}  assistance   Assistencia
	 * @return {void}
	 */
	function failedContact(assistance) {
		$localForage.getItem('dataUser').then(function(data) {
			var prov = data.id;
			AssistanceService.failedContact(prov, assistance).then(function(data) {
				if (data.isSuccess) {
					toastr.success(data.data.defMessage, 'Estatus!');
				} else {
					toastr.error(data.data.defMessage, 'Error!');
				}
			});
		});
	}
}// End controller

angular
.module('app')
.component('wrapperCheckContact', {
	templateUrl: 'app/components/wrapperCheckContact/wrapperCheckContact.html',
	controller: wrapperCheckContactController,
	controllerAs: 'ctrl',
	bindings : {
		assistance : '=',
		service : '=',
		valTime : '=',
		showDestiny : '='
	}
});

