/**
  * @namespace HelpUtilityCtrl
  * @desc Funciones de ayuda para acciones en los controladores.
  * @memberOf Factories
  * @return {Object} ctrl
  */
function HelpUtilityCtrl() {
	var service = {
		filterManualReason : filterManualReason,
		statusDescriptionMapping : statusDescriptionMapping,
		statusDescriptionMappingOneAssistance : statusDescriptionMappingOneAssistance
	};
	return service;

	/* IMPLEMENTACION */
	/**
	 * @desc Filta la lista de motivos de reasignacion, eliminado el motivo MANUAL.
	 * @memberOf Factories.HelpUtilityCtrl
	 * @function filterManualReason
	 * @param {Object}	reasonsList	Lista de motivos de reasignacion.
	 * @return {Object} Lista de motivos filtrada.
	 */
	function filterManualReason(reasonsList) {
		var newReasonList = _.remove(reasonsList, function(r) {
			return r.description !== 'MANUAL';
		});
		return newReasonList;
	}
	/**
	 * Modifica la descripcion de estatus de las asistencia.
	 * @memberOf Factories.HelpUtilityCtrl
	 * @function   statusDescriptionMapping
	 * @param      {Object}  assistances  Lista de asistencias.
	 * @return 		{Object}  Listas de asistencias con descripcion de estatus modificado.
	 */
	function statusDescriptionMapping(assistances) {
		return _.forEach(assistances, function(ast) {
			statusDescriptionMappingOneAssistance(ast);
		});
	}
		/**
	 * Modifica la descripcion de estatus de una asistencia.
	 * @memberOf Factories.HelpUtilityCtrl
	 * @function   statusDescriptionMappingOneAssistance
	 * @param      {Object}  assistance 	Una asistencia.
	 * @return 		{Object}  Asistencias con descripcion de estatus modificado.
	 */
	function statusDescriptionMappingOneAssistance(assistance) {
		try {
		/*
			var sinAccion = assistance.status.description;
			switch (assistance.status.description) {
				case 'Asignado' :
					assistance.status.description = 'Tiempo restante';
					if (assistance.assistanceMonitoringData.providerDates.contactDate !== null) {
						assistance.status.description = 'Usuario contactado';
						if (assistance.assistanceMonitoringData.providerDates.endDate !== null) {
							assistance.status.description = 'Proveedor Concluido';
						}
					}
					break;
				case 'Tiempo restante' :
					assistance.status.description = sinAccion;
					if (assistance.assistanceMonitoringData.providerDates.contactDate !== null) {
						assistance.status.description = 'Usuario contactado';
						if (assistance.assistanceMonitoringData.providerDates.endDate !== null) {
							assistance.status.description = 'Proveedor Concluido';
						}
					}
					break;
				case 'Proveedor está llegando a la ubicación del cliente.' :
					assistance.status.description = 'Tiempo restante';
					if (assistance.assistanceMonitoringData.providerDates.contactDate !== null) {
						assistance.status.description = 'Usuario contactado';
						if (assistance.assistanceMonitoringData.providerDates.endDate !== null) {
							assistance.status.description = 'Proveedor Concluido';
						}
					}
					break;
				case 'El usuario ha sido contactado':
					assistance.status.description = 'Usuario contactado';
					if ((assistance.assistanceMonitoringData.providerDates.contactDate !== null) && (assistance.assistanceMonitoringData.validationFlags.success === true)) {
						assistance.status.description = 'Usuario contactado';
						if (assistance.assistanceMonitoringData.providerDates.endDate !== null) {
							assistance.status.description = 'Proveedor Concluido';
						}
					}
					break;
				default :
					assistance.status.description = sinAccion;
			}
			*/
			/**
			if (assistance.status.code === '2') {
				assistance.status.description = 'Tiempo restante';
				if (assistance.assistanceMonitoringData.providerDates.contactDate !== null && assistance.assistanceMonitoringData.validationFlags.success === true) {
					assistance.status.description = 'Usuario contactado';
				}
				if (assistance.assistanceMonitoringData.providerDates.contactDate !== null && assistance.assistanceMonitoringData.providerDates.endDate !== null) {
					assistance.status.description = 'Proveedor Concluido';
				}
			}
			assistance.status.status_type = '0';
			if (assistance.status.description === 'Asistencia Concluida' || assistance.status.description === 'Proveedor Concluido') {
				assistance.status.status_type = '20';
			}
			if (assistance.status.description === 'Cancelación de Proveedor' || assistance.status.description === 'Cancelación posterior' || assistance.status.description === 'Cancelado al momento') {
				assistance.status.status_type = '4';
			}
			*/
			switch (assistance.status.description) {
        case 'Asistencia Concluida':
        case 'Proveedor Concluido':
        case 'Proveedor no Contacto Nuestro Usuario':
					assistance.status.status_type = '20';
          break;
        case 'Cancelación de Proveedor':
        case 'Cancelación posterior':
        case 'Cancelado al momento':
          assistance.status.status_type = '4';
          break;
				default:
					assistance.status.status_type = '0';
          assistance.status.description = 'Tiempo restante';
					if (assistance.assistanceMonitoringData.providerDates.contactDate !== null && assistance.assistanceMonitoringData.validationFlags.success === true) {
						assistance.status.description = 'Usuario contactado';
					}
					if (assistance.assistanceMonitoringData.providerDates.contactDate !== null && assistance.assistanceMonitoringData.providerDates.endDate !== null) {
						assistance.status.description = 'Proveedor Concluido';
					}
          break;
      }
		} catch (err) {
			assistance.status = {description : 'Sin estatus'};
		}
		return assistance;
	}
}

angular
  .module('app')
  .factory('HelpUtilityCtrl', HelpUtilityCtrl);
