/**
 * Componente de vista para mostrar subservicio y tipo de asignación.
 * @module wrapperHeadController
 * @return {Object} ctrl
 */
function wrapperHeadController() {
}

angular
	.module('app')
	.component('wrapperHead', {
		templateUrl: 'app/components/wrapperHead/wrapperHead.html',
		controller: wrapperHeadController,
		controllerAs : 'headerCtrl',
		bindings: {
			subservice : '<',
			typeassignment:'<'
		}
	});

