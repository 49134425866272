/**
 * Componente costos para paso de corriente.
 * @module dragCraneCostController
 * @param      {Object}  $document  Obtiene elementos del DOM.
 * @return {Object} ctrl
 */
function dragCraneCostController($document) {
var vm = this;
	/* Define variables */

	/* Define funciones */
	vm.CostCaptureC = CostCaptureC;
	vm.CostCaptureNC = CostCaptureNC;
	vm.maniobras = maniobras;
	vm.mostrar = false;
	/* Implementacion */
	/** @static
	 * @function
	 * @name CostCaptureC
	 * @description Contruye objeto de costos capturado Carretero
	 * @param      {String}  service - id de asistencia
	 * @param      {String}  beginningtrip - costo por inicio de viaje
	 * @param      {String}  kilometerstraveledCost - costo por kilometros rrecorridos
	 * @param      {String}  kilometerstraveled - costo por kilometros rrecorridos
	 * @param      {String}  tollbooths - costo por cabinas de peaje
	 * @param      {String}  waittime - costo por tiempo de espera
	 * @param      {String}  maneuver - costo por maniobras
	 * @param      {String}  country - pais por proveedor
	 * @return  {void}
	 */
	function CostCaptureC(service, beginningtrip, kilometerstraveledCost, kilometerstraveled, tollbooths, waittime, maneuver, country) {

        if (maneuver === null || angular.isUndefined(maneuver)) {
            maneuver = 0;
        }

        var res;
        if (country === 'CO') {
            res = {
                service: service,
                beginning_trip: beginningtrip,
                kilometers_traveled: kilometerstraveled,
                kilometers_traveledCost: kilometerstraveledCost
            };
        } else {

            res = {
                service: service,
                beginning_trip: beginningtrip,
                kilometers_traveled: kilometerstraveled,
                kilometers_traveledCost: kilometerstraveledCost,
                toll_booths: tollbooths,
                wait_time: waittime,
                maneuver: maneuver
            };
        }

        vm.getTimecos({
            service: res
        });
    }

	/** @static
	 * @function
	 * @name CostCaptureC
	 * @description Contruye objeto de costos capturado No Carretero
	 * @param      {String}  service - id de servicio
	 * @param      {String}  drag - costo por arrastre
	 * @param      {String}  waittime - costo por tiempo de espera
	 * @param			 {String}	 maneuver - costos de maniobras
	 * @param      {String}  country - pais por proveedor
	 * @return  {void}
	 */
	function CostCaptureNC(service, drag, waittime, maneuver, country) {
        if (maneuver === null || angular.isUndefined(maneuver)) {
            maneuver = 0;
        }
        var res;
        if (country === 'CO') {
            res = {
                service: service,
                drag: drag
            };
        } else {

            res = {
                service: service,
                drag: drag,
                wait_time: waittime,
                maneuver: maneuver
            };
        }
        vm.getTimecos({
            service: res
        });
    }
 /** @static
  * @function
  * @name maniobras
  * @description activa costo de maniobras.
  * @param			 {String}	 valida - valida checbox de maniobras
  * @return	{Object | undefined}	 No retorna nada.
  */
  function maniobras(valida) {
		var show = $document[0].getElementById('check' + valida);
		if (show.checked === true) {
			vm.mostrar = true;
		} else {
			vm.mostrar = false;
		}
	}
}

angular
.module('app')
.component('dragCraneCost', {
	templateUrl: 'app/components/wrapperCost/dragCraneCost/dragCraneCost.html',
	controller: dragCraneCostController,
	controllerAs : 'ctrl',
	bindings : {
		data : '<',
		valCosts : '<',
		getTimecos : '&',
		service : '<',
		monitor : '<',
		status : '='
	}
});
