/**
  * @namespace CheckContactService
  * @desc Manejo de fechas en seguimiento de asistencia.
  * @param {Object} $http  Peticiones
  * @memberOf Factories
  * @return {Object} service
  */
function CheckContactService($http) {
	/**
	 * Funciones disponibles del servicio
	 * @memberof Factories.CheckContactService
	 * @property {function} dateArrival
	 * @property {function} dateContact
	 * @property {function} validateCode
	 * @property {function} dateEnd
	 * @property {function} captureCost
	 */
	var service = {
		dateArrival : dateArrival,
		dateContact : dateContact,
		validateCode : validateCode,
		dateEnd : dateEnd,
		captureCost : captureCost,
		saveCost : saveCost
	};
	/* Retorna definicion del servicio */
	return service;

	/* Implementacion */
	/**
	 * @static
	 * @function getCustomerComplete
	 * @desc Procesa respuesta de servicio.
	 * @memberof Factories.CheckContactService
	 * @param      {Object}  data  	Response de servicio.
	 * @return     {Object}  data  Objeto data contenido en el response
	 */
	function getCustomerComplete(data) {
		return data;
	}
	/**
	 * @static
	 * @function postCustomerComplete
	 * @desc Procesa respuesta de servicio.
	 * @memberof Factories.CheckContactService
	 * @param      {Object}  data  	Response de servicio.
	 * @return     {Object}  data  Objeto data contenido en el response
	 */
	function postCostComplete(data) {
		return data.data;
	}
	/**
	 * @function dateArrival
	 * @desc Registra hora de Arribo.
	 * @memberof Factories.CheckContactService
	 * @param      {String}  provider  	Id de proveedor.
	 * @param      {String}  idservice  id de asistencia.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function dateArrival(provider, idservice) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + idservice + '/arrive_date?requestFrom=portal')
			.then(getCustomerComplete);
	}
	/**
	 * @function dateContact
	 * @desc Registra hora de Contacto.
	 * @memberof Factories.CheckContactService
	 * @param      {String}  provider  	Id de proveedor.
	 * @param      {String}  idservice  id de asistencia.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function dateContact(provider, idservice) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + idservice + '/contact_date?requestFrom=portal')
		.then(getCustomerComplete);
	}
	/**
	 * @function validateCode
	 * @desc Validacion de codigo verificador de contacto.
	 * @memberof Factories.CheckContactService
	 * @param      {String}  idservice 	Id de asistencia.
	 * @param      {String}  provider  id de proveedor.
	 * @param      {String}  tipo  tipo de validacion.
	 * @param      {String}  code  codigo verificador.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function validateCode(idservice, provider, tipo, code) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + idservice + '/validate_contact?' + tipo + '=' + code)
		.then(getCustomerComplete);
	}
	/**
	 * @function dateEnd
	 * @desc Registra hora de Arribo.
	 * @memberof Factories.CheckContactService
	 * @param      {String}  provider  	Id de proveedor.
	 * @param      {String}  idservice  id de asistencia.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function dateEnd(provider, idservice) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + idservice + '/end_date?requestFrom=portal')
		.then(getCustomerComplete);
	}
	/**
	 * @function CaptureCost
	 * @desc Validacion de codigo verificador de contacto.
	 * @memberof Factories.CheckContactService
	 * @param      {String}  provider  	Id de proveedor.
	 * @param      {Object}  service  contiene costos capturados en asistencia y id de asistencia.
	 * @param      {Object}  tipoCosto  contiene costos capturados en asistencia y id de asistencia.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function captureCost(provider, service, tipoCosto) {
		return $http.post('api/v1/providers/' + provider + '/assistances/' + service.service + '/costs', {
			beginning_trip: service.beginning_trip, // inicio de viaje
			toll_booths: service.toll_booths, // cabina de peaje
			kilometers_traveled: service.kilometers_traveled, // kilometros recorridos
			liquids: service.liquids, // liquidos
			drag: service.drag, // arrastre
			current_flow: service.current_flow, // flujo de corriente
			change_tire: service.change_tire, // cambio de neumaticos
			wait_time: service.wait_time,
			maneuver: service.maneuver,
			kilometers_traveledCost : service.kilometers_traveledCost,
			statusCosto: tipoCosto
		})
		.then(postCostComplete);
	}
	/**
	 * @function saveCost
	 * @desc Validacion de codigo verificador de contacto.
	 * @memberof Factories.CheckContactService
	 * @param      {String}  providerId  	Id de proveedor.
	 * @param      {Object}  assistanceId  contiene costos capturados en asistencia y id de asistencia.
	 * @param      {Object}  map  contiene costos capturados en asistencia y id de asistencia.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function saveCost(providerId, assistanceId, map) {
		return $http.post('api/v1/providers/' + providerId + '/assistances/' + assistanceId + '/savecosts', map)
		.then(postCostComplete);
	}
}// End service

angular
  .module('app')
  .service('CheckContactService', CheckContactService);

