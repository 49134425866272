/**
 * Componente de vista para mostar el listado de archivos por expediente.
 * @module wrapperFilesController
 * @param {Object} CatalogService  servicios.
  * @param {Object} $log  logs
 * @return {Object} ctrl
 */
function wrapperFilesController() {
	var vm = this;
	/* Define variables */
	vm.files = [];
}// End controller

angular
.module('app')
.component('wrapperFiles', {
	templateUrl: 'app/components/wrapperFiles/wrapperFiles.html',
	controller: wrapperFilesController,
	controllerAs : 'ctrl',
	bindings : {
		folio : '=',
		country : '=',
		setFiles: '='
	}
});
