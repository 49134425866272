/**
 * Componente de vista para mostar el formulario de costos dependiendo del subservicio y tipo de servicio (CARRETERO / NO CARRETERO).
 * @module wrapperCostController
 * @param {Object} toastr  Libreria para alertas.
 * @param {Object} toastrConfig  Libreria para configuración de alertas.
 * @return {Object} ctrl
 */
function wrapperCostController(toastr, toastrConfig) {
	toastrConfig.positionClass = 'toast-top-center';
	var vm = this;
	/* Define variables */
	vm.error = true;
	/* Define funciones */
	vm.CostCapture = CostCapture;
	vm.CostSise = CostSise;


	/* Implementacion */
	/** @static
	 * @function
	 * @name CostCapture
	 * @description Valida cotos capturados no null, no vacios y mayores a cero.
	 * @param      {String}  service	id de asistenccia
	 * @return {void}
	 */
	function CostCapture(service) {
		if (vm.data.assistanceMonitoringData.providerDates.endDate || vm.data.status.arrival === 8) {
			_.forEach(service, function (value) {
				if (value === null || angular.isUndefined(value)) {
					vm.error = false;
				}
			});
			if (vm.error) {
				vm.getTime({service: service});
			} else {
				toastr.info('Todos los campos deben tener costos capturados, igual o mayor a cero', 'Captura de costos!');
				vm.error = true;
			}
		}
	}
	/* Implementacion */
	/** @static
	 * @function
	 * @name CostSise
	 * @description Valida costos capturados no null, no vacios y mayores a cero.
	 * @param      {String}  service	id de asistenccia
	 * @param      {String}  assistanceId	id de asistenccia
	 * @return {void}
	 */
	function CostSise(service, assistanceId) {
		vm.getCost({service : service, assistanceId : assistanceId});
	}
}// End controller

angular
.module('app')
.component('wrapperCost', {
	templateUrl: 'app/components/wrapperCost/wrapperCost.html',
	controller: wrapperCostController,
	controllerAs : 'ctrl',
	bindings : {
		subservice : '<',
		data : '=',
		valCosts : '=',
		getTime : '&',
		getCost : '&',
		loadingData : '='
	}
});
